import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { white } from "../../constants/Colours";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100%",
    backgroundColor: theme.palette.background,
  },
  container: {
    paddingTop: 35,
    marginBottom: 100,
  },
}));
const PageLayout = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>{children}</Container>
    </div>
  );
};

export default PageLayout;
