import React, { useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useWalletContext } from "../../context/WalletContext";
import WrapApprovePanel from "./WrapApprovePanel";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    color: theme.palette.colors.holoBlack,
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
  },
  content: {
    paddingTop: "33px!important",
    paddingBottom: "45px!important",
  },
  button: {
    height: 48,
    width: 240,
  },
}));

export default function WrapPanel() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [wrapped, setWrapped] = useState(true);
  const { isWalletConnected } = useWalletContext();

  return (
    <Card>
      <CardContent className={classes.content}>
        <Grid container spacing={5}>
          <Grid container item xs={12} rowSpacing={4}>
            <Grid item xs={12} sm={isWalletConnected ? 6 : 12} md={7} lg={9}>
              <Typography className={classes.title} variant="string">
                {!isWalletConnected && t("wrapPage.wrapUnwrapTitle")}
                {isWalletConnected && wrapped && t("wrapPage.wrapTitle")}
                {isWalletConnected && !wrapped && t("wrapPage.unwrapTitle")}
              </Typography>
            </Grid>
            {isWalletConnected && (
              <Grid
                container
                item
                xs={12}
                sm={6}
                md={5}
                lg={3}
                justifyContent={{ xs: "center", sm: "flex-end" }}
                spacing={2}
              >
                <Grid item xs={6}>
                  <Button
                    variant={`${wrapped ? "contained" : "outlined"}`}
                    onClick={() => setWrapped(true)}
                    color="primary"
                    size="small"
                    fullWidth
                  >
                    {t("buttons.wrap")}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant={`${!wrapped ? "contained" : "outlined"}`}
                    onClick={() => setWrapped(false)}
                    color="primary"
                    size="small"
                    fullWidth
                  >
                    {" "}
                    {t("buttons.unwrap")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          <WrapApprovePanel wrapped={wrapped} />
        </Grid>
      </CardContent>
    </Card>
  );
}
