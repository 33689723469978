import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ValueCard from "../../../Components/ValueCard";
import { usePoolContext } from "../Context/PoolContext";

const useStyles = makeStyles((theme) => ({
  card: {
    paddingTop: "33px!important",
    paddingBottom: "33px!important",
  },
  title: {
    display: "block",
    fontSize: 18,
    fontWeight: 400,
  },
  value: {
    display: "block",
    fontFamily: "PowerGrotesk-Medium",
    fontSize: 36,
    fontWeight: 500,
    paddingBottom: "0px!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: 31,
    },
  },
}));

const PoolValue = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { holoPoolValue } = usePoolContext();

  return (
    <ValueCard className={classes.card}>
      <Grid item xs={12}>
        <ValueCard.Title className={classes.title} variantParam="string">
          {t("poolPage.PoolValueTitle")}{" "}
        </ValueCard.Title>
        <ValueCard.Value
          className={classes.value}
          variantParam="string"
          value={"$" + Number(holoPoolValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
        />
      </Grid>
    </ValueCard>
  );
};

export default PoolValue;
