import {
  DASHBOARD,
  DEPOSIT,
  MINT,
  SWAP,
  WRAP,
  POOL,
  DOCS,
  PRESALE,
  ACCOUNT,
} from "./PageUrls";
import DashboardPage from "../Pages/Dashboard";
import StakePage from "../Pages/Stake";
import MintPage from "../Pages/Mint";
import SwapPage from "../Pages/Swap";
import WrapPage from "../Pages/Wrap";
import PoolPage from "../Pages/Pool";
import PresalePage from "../Pages/Presale";
import AccountPage from "../Pages/Account";

export const dashboard = "dashboard";
export const deposit = "deposit";
export const mint = "mint";
export const swap = "swap";
export const wrap = "wrap";
export const pool = "pool";
export const docs = "docs";
export const presale = "PRESALE";
export const account = "account";

export const menuList = [
  {
    url: DASHBOARD,
    name: dashboard,
    title: "DASHBOARD",
    mobileTitle: "DASH",
    icon: "dashboard-nav",
    element: <DashboardPage />,
  },
  {
    url: ACCOUNT,
    name: account,
    title: "ACCOUNT",
    mobileTitle: "ACCOUNT",
    icon: "account-nav",
    element: <AccountPage />,
  },
  {
    url: DEPOSIT,
    name: deposit,
    title: "DEPOSIT",
    mobileTitle: "DEPOSIT",
    icon: "stake-nav",
    element: <StakePage />,
  },
  {
    url: MINT,
    name: mint,
    title: "MINT",
    mobileTitle: "MINT",
    icon: "mint-nav",
    element: <MintPage />,
  },
  {
    url: SWAP,
    name: swap,
    title: "SWAP",
    mobileTitle: "SWAP",
    icon: "swap-nav",
    element: <SwapPage />,
  },
  {
    url: WRAP,
    name: wrap,
    title: "WRAP",
    mobileTitle: "WRAP",
    icon: "wrap-nav",
    element: <WrapPage />,
  },
  {
    url: PRESALE,
    name: presale,
    title: "PRESALE",
    mobileTitle: "PRESALE",
    icon: "presale-nav",
    element: <PresalePage />,
  },
  {
    url: POOL,
    name: pool,
    title: "POOL",
    mobileTitle: "POOL",
    icon: "pool-nav",
    element: <PoolPage />,
  },
  {
    url: DOCS,
    to: "https://docs.holoclear.xyz",
    name: docs,
    title: "DOCS",
    mobileTitle: "DOCS",
    icon: "docs-nav",
    hideOnRoute: true,
  },
];
