import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFoundPage from "../Pages/NotFoundPage";
import { Box } from "@mui/material";
import { menuList } from "./MenuNameExporter";

export default function CustomSwitch() {
  return (
    <Box component="main" sx={{ flexGrow: 1, width: "calc(100% - 96px)" }}>
      <Routes>
        {menuList
          .filter((menu) => !menu.hideOnRoute)
          .map((menu) => (
            <Route path={menu.url} exact element={menu.element} />
          ))}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Box>
  );
}
