/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageTitle from "../../Components/Typography/PageTitle";
import { Grid } from "@mui/material";
import PageLayout from "../../Components/Layout/PageLayout";
import { useTranslation } from "react-i18next";
import ResourcesCard from "../Dashboard/Resources";
import HelpBubble from "../Dashboard/HelpBubble";
import TransferSection from "./TransferSection";
import { useWalletContext } from "../../context/WalletContext";
import TransferredTokens from "./TransferSection/TransferredTokens";
import { usePresaleContext } from "./Context/PresaleContext";
import LoadingPage from "../LoadingPage";

const PresalePage = () => {
  return (
    <usePresaleContext.Provider>
      <PresalePageContent />
    </usePresaleContext.Provider>
  );
};
const PresalePageContent = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { isWalletConnected, account } = useWalletContext();
  const { isHoloClearApproved, showRedeemBonus, setShowRedeemBonus ,checkRedeemBonus, checkPholoAllowance, fetchPHoloBalance,  } = usePresaleContext();

  useEffect(() => {
    async function checkRedem() {
      setLoading(true);
      await checkRedeemBonus(account);
      await checkPholoAllowance();
      await fetchPHoloBalance(account);
      setLoading(false);
    }
    if (isWalletConnected) {
      checkRedem()
    } else {
      setShowRedeemBonus(false);
    }
  }, [isWalletConnected, account]);

  if (loading) {
    return <PageLayout><LoadingPage /></PageLayout>
  }

  return (
    <PageLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PageTitle> {t("pages.Presale")} </PageTitle>
        </Grid>
        <Grid item xs={12}>
          <TransferSection />
        </Grid>
        {isHoloClearApproved && isWalletConnected && !showRedeemBonus && (
          <Grid item xs={12}>
            <TransferredTokens />
          </Grid>
        )}
        <Grid item xs={12}>
          <ResourcesCard />
        </Grid>
        <Grid item xs={12}>
          <HelpBubble />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default PresalePage;
