import Icon from "../../../Components/Icon";
import Logo from "../../../assets/logo.svg";

export const tokenData = [
  {
    label: "BNB",
    value: "BNB",
    icon: (
      <Icon
        style={{ verticalAlign: "sub", marginBottom: 1 }}
        icon="binance"
        size="20"
      />
    ),
    price: "0.0000", // 1 BNB = Price x $1
    bnbPrice: "0.0000", // 1 BNB = bnbPrice x 1BNB
    balance: "0.0000",
    minSwapAmount: "0.0000", // Min x amount BNB
  },
  {
    label: "HOLOCLEAR",
    value: "$HOLO",
    icon: (
      <img
        style={{ verticalAlign: "sub", marginBottom: 1 }}
        height={20}
        src={Logo}
        alt="logo"
      />
    ),
    price: "0.0000", // 1 HOLO = Price x $1
    bnbPrice: "0.0000", // 1 HOLO = bnbPrice x 1BNB
    balance: "0.0000",
    minSwapAmount: "0.0000", // Min x amount $HOLO
  },
  {
    label: "BUSD",
    value: "BUSD",
    icon: (
      <Icon
        style={{ verticalAlign: "sub", marginBottom: 1 }}
        icon="BUSD"
        size="20"
      />
    ),
    price: "1.0000", // 1 BUSD = Price x $1
    bnbPrice: "0.0000", // 1 BUSD = bnbPrice x 1BNB
    balance: "0.0000",
    minSwapAmount: "0.0000", // Min x amount BUSD
  },
];

export const MarketTokens = [
  {
    label: "BNB",
    value: "BNB",
    market: "HOLOBNB",
    icon: (
      <Icon
        style={{ verticalAlign: "sub", marginBottom: 1 }}
        icon="binance"
        size="20"
      />
    ),
  },
  {
    label: "BUSD",
    value: "BUSD",
    market: "HOLOBUSD",
    icon: (
      <Icon
        style={{ verticalAlign: "sub", marginBottom: 1 }}
        icon="BUSD"
        size="20"
      />
    ),
  },
];
