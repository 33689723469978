import * as React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { holoBlack, holoGreen, holoPink } from "../../constants/Colours";
import { Grid } from "@mui/material";
import { useApiResult } from "../../context/ApiResultContext";
import { useState } from "react";
import { useContractContext } from "../../context/ContractContext";
import { useEffect } from "react";
import { ethers } from "ethers";
import ValueCard from "../../Components/ValueCard";
import GraphStatus from "../../Components/Icon/GraphStatus";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
  },
  cardContent: {
    width: "100%",
    margin: "auto 0",
  },
  title: {
    color: theme.palette.colors.holoBlack,
    paddingBottom: 16,
    whiteSpace: "nowrap",
  },
  accountApyTitle: {
    fontSize: "18px!important",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px!important",
      fontWeight: 400,
      lineHeight: "14px",
    },
  },
  value: {
    color: theme.palette.colors.holoPink,
    paddingBottom: 16,
    fontSize: 25,
    fontFamily: "PowerGrotesk-Medium!important",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0.75px",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      lineHeight: "34px",
      letterSpacing: "0.03em",
    },
  },
  valueAccountPage: {
    color: theme.palette.colors.holoPink,
    paddingBottom: 16,
    fontSize: "25px!important",
    fontFamily: "PowerGrotesk-Medium!important",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0.75px",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px!important",
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px!important",
      lineHeight: "22px",
      letterSpacing: "0.03em",
    },
  },
  change: {
    color: theme.palette.colors.holoGreen,
    whiteSpace: "nowrap",
  },
  changeIcon: {
    marginRight: 5,
  },
  growthChart: {
    display: "flex",
    alignItems: "center",
  },
  growthChartIcon: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export default function ApyCard(props) {
  const classes = useStyles();
  const { isAccountPage = false } = props;
  const { t } = useTranslation();
  const { data } = useApiResult();
  const [apyValue, setApyValue] = useState("-");
  const { HoloYieldContract, VaultContract } = useContractContext();
  useEffect(() => {
    getApyValue();
  }, []);
  const getApyValue = async () => {
    try {
      const rate_req = HoloYieldContract.rate();
      const epoch_req = VaultContract.epoch();
      Promise.all([rate_req, epoch_req])
        .then(([rate_gwei, epoch_req]) => {
          const rate = ethers.utils.formatEther(rate_gwei);
          const epoch = epoch_req[0];
          const _apyValue = 100 * (Math.pow(rate, (365 * 86400) / epoch) - 1);
          setApyValue(parseFloat(_apyValue.toFixed(3)));
        })
        .catch((errors) => {
          console.log(errors);
          setApyValue("Error");
        });
    } catch (error) {
      console.log(error);
      setApyValue("Error");
    }
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid xs={12} md={6}>
            <ValueCard.Title
              className={
                !isAccountPage ? classes.title : classes.accountApyTitle
              }
            >
              {t("apy")}
            </ValueCard.Title>
            {!isAccountPage && (
              <Typography variant="h5" className={classes.value}>
                {apyValue}%
              </Typography>
            )}
            {isAccountPage && (
              <Typography variant="h5" className={classes.valueAccountPage}>
                {apyValue}%
              </Typography>
            )}
            <ValueCard.Change noMargin change={data.change.apy} />
          </Grid>
          <Grid
            xs={6}
            className={classes.growthChart}
            display={{ xs: "none", md: "flex" }}
          >
            <GraphStatus
              isDown={data.change.apy < 0}
              className={classes.growthChartIcon}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
