import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import SummaryPanelItem from "./SummaryPanelItem";
import { holoLightBlue } from "../../constants/Colours";
import useContract from "../../Hooks/useContract";
import HoloClearConfig from "../../blockChainContexts/Testnet/HoloClear/config";
import { useContractContext } from "../../context/ContractContext";
import { ethers } from "ethers";
import moment from "moment";
import { stringToByte32 } from "../../utils/String";
import RouterConfig from "../../blockChainContexts/Testnet/Router/config";
import { useCountdown } from "../../Hooks/useCountDown";
import { formatRemainTime } from "../../utils/Date";
import { useApiResult } from "../../context/ApiResultContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondBackground,
    [theme.breakpoints.up("lg")]: {
      alignContent: "flex-start",
      borderRight: "1px solid " + theme.palette.colors.white3,
      maxWidth: "360px!important",
      minHeight: "calc(100vh - 96px)",
      height: "max-content",
      position: "sticky",
      top: "96px",
    },
    [theme.breakpoints.down("lg")]: {
      paddingBottom: 34,
      borderBottom: "1px solid " + theme.palette.colors.white3,
    },
    [theme.breakpoints.between("lg", "md")]: {
      maxWidth: 300,
    },
  },
  item: {
    height: "min-content",
  },
}));

const SummaryPanel = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    HoloClearContract,
    HoloYieldContract,
    VaultContract,
    LotteryContract,
    MarketOracleContract,
  } = useContractContext();
  const [totalSupply, setTotalSupply] = useState("-");
  const [currentIndex, setCurrentIndex] = useState("-");
  const [nextInterestPayment, setNextInterestPayment] = useState("-");
  const [prizePool, setPrizePool] = useState("-");
  const [depositedValue, setDepositedValue] = useState("-");
  const { data } = useApiResult();
  useEffect(() => {
    getTotalSupply();
    getCurrentIndex();
    getNextInterestPayment();
    getPrizePool();
    getValueDeposited();
    // getHolderCount();
  }, []);

  const getTotalSupply = async () => {
    try {
      const totalSupply_gwei = await HoloClearContract.totalSupply();
      const _totalSupply = ethers.utils.formatEther(totalSupply_gwei);
      setTotalSupply(Math.round(_totalSupply).toLocaleString());
    } catch (error) {
      setTotalSupply("Error");
    }
  };
  const getCurrentIndex = async () => {
    try {
      const currentIndex_gwei = await HoloYieldContract.index();
      const _currentIndex = ethers.utils.formatEther(currentIndex_gwei);
      setCurrentIndex(parseFloat(_currentIndex).toFixed(2));
    } catch (error) {
      setCurrentIndex("Error");
    }
  };
  const getNextInterestPayment = async () => {
    try {
      const _nextDate = await VaultContract.epoch();
      setNextInterestPayment(_nextDate[2].toString());
    } catch (error) {
      console.log(error);
      setNextInterestPayment("Error");
    }
  };
  const getPrizePool = async () => {
    try {
      const prize_gwei = await LotteryContract.busd_balance();
      const prize = ethers.utils.formatEther(prize_gwei);
      setPrizePool(parseFloat(prize).toLocaleString());
    } catch (error) {
      console.log(error);
      setPrizePool("Error");
    }
  };
  const getValueDeposited = async () => {
    try {
      const holobnb_array_req = MarketOracleContract.get_price_data(
        stringToByte32("HOLOBNB")
      );
      const bnbbusd_array_req = MarketOracleContract.get_price_data(
        stringToByte32("BNBBUSD")
      );
      const cir_supply_req = HoloYieldContract.circulatingSupply();

      Promise.all([holobnb_array_req, bnbbusd_array_req, cir_supply_req])
        .then(([holobnb_array, bnbbusd_array, cir_supply_gwei]) => {
          const holobnb =
            holobnb_array[1] == HoloClearConfig.address
              ? holobnb_array[6]
              : holobnb_array[5];
          const bnbbusd =
            bnbbusd_array[1] == RouterConfig.address
              ? bnbbusd_array[6]
              : bnbbusd_array[5];
          const holobusd = bnbbusd / holobnb;
          const cir_supply = ethers.utils.formatEther(cir_supply_gwei);
          const _depositedValue = cir_supply * holobusd;
          setDepositedValue(_depositedValue.toLocaleString());
        })
        .catch((errors) => {
          console.log(errors);
          setDepositedValue("Error");
        });
    } catch (error) {
      console.log(error);
      setDepositedValue("Error");
    }
  };
  const nextInterestPaymentCounter = useCountdown(
    nextInterestPayment,
    () => {}
  );
  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      lg={3}
      rowSpacing={1}
      direction="row"
      className={classes.root}
    >
      <Grid item xs={6} md={4} lg={12} className={classes.item}>
        <SummaryPanelItem
          iconName="holders"
          title={t("holders")}
          subText={
            data.graphs.holders.data[data.graphs.holders.data.length - 1]
          }
        />
      </Grid>
      <Grid item xs={6} md={4} lg={12} className={classes.item}>
        <SummaryPanelItem
          iconName="net-worth"
          title={t("totalSupply")}
          subText={totalSupply}
        />
      </Grid>
      <Grid item xs={6} md={4} lg={12} className={classes.item}>
        <SummaryPanelItem
          iconName="current-index"
          title={t("currentIndex")}
          subText={currentIndex}
        />
      </Grid>
      <Grid item xs={6} md={4} lg={12} className={classes.item}>
        <SummaryPanelItem
          iconName="harvested-losses"
          title={t("nextInterestPayment")}
          subText={formatRemainTime(nextInterestPaymentCounter)}
        />
      </Grid>
      <Grid item xs={6} md={4} lg={12} className={classes.item}>
        <SummaryPanelItem
          iconName="earnings"
          title={t("stakedValue")}
          subText={`$${depositedValue}`}
        />
      </Grid>
      <Grid item xs={6} md={4} lg={12} className={classes.item}>
        <SummaryPanelItem
          iconName="penny"
          title={t("prizePool")}
          subText={`$${prizePool}`}
        />
      </Grid>
    </Grid>
  );
};

export default SummaryPanel;
