import { Grid } from "@mui/material";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Loading from "../../../Components/Loading";
import { useContractContext } from "../../../context/ContractContext";
import { stringToByte32 } from "../../../utils/String";
import { useMintContext } from "../Context/MintContext";
import GiltCard from "../gilt/GiltCard";

const DisplayedGilts = ({ market = "HOLOBNB" }) => {
  const [giltList, setGiltList] = useState([]);
  const { GiltsContract } = useContractContext();
  const { holoPrice } = useMintContext();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    getGiltList();
  }, [holoPrice]);

  const getGiltList = async () => {
    try {
      const encodedMarket = stringToByte32(market);
      const gilts = await GiltsContract.view_gilts(encodedMarket);
      const formatted_gilts = gilts
        .filter((gilt) => gilt.live)
        .map((gilt, index) => {
          const bonus = ethers.utils.formatUnits(gilt.bonus, 18);
          const maturity_period = gilt.maturity_period.toNumber();
          return {
            gilt,
            bonus,
            price: holoPrice > 0 ? holoPrice / bonus : 0,
            discount: (1 - 1 / bonus) * 100,
            vestin_period: maturity_period,
          };
        });
      setGiltList(formatted_gilts);
    } catch (error) {
      console.log(error);
      setGiltList([]);
    }
    setloading(false);
  };
  if (loading) return <Loading />;
  return (
    <Grid container item spacing={4}>
      {giltList.map((bnbGilt, i) => (
        <Grid item xs={12}>
          <GiltCard
            key={i}
            discount={bnbGilt.discount}
            price={bnbGilt.price}
            vestingTime={bnbGilt.vestin_period}
            isBusd={market === "HOLOBUSD"}
            gilt={bnbGilt.gilt}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DisplayedGilts;
