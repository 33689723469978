import { makeStyles } from "@mui/styles";
import { Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import ValueCard from "../ValueCard";
import { useEffect, useState } from "react";
import { useWalletContext } from "../../context/WalletContext";
import { float4 } from "../../utils/Number";
import { useContractContext } from "../../context/ContractContext";
import { ethers } from "ethers";

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingLeft: 12,
    paddingTop: 5,
  },
  iconMdDevice: {
    paddingTop: 20,
  },
  value: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: "34px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: "34px",
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "29px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
    },
  },
}));

const BUSDBalanceCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [balance, setbalance] = useState("-");
  const matches = useMediaQuery("(max-width:900px)");
  const { BUSDContract } = useContractContext();
  const { account } = useWalletContext();
  useEffect(() => {
    getBalance();
  }, []);
  const getBalance = async () => {
    try {
      var balance = await BUSDContract.balanceOf(account);
      const busdBalance = ethers.utils.formatEther(balance);
      setbalance(busdBalance);
    } catch (error) {
      setbalance("Error");
    }
  };
  return (
    <ValueCard size="lg">
      <Grid container item xs={12}>
        <ValueCard.Title variantParam="string" className={classes.title}>
          {t("busdBalance")}
        </ValueCard.Title>
        <Grid container item xs={12} spacing={3}>
          <Grid container item xs={12} alignItems="flex-start">
            <ValueCard.Value
              variantParam="string"
              className={classes.value}
              value={`${float4(balance)} BUSD`}
            />
            {!matches && (
              <Icon className={classes.icon} icon="BUSD" size={22} />
            )}
          </Grid>
        </Grid>
        {matches && (
          <Icon icon="BUSD" size={22} className={classes.iconMdDevice} />
        )}
      </Grid>
    </ValueCard>
  );
};

export default BUSDBalanceCard;
