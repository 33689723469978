import React, { useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import PresaleTransferConfig from "../../blockChainContexts/Testnet/PresaleTransfer/config";
import { useContractContext } from "../../context/ContractContext";
import { useWalletContext } from "../../context/WalletContext";
import { usePresaleContext } from "../../Pages/Presale/Context/PresaleContext";
import AlertComponent from "../Alert";
import { AlertStatus } from "../../constants/Status";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Light!important",
    fontSize: "16px!important",
    color: theme.palette.colors.grey11,
  },
}));
const ConnectionRequired = ({
  connectionTranslationKey,
  approveTranslationKey,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    status: undefined,
    loading: false,
  });
  const { setWalletConnected, isWalletConnected } = useWalletContext();
  const { isHoloClearApproved, setHoloClearApproved } = usePresaleContext();
  const { PholoContract } = useContractContext();

  const ButtonOnClick = () => {
    if (!isWalletConnected) {
      setWalletConnected(true);
    } else if (!isHoloClearApproved) {
      approveHoloClear();
    }
  };


  const approveTrx = () => new Promise(async (resolve, reject) => {
    try {
      let maxValue = "115792089237316195423570985008687907853269984665640564039457584007913129639935" // hard coded 2^256-1
      const transaction = await PholoContract.approve(PresaleTransferConfig.address, maxValue);
      await transaction.wait();
      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });

  const approveHoloClear = async () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      loading: true
    }));
    try {
      await approveTrx();
      setHoloClearApproved(true);
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: "",
        status: AlertStatus.Success,
        loading: false
      }));
    } catch (e) {
      console.log(e)
      setHoloClearApproved(false);
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: t("errors.transaction"),
        status: AlertStatus.Error,
        loading: false
      }));
    }
  }
  const closeAlert = () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      message: "",
      status: undefined,
      loading: false,
    }));
  };

  if (!isHoloClearApproved || !isWalletConnected) {
    return (
      <Grid
        container
        direction={{ xs: "column-reverse", sm: "row" }}
        rowSpacing={2}
      >
        <Grid item xs={12} sm={9}>
          <Typography
            className={classes.text}
            textAlign={{ xs: "center", sm: "left" }}
            justifyContent={{ xs: "center", sm: "start" }}
          >
            <Trans
              i18nKey={
                !isWalletConnected
                  ? connectionTranslationKey
                  : approveTranslationKey
              }
              components={[
                <Typography
                  className={classes.text}
                  display="inline"
                  fontWeight={700}
                />,
                <br />,
              ]}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            onClick={ButtonOnClick}
            variant="contained"
            color="secondary"
            size="large"
          >
            {!isWalletConnected && !submitStatus.loading && t("buttons.connect")}
            {isWalletConnected && !submitStatus.loading && t("buttons.approve")}
            {submitStatus.loading && <CircularProgress size={18} />}
          </Button>
        </Grid>
        {submitStatus.status && <AlertComponent severity={submitStatus.status} message={submitStatus.message} onclickHandler={closeAlert} />}
      </Grid>
    );
  } else {
    return children;
  }
};

export default ConnectionRequired;
