import { Alert, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function AlertComponent(props) {
  const { onclickHandler, message, severity } = props;

  return (
    <Grid item xs={12}>
      <Alert
        action={
          onclickHandler && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onclickHandler}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
        severity={severity}
      >
        {message}
      </Alert>
    </Grid>
  );
}
