import { Grid } from "@mui/material";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HoloClearConfig from "../../../blockChainContexts/Testnet/HoloClear/config";
import RouterConfig from "../../../blockChainContexts/Testnet/Router/config";
import ValueCard from "../../../Components/ValueCard";
import { useApiResult } from "../../../context/ApiResultContext";
import { useContractContext } from "../../../context/ContractContext";
import { float4 } from "../../../utils/Number";
import { stringToByte32 } from "../../../utils/String";
import { useStakeContext } from "../Context/Stake";

const ValueDeposited = () => {
  const { t } = useTranslation();
  const [depositedValue, setDepositedValue] = useState("-");
  const { HoloYieldContract, MarketOracleContract } = useContractContext();
  const { data } = useApiResult();
  const { updateCards } = useStakeContext();
  useEffect(() => {
    getValueDeposited();
  }, [updateCards]);
  const getValueDeposited = async () => {
    try {
      const holobnb_array_req = MarketOracleContract.get_price_data(
        stringToByte32("HOLOBNB")
      );
      const bnbbusd_array_req = MarketOracleContract.get_price_data(
        stringToByte32("BNBBUSD")
      );
      const cir_supply_req = HoloYieldContract.circulatingSupply();

      Promise.all([holobnb_array_req, bnbbusd_array_req, cir_supply_req])
        .then(([holobnb_array, bnbbusd_array, cir_supply_gwei]) => {
          const holobnb =
            holobnb_array[1] == HoloClearConfig.address
              ? holobnb_array[6]
              : holobnb_array[5];
          const bnbbusd =
            bnbbusd_array[1] == RouterConfig.address
              ? bnbbusd_array[6]
              : bnbbusd_array[5];
          const holobusd = bnbbusd / holobnb;
          const cir_supply = ethers.utils.formatEther(cir_supply_gwei);
          const _depositedValue = cir_supply * holobusd;
          setDepositedValue(_depositedValue.toLocaleString());
        })
        .catch((errors) => {
          console.log(errors);
          setDepositedValue("Error");
        });
    } catch (error) {
      console.log(error);
      setDepositedValue("Error");
    }
  };
  return (
    <ValueCard size="lg">
      <Grid xs={12}>
        <ValueCard.Title>{t("stakePage.ValueDeposited")} </ValueCard.Title>
        <ValueCard.Value noWrap value={`$ ${depositedValue}`} />
        <ValueCard.Change change={float4(data?.change?.value_deposited)} />
      </Grid>
    </ValueCard>
  );
};

export default ValueDeposited;
