import React from "react";
import { Card, CardContent, Chip, Button, Divider, Grid, CircularProgress, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useWalletContext } from "../../../context/WalletContext";
import { usePoolContext } from "../Context/PoolContext";
import moment from "moment";

const useStyles = makeStyles({
  header: {
    display: "block",
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "29px",
    letterSpacing: "0.016em",
    marginBottom: "30px!important",
  },
});

const Winners = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Typography variant="string" className={classes.header}>
          {t("poolPage.winnerTitle")}
        </Typography>
        <WinnerTable />
      </CardContent>
    </Card>
  );
};

const useWinnerStyles = makeStyles((theme) => ({
  header: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.colors.grey10,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  date: {
    display: "block",
    width: "100%",
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.colors.grey4,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 12,
    },
  },
  amount: {
    display: "block",
    width: "100%",
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.colors.grey4,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 12,
    },
  },
  address: {
    width: "100%",
    maxWidth: "150px!important",
    paddingTop: "7px!important",
    paddingBottom: "6px!important",
    fontSize: "14px!important",
    height: "min-content!important",
    backgroundColor: theme.palette.colors.chipDefault + "!important",
    fontWeight: 400,
    color: theme.palette.colors.holoDark + "!important",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 12,
    },
  },
  claimButton: {
    borderRadius: "25px!important",
    paddingTop: "9px!important",
    paddingBottom: "6px!important",
    height: "auto!important",
  },
  divider: {
    marginTop: "10px!important",
    marginBottom: "10px!important",
  },
}));
const WinnerTable = () => {
  const { t } = useTranslation();
  const classes = useWinnerStyles();
  const { isWalletConnected, setWalletConnected, account } = useWalletContext();
  const { winners, handleClaimPrizeClick, submitStatus } = usePoolContext();
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      {winners !== undefined && winners.length > 0 ? winners.map((winner, i) => (
        <Grid key={i} container item xs={12} spacing={2} alignItems="center">
          <Grid
            item
            xs={4}
            sm={4}
            display={{ xs: "block", sm: i === 0 ? "block" : "none" }}
            className={classes.header}
          >
            {t("poolPage.date")}
          </Grid>
          <Grid
            item
            xs={4}
            sm={2}
            lg={3}
            display={{ xs: "block", sm: i === 0 ? "block" : "none" }}
            className={classes.header}
          >
            {t("poolPage.amount")}
          </Grid>
          <Grid
            item
            xs={4}
            sm={3}
            display={{ xs: "block", sm: i === 0 ? "block" : "none" }}
            className={classes.header}
          >
            <Typography display={{ xs: "block", sm: "none" }}>
              {t("poolPage.address")}
            </Typography>
            <Typography display={{ xs: "none", sm: "block" }}>
              {t("poolPage.walletAddress")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            lg={1}
            display={{ xs: "none", sm: i === 0 ? "block" : "none" }}
            className={classes.header}
          >
            {t("poolPage.verify")}
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography
              className={classes.date}
              display={{ xs: "block", sm: "none" }}
            >
              {moment.unix(winner?.timeDraw).utc().format("Do MMMM YYYY UTC")}
            </Typography>
            <Typography
              className={classes.date}
              display={{ xs: "none", sm: "block" }}
            >
              {moment.unix(winner?.timeDraw).utc().format("Do MMMM YYYY, HH:mm UTC")}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2} lg={3}>
            <Typography className={classes.amount} variant="string">
              {"$" + Number(winner?.amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <Chip
              className={classes.address}
              label={winner?.address?.slice(0, 5) + "..." + winner?.address?.substr(-4)}
            />
          </Grid>
          <Grid item xs={12} sm={3} lg={2}>
            {!isWalletConnected ? (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled
                className={classes.claimButton}
                size="small"
                onClick={() => setWalletConnected(true)}
              >
                {winner?.claimed ? t("poolPage.claimedPrize") : t("poolPage.unclaimed")}
              </Button>
            ) : (
              <>
                {winner.claimed ? (
                  <>
                    <Grid display={{ xs: "block" }}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        disabled={winner?.claimed}
                        className={classes.claimButton}
                        size="small">
                        {t("poolPage.claimedPrize")}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid display={{ xs: "block" }}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      disabled={winner?.address !== account} //user can only claim their prize
                      className={classes.claimButton}
                      onClick={() => handleClaimPrizeClick(winner)}
                      size="small">
                      {submitStatus.loading && winner?.address === account && <CircularProgress color="warning" size={18} />}
                      {submitStatus.loading && winner?.address !== account && t("poolPage.claimPrize")}
                      {!submitStatus.loading && t("poolPage.claimPrize")}
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      )) : (<></>)}
    </Grid>
  );
};

export default Winners;
