import React from "react";
import CustomTextField from ".";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    fontSize: "17px!important",
    minHeight: 48,
  },
});
const PriceField = ({
  readOnly = false,
  InputProps = {},
  maxValue = 0,
  hideMaxButton = false,
  ...props
}) => {
  const classes = useStyles();
  const maxOnClick = () => {
    props.onChange(maxValue);
  };
  const onChange = (e) => {
    return props.onChange(e.target.value);
  };
  return (
    <CustomTextField
      fullWidth
      variant="outlined"
      placeholder={"..."}
      InputProps={{
        endAdornment: !hideMaxButton && !readOnly && (
          <MaxButton onClick={maxOnClick} />
        ),
        classes: { root: classes.root },
        readOnly: readOnly,
        ...InputProps,
      }}
      {...props}
      onChange={onChange}
    />
  );
};
const MaxButton = ({ onClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <Button
      size="large"
      color={"secondary"}
      onClick={onClick}
      style={{ fontWeight: 700 }}
    >
      {t("buttons.max")}
    </Button>
  );
};
export default PriceField;
