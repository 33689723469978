import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";
import { AlertStatus } from "../../../constants/Status";
import constate from "constate";

import HoloClearConfig from "../../../blockChainContexts/Testnet/HoloClear/config";
import RouterConfig from "../../../blockChainContexts/Testnet/Router/config";
import BusdConfig from "../../../blockChainContexts/Testnet/Busd/config";
import { useWalletContext } from "../../../context/WalletContext";
import { stringToByte32 } from "../../../utils/String";
import { useContractContext } from "../../../context/ContractContext";
import { tokenData } from "./tokenData";
import SwapConfig from "../../../blockChainContexts/Testnet/Swap/config";

const [SwapContextProvider, useSwapContext] = constate(() => {
  const { t } = useTranslation();
  const { account, isWalletConnected, library } = useWalletContext();
  const {
    HoloClearContract,
    BUSDContract,
    SwapContract,
    MarketOracleContract,
    PancakeSwapContract,
  } = useContractContext();

  const [showApproveHoloClearButton, setShowApproveHoloClearButton] =
    useState(false);
  const [showApproveBusdButton, setShowApproveBusdButton] = useState(false);
  const [approvedHoloClear, setApprovedHoloClear] = useState(false);
  const [approvedBusd, setApprovedBusd] = useState(false);
  const [ableToSwapBusd, setAbleToSwapBusd] = useState(true); // Enable or Disable Busd swap option (set true for enabled)
  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    status: undefined,
    loading: false,
  });
  const [loading, setLoading] = useState(true); // Page first loading
  const [approveTrxLoading, setApproveTrxLoading] = useState(false);
  const [tokens, setTokens] = useState(tokenData);
  const [fromToken, setFromToken] = useState(tokens[0]);
  const [toToken, setToToken] = useState(tokens[1]);
  const [fromTokenValue, setFromTokenValue] = useState({
    default: "",
    fixed: "",
  });
  const [toTokenValue, setToTokenValue] = useState({ default: "", fixed: "" });
  const [taxes, setTaxes] = useState({
    holo: {
      sell: 0,
      buy: 0,
    },
  });
  const findToken = (selectedToken) => {
    return tokens.find((token) => token.value === selectedToken);
  };

  useEffect(() => {
    if (fromToken.value === "BUSD") {
      setShowApproveBusdButton(true);
      setShowApproveHoloClearButton(false);
    } else if (fromToken.value === "$HOLO") {
      setShowApproveBusdButton(false);
      setShowApproveHoloClearButton(true);
    } else if (fromToken.value === "BNB") {
      setShowApproveHoloClearButton(false);
    }
    setApprovedBusd(false);
    setApprovedHoloClear(false);
  }, [toToken, fromToken]);

  useEffect(() => {
    try {
      if (!account || !isWalletConnected) return;

      //approve button will be always visible for the first time swap
      // checkHoloClearAllowanceAmount();
      // checkBusdAllowanceAmount();
      fetchUserBalanceData();
    } catch (error) {
      console.log(error);
    }
  }, [account, isWalletConnected]);

  useEffect(() => {
    getTaxes();
    fetchPublicTokenData();
  }, []);

  // const checkHoloClearAllowanceAmount = async () => {
  //   const holoClearAllowanceAmount = await HoloClearContract.allowance(account, RouterConfig.address);
  //   setApprovedHoloClear(ethers.utils.formatEther(holoClearAllowanceAmount) > 0);
  // }
  // const checkBusdAllowanceAmount = async () => {
  //   const busdAllowanceAmount = await BUSDContract.allowance(account, RouterConfig.address);
  //   setApprovedBusd(ethers.utils.formatEther(busdAllowanceAmount) > 0);
  // }

  const fetchPublicTokenData = async () => {
    await setBNBPrice();
    await setHoloClearPrice();
    setLoading(false);
  };

  const fetchUserBalanceData = async () => {
    await setBNBBalance();
    await setBUSDBalance();
    await setHoloClearBalance();
  };

  const setHoloClearBalance = async () => {
    const holoClearBalance = await HoloClearContract.balanceOf(account);
    const holoClearBalanceFormatted =
      ethers.utils.formatEther(holoClearBalance);

    let updatedTokens = tokens.map((token) => {
      if (token.label === "HOLOCLEAR") {
        token.balance = holoClearBalanceFormatted;
      }
      return token;
    });

    setTokens(updatedTokens);
  };

  const setBNBBalance = async () => {
    var balance = await library.getBalance(account);
    const bnbBalance = ethers.utils.formatEther(balance);

    let updatedTokens = tokens.map((token) => {
      if (token.label === "BNB") {
        token.balance = bnbBalance;
      }
      return token;
    });

    setTokens(updatedTokens);
  };

  const setBNBPrice = async () => {
    const bnbBusdContractReserves = await PancakeSwapContract.getReserves();
    const bnbPrice =
      bnbBusdContractReserves._reserve1 / bnbBusdContractReserves._reserve0;

    let updatedTokens = tokens.map((token) => {
      if (token.label === "BNB") {
        token.price = bnbPrice;
      }
      if (token.label === "BUSD") {
        token.bnbPrice = bnbPrice;
      }
      return token;
    });

    setTokens(updatedTokens);
  };

  const setHoloClearPrice = async () => {
    try {
      const holobnbArray = await MarketOracleContract.get_price_data(
        stringToByte32("HOLOBNB")
      );
      const bnbbusdArray = await MarketOracleContract.get_price_data(
        stringToByte32("BNBBUSD")
      );
      const holobnb =
        holobnbArray[1] === HoloClearConfig.address
          ? holobnbArray[6]
          : holobnbArray[5];
      const bnbbusd =
        bnbbusdArray[1] === RouterConfig.address
          ? bnbbusdArray[6]
          : bnbbusdArray[5];
      const holobusd = bnbbusd / holobnb;

      let updatedTokens = tokens.map((token) => {
        if (token.label === "HOLOCLEAR") {
          token.price = holobusd;
          token.bnbPrice = ethers.utils.formatEther(holobnb.toString());
        }
        return token;
      });

      setTokens(updatedTokens);
    } catch (error) {
      console.log(error);
    }
  };

  const setBUSDBalance = async () => {
    var balance = await BUSDContract.balanceOf(account);
    const busdBalance = ethers.utils.formatEther(balance);

    let updatedTokens = tokens.map((token) => {
      if (token.label === "BUSD") {
        token.balance = busdBalance;
      }
      return token;
    });

    setTokens(updatedTokens);
  };

  const handleApproveHoloClearClick = async () => {
    if (fromTokenValue.default <= 0) {
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: t("errors.insufficientAmount", {
          amount: fromTokenValue.default ? fromTokenValue.default : 0,
        }),
        status: AlertStatus.Warning,
      }));
      return;
    }
    setApproveTrxLoading(true);
    try {
      approveHoloClear(fromTokenValue.default)
        .then(() => {
          setSubmitStatus((prevState) => ({
            ...prevState,
            message: t("success.transaction"),
            status: AlertStatus.Success,
          }));
          setApproveTrxLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSubmitStatus((prevState) => ({
            ...prevState,
            message: t("errors.transaction"),
            status: AlertStatus.Error,
          }));
          setApproveTrxLoading(false);
        })
        .finally(() => {
          setSubmitStatus((prevState) => ({
            ...prevState,
          }));
          setApproveTrxLoading(false);
        });
    } catch (e) {
      console.log(e);
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: t("errors.transaction"),
        status: AlertStatus.Error,
      }));
      setApproveTrxLoading(false);
    }
  };

  const approveHoloClear = (value) =>
    new Promise(async (resolve, reject) => {
      try {
        const approve = await HoloClearContract.approve(
          SwapConfig.address,
          ethers.utils.parseUnits(value.toString(), "ether")
        );
        await approve.wait();
        setApprovedHoloClear(true);

        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });

  const handleApproveBusdClick = async () => {
    setApproveTrxLoading(true);
    if (fromTokenValue.default <= 0) {
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: t("errors.insufficientAmount", {
          amount: fromTokenValue.default ? fromTokenValue.default : 0,
        }),
        status: AlertStatus.Warning,
      }));
      return;
    }
    try {
      approveBusd(fromTokenValue.default)
        .then(() => {
          setSubmitStatus((prevState) => ({
            ...prevState,
            message: t("success.transaction"),
            status: AlertStatus.Success,
          }));
          setApproveTrxLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSubmitStatus((prevState) => ({
            ...prevState,
            message: t("errors.transaction"),
            status: AlertStatus.Error,
          }));
          setApproveTrxLoading(false);
        })
        .finally(() => {
          setSubmitStatus((prevState) => ({
            ...prevState,
          }));
          setApproveTrxLoading(false);
        });
    } catch (e) {
      console.log(e);
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: t("errors.transaction"),
        status: AlertStatus.Error,
      }));
      setApproveTrxLoading(false);
    }
  };

  const approveBusd = (value) =>
    new Promise(async (resolve, reject) => {
      try {
        const approve = await BUSDContract.approve(
          SwapConfig.address,
          ethers.utils.parseUnits(value.toString(), "ether")
        );
        await approve.wait();
        setApprovedBusd(true);

        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });

  const clearInputs = () => {
    setFromTokenValue({ default: "", fixed: "" });
    setToTokenValue({ default: "", fixed: "" });
    setApprovedBusd(false);
    setApprovedHoloClear(false);
  };

  const handleSwapTokensClick = async () => {
    if (Number(fromTokenValue.default) < Number(fromToken.minSwapAmount)) {
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: t("errors.minSwapAmount", {
          minSwapAmount: fromToken.minSwapAmount,
          token: fromToken.value,
        }),
        status: AlertStatus.Warning,
      }));
    } else if (Number(fromTokenValue.default) > Number(fromToken.balance)) {
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: t("errors.insufficientTokenBalance", {
          token: fromToken.value,
          balance: fromToken.balance,
        }),
        status: AlertStatus.Warning,
      }));
    } else {
      setSubmitStatus((prevState) => ({
        ...prevState,
        loading: true,
        message: t("info.transaction"),
        status: AlertStatus.Info,
      }));
      try {
        callSwapFunc(fromTokenValue.default)
          .then(() => {
            setSubmitStatus((prevState) => ({
              ...prevState,
              message: t("success.transaction"),
              status: AlertStatus.Success,
              loading: false,
            }));
            clearInputs();
            fetchUserBalanceData();
          })
          .catch((err) => {
            console.log(err);
            setSubmitStatus((prevState) => ({
              ...prevState,
              message: t("errors.transaction"),
              status: AlertStatus.Error,
              loading: false,
            }));
          })
          .finally(() => {
            setSubmitStatus((prevState) => ({
              ...prevState,
              loading: false,
            }));
          });
      } catch (e) {
        console.log(e);
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("errors.transaction"),
          status: AlertStatus.Error,
          loading: false,
        }));
      }
    }
  };

  const callSwapFunc = async (value) => {
    if (fromToken.value === "BNB" && toToken.value === "$HOLO") {
      await swapTokensBnbToHoloClear(value);
    } else if (fromToken.value === "$HOLO" && toToken.value === "BNB") {
      await swapTokensHoloClearToBnb(value);
    } else if (fromToken.value === "BUSD" && toToken.value === "$HOLO") {
      await swapTokensBusdToHoloClear(value);
    } else if (fromToken.value === "$HOLO" && toToken.value === "BUSD") {
      await swapTokensHoloClearToBusd(value);
    }
  };

  const swapTokensBnbToHoloClear = (value) =>
    new Promise(async (resolve, reject) => {
      try {
        const swap = await SwapContract.swap_bnb_to_holo(account, {
          from: account,
          value: ethers.utils.parseUnits(value.toString(), "ether"),
        });
        await swap.wait();

        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });

  const swapTokensHoloClearToBnb = (value) =>
    new Promise(async (resolve, reject) => {
      try {
        const swap = await SwapContract.swap_holo_to_bnb(
          account,
          ethers.utils.parseUnits(value.toString(), "ether")
        );
        await swap.wait();

        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });

  const swapTokensBusdToHoloClear = (value) =>
    new Promise(async (resolve, reject) => {
      try {
        const swap = await SwapContract.swap_quote_to_holo(
          account,
          ethers.utils.parseUnits(value.toString(), "ether"),
          BusdConfig.address
        );
        await swap.wait();

        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });

  const swapTokensHoloClearToBusd = (value) =>
    new Promise(async (resolve, reject) => {
      try {
        const swap = await SwapContract.swap_holo_to_quote(
          account,
          ethers.utils.parseUnits(value.toString(), "ether"),
          BusdConfig.address
        );
        await swap.wait();

        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });
  const getTaxes = async () => {
    Promise.all([
      HoloClearContract.buy_fee(),
      HoloClearContract.sell_fee(),
    ]).then(([holoBuyFee, holoSellFee]) => {
      setTaxes((prevState) => ({
        ...prevState,
        holo: {
          buy: ethers.utils.formatEther(holoBuyFee),
          sell: ethers.utils.formatEther(holoSellFee),
        },
      }));
    });
  };
  return {
    fromToken,
    setFromToken,
    toToken,
    setToToken,
    tokens,
    submitStatus,
    setSubmitStatus,
    loading,
    handleSwapTokensClick,
    fromTokenValue,
    setFromTokenValue,
    toTokenValue,
    setToTokenValue,
    ableToSwapBusd,
    findToken,
    showApproveHoloClearButton,
    showApproveBusdButton,
    setShowApproveHoloClearButton,
    setShowApproveBusdButton,
    handleApproveHoloClearClick,
    handleApproveBusdClick,
    approvedBusd,
    approvedHoloClear,
    approveTrxLoading,
    taxes,
  };
});

useSwapContext.Provider = SwapContextProvider;
export { useSwapContext };
