import constate from "constate";
import { useState } from "react";
import { useCountdown } from "../../../Hooks/useCountDown";
import { nowSubctractTime } from "../../../utils/Date";

const [GiltContextProvider, useGiltContext] = constate(({ gilt }) => {
  const [claimStatus, setclaimStatus] = useState(gilt.status);
  const setClaimable = () => {
    if (claimStatus !== "claimed" && claimStatus !== "active")
      setclaimStatus("active");
  };
  const remainingTime = useCountdown(
    gilt.maturity_time.toNumber(),
    setClaimable
  );
  return { remainingTime, claimStatus };
});
useGiltContext.Provider = GiltContextProvider;
export { useGiltContext };
