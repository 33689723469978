import { makeStyles } from "@mui/styles";
import Logo from "../../assets/logo.svg";
import { white } from "../../constants/Colours";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    minHeight: "100vh",
  },
  logo: {
    animation: `$rotateAnim 2000ms infinite ease`,
  },
  "@keyframes rotateAnim": {
    "0%": {
      transform: "rotate(0)",
      opacity: 1,
    },
    "50%": {
      opacity: 0.4,
    },
    "100%": {
      opacity: 1,
      transform: "rotate(360deg)",
    },
  },
}));

const LoadingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img
        alt="HOLOCLEAR_Logo"
        src={Logo}
        width="100"
        className={classes.logo}
      />
    </div>
  );
};

export default LoadingPage;
