import * as React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { grey3, grey4, dividerColor } from "../../constants/Colours";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Icon from "../../Components/Icon";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
  },
  listItem: {
    "&:hover": {
      opacity: 0.6,
    },
  },
  title: {
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 26,
    color: theme.palette.colors.grey3,
  },
  text: {
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    [theme.breakpoints.down("lg")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    lineHeight: 24,
    color: theme.palette.colors.grey4,
  },
  icon: {
    marginRight: 18,
  },
}));

export default function ResourcesCard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography component="div" className={classes.title}>
          {t("resources")}
        </Typography>
        <List>
          <ListItem
            className={classes.listItem}
            style={{ paddingLeft: 0, cursor: "pointer", marginBottom: 8 }}
            component="a"
            href={"https://docs.holoclear.xyz"}
            target="_blank"
            secondaryAction={
              <IconButton style={{ right: 0 }} edge="end" aria-label="arrow">
                <KeyboardArrowRightIcon style={{ right: 0 }} />
              </IconButton>
            }
          >
            <Icon
              icon="File"
              size="20"
              className={classes.icon}
              color={theme.palette.colors.holoBlack}
            />
            <ListItemText className={classes.text} primary={t("readDocs")} />
          </ListItem>
          <Divider style={{ background: dividerColor }} />
          <ListItem
            className={classes.listItem}
            style={{
              paddingLeft: 0,
              paddingTop: 16,
              marginBottom: 8,
              cursor: "pointer",
            }}
            component="a"
            target="_blank"
            href={"https://t.me/holoclear"}
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <KeyboardArrowRightIcon
                  color={theme.palette.colors.holoBlack}
                />
              </IconButton>
            }
          >
            <Icon
              icon="TelegramLogo"
              size="20"
              className={classes.icon}
              color={theme.palette.colors.holoBlack}
            />
            <ListItemText
              className={classes.text}
              primary={t("joinCommunity")}
            />
          </ListItem>
          <Divider style={{ background: dividerColor }} />
          <ListItem
            className={classes.listItem}
            style={{ paddingLeft: 0, paddingTop: 16, cursor: "pointer" }}
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <KeyboardArrowRightIcon
                  color={theme.palette.colors.holoBlack}
                />
              </IconButton>
            }
            component="a"
            href={"https://twitter.com/holoclear"}
            target="_blank"
          >
            <Icon
              icon="Twitter"
              size="20"
              className={classes.icon}
              color={theme.palette.colors.holoBlack}
            />
            <ListItemText
              className={classes.text}
              primary={t("followTwitter")}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
