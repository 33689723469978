import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SmallHoloPinkButton } from "../../../Components/Button/ConnectWalletButtons";
import CustomizedSnackbar from "../../../Components/Snackbar";
import { useContractContext } from "../../../context/ContractContext";
import { useWalletContext } from "../../../context/WalletContext";
import { useGiltContext } from "../Context/GiltContext";
import { useMintContext } from "../Context/MintContext";

const ClaimButton = ({ giltIndex }) => {
  const { account } = useWalletContext();
  const { GiltsContract } = useContractContext();
  const { updateGilts } = useMintContext();
  const [claimStatus, setclaimStatus] = useState({
    loading: false,
    message: "",
    status: undefined,
  });
  const { t } = useTranslation();
  const claim = async () => {
    setclaimStatus((prevStatus) => ({
      ...prevStatus,
      loading: true,
      message: "",
      status: undefined,
    }));
    try {
      const transaction = await GiltsContract.claim(account, giltIndex);
      await transaction.wait();
      updateGilts();
    } catch (error) {
      console.error(error);
      setclaimStatus((prevStatus) => ({
        ...prevStatus,
        message: t("errors.transaction"),
        status: "warning",
      }));
    }
    setclaimStatus((prevStatus) => ({
      ...prevStatus,
      loading: false,
    }));
  };
  const closeErrorMessage = () => {
    setclaimStatus((prevStatus) => ({
      ...prevStatus,
      message: "",
      status: undefined,
    }));
  };
  return (
    <>
      <CustomizedSnackbar
        open={claimStatus.status !== undefined}
        setOpen={closeErrorMessage}
        message={claimStatus.message}
        severity={claimStatus.status}
      />
      <SmallHoloPinkButton
        loading={claimStatus.loading}
        onClickHandler={claim}
        label={t("buttons.claim")}
      />
    </>
  );
};

export default ClaimButton;
