import WalletConnect from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import NetworkConfig from "../NetworkConfig";
export const CHAINID = NetworkConfig.chainId;
export const RPCURL = NetworkConfig.rpcUrl;

//web3 connection after wallet connect
export const providerOptions = {
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: process.env.INFURA_KEY, // binance does not support. It's for Etheruem.
      rpc: {
        [CHAINID]: RPCURL, // BSC Mainnet
      },
    },
  },
};

// read only contract interaction
export const defaultProvider = new ethers.getDefaultProvider(RPCURL);
