import { useState, useEffect } from "react";
import constate from "constate";
import Web3Modal from "web3modal";
import {
  CHAINID,
  providerOptions,
} from "../blockChainContexts/ProviderOptions";
import { ethers } from "ethers";
import PHoloConfig from "../blockChainContexts/Testnet/pHolo/config";
import { toHex } from "../utils/String";

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
});

const [WalletContextProvider, useWalletContext] = constate(() => {
  const [account, setAccount] = useState();
  const [provider, setProvider] = useState();

  const [library, setLibrary] = useState();
  const [chainId, setChainId] = useState();
  const [pholoContract, setPholoContract] = useState();
  
  const [showSwitchNetwork, setShowSwitchNetwork] = useState(false);

  // Already connected
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        window.location.reload(true); //when network is changed, reload the page to load everything from scratch
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
        }
      };
    }
  }, [provider]);

  // This method will describe any error to user
  const setError = (message) => {
    console.log("Error: ", message);
  };

  // Connect wallet to chain
  const connectWallet = async () => {
    try {
      // this method will ask user permission to connect wallet.
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);

      // Get current library
      const network = await library.getNetwork();

      // Store current Provider
      setProvider(provider);
      // Store current Library
      setLibrary(library);
      setShowSwitchNetwork(false);
      if (network !== "" && network.chainId !== CHAINID) {
        disconnect();
        setShowSwitchNetwork(true);
        return;
      }
      // returns an array containing the Metamask addresses currently connected
      const accounts = await library.listAccounts();
      if (accounts) {
        setAccount(accounts[0]);
      }

      setChainId(network.chainId);

      // Create pHolo Contract Instance
      const pholoContractInstance = new ethers.Contract(
        PHoloConfig.address,
        PHoloConfig.abi,
        library.getSigner()
      );

      setPholoContract(pholoContractInstance);
    } catch (error) {
      setError(error);
    }
  };

  const handleSwitchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(CHAINID) }],
      });
    } catch (switchError) {
      //if wallet does not have BSC network configured, there would be error.
      if (switchError.code === 4902) {
        alert(
          "Please make sure your wallet has Binance smart chain mainnet configuration"
        );
      }
    }
  };

  const getBNBBalance = async () => {
    var balance = await library.getBalance(account);
    const balanceInBNB = ethers.utils.formatEther(balance);

    return balanceInBNB;
  };

  const getPHOLOBalance = async () => {
    var balance = await pholoContract.balanceOf(account);
    const balanceInPHOLO = ethers.utils.formatEther(balance);

    return balanceInPHOLO;
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setPholoContract();
  };

  const disconnect = async () => {
    try {
      await web3Modal.clearCachedProvider();
      window.localStorage.clear();
      refreshState();
    } catch (err) {
      setError(err);
    }
  };

  const setWalletConnected = (status) => {
    if (status) {
      connectWallet();
    } else {
      disconnect();
    }
  };

  return {
    isWalletConnected: account !== undefined,
    setWalletConnected,
    library,
    chainId,
    pholoContract,
    account,
    showSwitchNetwork,
    getBNBBalance,
    getPHOLOBalance,
    handleSwitchNetwork,
  };
});

useWalletContext.Provider = WalletContextProvider;
export { useWalletContext };
