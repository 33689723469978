import { Grid } from "@mui/material";
import { styled } from "@mui/system";

export const GrowthChart = styled(Grid)({
  display: "flex",
  alignItems: "center",
})

export const GrowthChartIcon = styled('img')(({theme}) => ({
    width: "100%",
    maxWidth: 100
}));