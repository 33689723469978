import { ethers } from "ethers";

/**
 * Convert string to byte32
 * @param {string} string
 * @returns {string} byte32
 */
export const stringToByte32 = (text = "") => {
  let bytes32 = ethers.utils.formatBytes32String(text);
  return bytes32;
};

/**
 * Convert byte32 to string
 * @param {string} byte32
 * @returns {string} string
 */
export const byte32ToString = (byte32 = "0x") => {
  let text = ethers.utils.parseBytes32String("0x" + byte32);
  return text;
};


export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};