import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrowthChart, GrowthChartIcon } from "../../../Components/Styleds";
import ValueCard from "../../../Components/ValueCard";
import { useContractContext } from "../../../context/ContractContext";
import { ethers } from "ethers";
import { useApiResult } from "../../../context/ApiResultContext";
import { useStakeContext } from "../Context/Stake";
import GraphStatus from "../../../Components/Icon/GraphStatus";
const Apy = () => {
  const { t } = useTranslation();
  const { data } = useApiResult();
  const [apyValue, setApyValue] = useState("-");
  const { updateCards } = useStakeContext();
  const { HoloYieldContract, VaultContract } = useContractContext();
  useEffect(() => {
    getApyValue();
  }, [updateCards]);
  const getApyValue = async () => {
    try {
      const rate_req = HoloYieldContract.rate();
      const epoch_req = VaultContract.epoch();
      Promise.all([rate_req, epoch_req])
        .then(([rate_gwei, epoch_req]) => {
          const rate = ethers.utils.formatEther(rate_gwei);
          const epoch = epoch_req[0];
          const _apyValue = 100 * (Math.pow(rate, (365 * 86400) / epoch) - 1);
          setApyValue(parseFloat(_apyValue.toFixed(3)));
        })
        .catch((errors) => {
          console.log(errors);
          setApyValue("Error");
        });
    } catch (error) {
      console.log(error);
      setApyValue("Error");
    }
  };
  return (
    <ValueCard size="lg">
      <Grid xs={6}>
        <ValueCard.Title>{t("apy")} </ValueCard.Title>
        <ValueCard.Value value={`${apyValue}%`} />
        <ValueCard.Change change={data.change.apy} />
      </Grid>
      <GrowthChart xs={6} display={{ xs: "none", md: "flex" }}>
        <GraphStatus isDown={data.change.apy < 0} />
      </GrowthChart>
    </ValueCard>
  );
};

export default Apy;
