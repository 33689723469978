import * as React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { grey10, grey4 } from "../../../constants/Colours";
import ChipField from "../../../Components/Chip";
import { Button } from "@mui/material";
import { float2, float3 } from "../../../utils/Number";
import ClaimButton from "./ClaimButton";
import { useGiltContext } from "../Context/GiltContext";
import VestingTime from "./VestingTime";
const StyledTableCell = styled(TableCell)((props) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 12,
    letterSpacing: "0.75px",
    color: props.theme.palette.colors.grey10,
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    lineHeight: "24px",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    letterSpacing: "0.75px",
    color: props.theme.palette.colors.grey4,
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    lineHeight: "24px",
    fontWeight: props.fontWeight || 400,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

export default function MyGiltsTable(props) {
  const { rows, showClaimButtons } = props;
  const { t } = useTranslation();
  return (
    <Table sx={{ minWidth: 700 }} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>{t("mintingPage.minted")}</StyledTableCell>
          <StyledTableCell align="center">
            {t("mintingPage.discount")}
          </StyledTableCell>
          <StyledTableCell align="center">
            {t("mintingPage.interesGained")}
          </StyledTableCell>
          <StyledTableCell>{t("mintingPage.vestingRemaning")}</StyledTableCell>
          <StyledTableCell>{t("mintingPage.currentValue")}</StyledTableCell>
          {showClaimButtons && <StyledTableCell></StyledTableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <useGiltContext.Provider gilt={row}>
            <StyledTableRow key={row.minted}>
              <StyledTableCell component="th" scope="row">
                {t("mintingPage.holo", { value: float3(row.mint) })}
              </StyledTableCell>
              <StyledTableCell>
                <ChipField
                  componentWidth={70}
                  margin={"auto"}
                  width={"max-content"}
                  label={`${float2(row.discount)}%`}
                />
              </StyledTableCell>
              <StyledTableCell>
                <ChipField
                  componentWidth={130}
                  margin={"auto"}
                  width={"max-content"}
                  label={t("mintingPage.holo", {
                    value: float3(row.interest_gained),
                  })}
                />
              </StyledTableCell>
              <StyledTableCell>
                <VestingTime />
              </StyledTableCell>
              <StyledTableCell fontWeight={700}>
                {t("mintingPage.holo", { value: float3(row.current_value) })}
              </StyledTableCell>
              {showClaimButtons && (
                <StyledTableCell>
                  <ClaimButtonSection giltIndex={row._id} />
                </StyledTableCell>
              )}
            </StyledTableRow>
          </useGiltContext.Provider>
        ))}
      </TableBody>
    </Table>
  );
}

export const ClaimButtonSection = ({ giltIndex }) => {
  const { claimStatus } = useGiltContext();
  const { t } = useTranslation();
  return (
    <>
      {claimStatus === "not-active" && (
        <Button fullWidth disabled variant="claim" color="primary">
          {t("buttons.claim")}
        </Button>
      )}
      {claimStatus === "active" && <ClaimButton giltIndex={giltIndex} />}
      {claimStatus === "claimed" && (
        <Button fullWidth disabled variant="claimed" color="primary">
          {t("buttons.claimed")}
        </Button>
      )}
    </>
  );
};
