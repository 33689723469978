import React from "react";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import Icon from "../../../Components/Icon";
import GiltsFieldHeader from "./GiltsFieldHeader";
import GiltsFieldValue from "./GiltsFieldValue";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 12,
  },
}));

const TokenField = ({ isBusd = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:900px)");
  let tokenBasics = {
    name: "bnb",
    icon: "binance",
  };
  if (isBusd) {
    tokenBasics = {
      name: "busd",
      icon: "BUSD",
    };
  }
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid
        container
        item
        xs={12}
        justifyContent={matches ? "center" : "flex-start"}
      >
        <GiltsFieldHeader headerText={t("mintingPage.token")} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent={matches ? "center" : "flex-start"}
      >
        <Icon icon={tokenBasics.icon} size="20" className={classes.icon} />
        <GiltsFieldValue
          fontFamily="PowerGrotesk-Regular"
          value={t(`mintingPage.${tokenBasics.name}`)}
          fontSize={18}
        />
      </Grid>
    </Grid>
  );
};

export default TokenField;
