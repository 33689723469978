import constate from "constate";
import { ethers } from "ethers";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HoloClearConfig from "../../../blockChainContexts/Testnet/HoloClear/config";
import RouterConfig from "../../../blockChainContexts/Testnet/Router/config";
import { useContractContext } from "../../../context/ContractContext";
import { useWalletContext } from "../../../context/WalletContext";
import { secondsToPeriod } from "../../../utils/Date";
import { stringToByte32 } from "../../../utils/String";

const [MintContextProvider, useMintContext] = constate(() => {
  const { MarketOracleContract, GiltsContract, HoloYieldContract } =
    useContractContext();
  const { t } = useTranslation();
  const { isWalletConnected, account } = useWalletContext();
  const [myGilts, setMyGilts] = useState({
    data: [],
    status: undefined,
    loading: true,
    message: "",
  });
  const [holoPrice, setHoloPrice] = useState("-");
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  // get current Holo price
  useEffect(() => {
    getHoloPrice();
  }, []);
  // get my gilts
  useEffect(() => {
    if (isWalletConnected) {
      getMyGilts();
    }
    return () => {
      setMyGilts((prevState) => ({
        ...prevState,
        data: [],
        status: undefined,
        loading: true,
        message: "",
      }));
    };
  }, [isWalletConnected]);
  const getHoloPrice = async () => {
    try {
      const holobnb_array_req = MarketOracleContract.get_price_data(
        stringToByte32("HOLOBNB")
      );
      const bnbbusd_array_req = MarketOracleContract.get_price_data(
        stringToByte32("BNBBUSD")
      );
      Promise.all([holobnb_array_req, bnbbusd_array_req])
        .then(([holobnb_array, bnbbusd_array]) => {
          const holobnb =
            holobnb_array[1] === HoloClearConfig.address
              ? holobnb_array[6]
              : holobnb_array[5];
          const bnbbusd =
            bnbbusd_array[1] === RouterConfig.address
              ? bnbbusd_array[6]
              : bnbbusd_array[5];
          const holobusd = bnbbusd / holobnb;
          setHoloPrice(holobusd.toLocaleString());
        })
        .catch((errors) => {
          console.log(errors);
          setHoloPrice("Error");
        });
    } catch (error) {
      console.log(error);
      setHoloPrice("Error");
    }
  };
  const getMyGilts = async () => {
    setMyGilts((prevStatus) => ({
      ...prevStatus,
      loading: true,
      status: undefined,
      message: "",
    }));
    try {
      // Hata var macustan contract bekleniyor.
      const gilts = await GiltsContract.view_my_gilts(account);
      const formatted_gilts = await Promise.all(
        gilts.map(async (gilt) => {
          const current_value_gwei = await HoloYieldContract.balanceForGons(
            gilt.gons
          );
          const current_value = ethers.utils.formatEther(current_value_gwei);
          const mint = ethers.utils.formatEther(gilt.deposit);
          const maturity_time = gilt.maturity_time.toNumber();
          const vesting_remaining = Math.max(
            maturity_time - moment.utc().unix(),
            0
          );
          const discount =
            (1 - 1 / ethers.utils.formatEther(gilt.discount)) * 100;
          return {
            ...gilt,
            mint,
            discount,
            current_value,
            interest_gained: current_value - mint,
            vesting_remaining,
            formatted_vesting_time: secondsToPeriod(vesting_remaining),
            status: getGiltStatus(gilt.redeemed, vesting_remaining),
          };
        })
      );
      formatted_gilts.reverse();
      setMyGilts((prevStatus) => ({
        ...prevStatus,
        data: formatted_gilts,
      }));
    } catch (error) {
      console.log(error);
      setMyGilts((prevStatus) => ({
        ...prevStatus,
        status: "warning",
        message: t("errors.myGilts"),
      }));
    }
    setMyGilts((prevStatus) => ({
      ...prevStatus,
      loading: false,
    }));
  };
  const updateGilts = () => {
    if (isWalletConnected) {
      getMyGilts();
    } else {
      console.error("Wallet isn't connected yet.");
    }
  };
  const getGiltStatus = (redeemed, vesting_remaining) => {
    if (vesting_remaining > 0) return "not-active";
    else if (vesting_remaining === 0) {
      if (redeemed) {
        return "claimed";
      } else {
        return "active";
      }
    }
  };
  return { holoPrice, myGilts, updateGilts, selectedMarket, setSelectedMarket };
});
useMintContext.Provider = MintContextProvider;
export { useMintContext };
