import * as React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { white } from "../../constants/Colours";
import { List, ListItem } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "transparent!important",
  },
  cardContent: {
    paddingTop: 24,
    paddingLeft: 36,
    borderRadius: 20,
    [theme.breakpoints.down("md")]: {
      paddingTop: 24,
      paddingLeft: 30,
      paddingRight: 40,
      paddingBottom: 26,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 45,
      paddingLeft: 26,
      paddingRight: 50,
      paddingBottom: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: 50,
      paddingBottom: 42,
    },
    paddingBottom: 26,
    background: "linear-gradient(270deg, #FF5925 0%, #EB5C9D 100%)",
  },
  title: {
    color: theme.palette.colors.white,
    lineHeight: "24px",
    fontFamily: `UniversLTStd, "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 22,
    fontWeight: 700,
  },
  subTitle: {
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    paddingTop: 10,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.colors.white,
    opacity: 0.7,
  },
  listItem: {
    "&:hover": {
      opacity: 0.6,
    },
  },
}));

export default function HelpBubble() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="string" className={classes.title}>
          {t("needHelp")}
        </Typography>
        <List style={{ padding: 0 }}>
          <ListItem
            className={classes.listItem}
            style={{ padding: 0, cursor: "pointer" }}
            component="a"
            href={"https://docs.holoclear.xyz"}
            target="_blank"
          >
            <Typography component="div" className={classes.subTitle}>
              {t("checkoutDocumentation")}
            </Typography>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}
