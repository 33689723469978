import FirstTime from "./FirstTime";
import { useWalletContext } from "../../../context/WalletContext";
import ConnectWallet from "./ConnectWallet";
import { useStakeContext } from "../Context/Stake";
import Loading from "../../../Components/Loading";
import Deposit from "./Deposit";

const Steps = () => {
  const { isWalletConnected } = useWalletContext();
  const { isApproved, loading } = useStakeContext();
  if (loading) {
    return <Loading />;
  }
  if (!isWalletConnected) {
    return <ConnectWallet />;
  }
  if (!isApproved) {
    return <FirstTime onApprove={() => {}} />;
  }
  return <Deposit />;
};

export default Steps;
