import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { holoBlack, holoPink } from "../../constants/Colours";
import { Grid } from "@mui/material";
import { useContractContext } from "../../context/ContractContext";
import { useWalletContext } from "../../context/WalletContext";
import { ethers } from "ethers";
import { float3, float4 } from "../../utils/Number";
import FlatIconSelector from "../../Components/Icon/FlatIconSelector";
import ValueCard from "../../Components/ValueCard";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
  },

  UndepositedTitle: {
    color: holoBlack,
    fontSize: 18,
    [theme.breakpoints.between("md", "sm")]: {
      fontSize: 11,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
    },
  },
  value: {
    color: holoPink,
    fontSize: 25,
    fontFamily: "PowerGrotesk-Medium",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0.75px",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: "22px",
      letterSpacing: "0.03em",
    },
  },
  undepositedValue: {
    fontSize: 36,
    fontFamily: "PowerGrotesk-Medium",
    fontWeight: 700,
    lineHeight: "34px",
    letterSpacing: "0.75px",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 34,
      lineHeight: "34px",
    },
  },
  iconMdDevice: {
    paddingLeft: 32,
    paddingTop: 20,
  },
}));

export default function DepositedBalanceCard(props) {
  const {
    showDepositedBalance = false,
    showUndepositedBalance = false,
    showROI = false,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [depositedBalance, setDepositedBalance] = useState("-");
  const { account } = useWalletContext();
  const { HoloYieldContract, HoloClearContract, VaultContract } =
    useContractContext();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let depBal;
      if (showDepositedBalance) {
        depBal = await HoloYieldContract.balanceOf(account);
        depBal = ethers.utils.formatEther(depBal);
      }
      if (showUndepositedBalance) {
        depBal = await HoloClearContract.balanceOf(account);
        depBal = ethers.utils.formatEther(depBal);
      }
      if (showROI) {
        let rate_gwei = await HoloYieldContract.rate();
        let rate = ethers.utils.formatEther(rate_gwei);
        let epoch = await VaultContract.epoch();
        depBal = 100 * (Math.pow(rate, (7 * 86400) / epoch[0].toNumber()) - 1);
      }
      setDepositedBalance(depBal);
    } catch (error) {
      console.log(error);
      setDepositedBalance("Error");
    }
  };

  return (
    <ValueCard size="lg">
      <Grid container item xs={12}>
        {showUndepositedBalance ? (
          <ValueCard.Title
            variantParam="string"
            className={classes.UndepositedTitle}
          >
            {t("accountPage.myUndepositedBalance")}
          </ValueCard.Title>
        ) : (
          <ValueCard.Title variantParam="string" className={classes.title}>
            {showDepositedBalance && t("accountPage.myDepositedBalance")}{" "}
            {showROI && t("accountPage.7DayROI")}
          </ValueCard.Title>
        )}
        <Grid container item xs={12} spacing={3}>
          {showDepositedBalance && (
            <Grid container item xs={12}>
              <ValueCard.Value
                variantParam="string"
                className={classes.value}
                value={float4(depositedBalance, true)}
              />
            </Grid>
          )}
          {showUndepositedBalance && (
            <Grid container item xs={12}>
              <ValueCard.Value
                variantParam="string"
                className={classes.undepositedValue}
                value={float4(depositedBalance, true)}
              />
            </Grid>
          )}
          {showROI && (
            <Grid container item xs={12}>
              <ValueCard.Value
                variantParam="string"
                className={classes.value}
                value={float3(depositedBalance, true) + "%"}
              />
            </Grid>
          )}
          {(showDepositedBalance || showROI) && (
            <FlatIconSelector
              className={classes.iconMdDevice}
              value={depositedBalance}
            />
          )}
        </Grid>
      </Grid>
    </ValueCard>
  );
}
