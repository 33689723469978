import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import "./fonts/PowerGrotesk";
import "./fonts/Tiemposfine";
import "./fonts";
import "./index.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { ThemeProvider } from "@mui/material/styles";
import defaultTheme from "./Themes/Default";
import { StylesProvider } from "@mui/styles";
import LoadingPage from "./Pages/LoadingPage";
import Themes from "./Themes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingPage />}>
      <StylesProvider injectFirst>
        <Themes>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Themes>
      </StylesProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
