import { createTheme } from "@mui/material/styles";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import { darkTheme, holoDark, lightTheme } from "../constants/Colours";

const breakpoints = createBreakpoints({});
const defaultTheme = (mode) => {
  let colorByMode = lightTheme;
  if (mode === "dark") {
    colorByMode = darkTheme;
  }
  const {
    ButtonGray,
    ButtonPrimaryColor,
    ButtonSecondaryColor,
    ButtonClaimedColor,
    ButtonInherit,
    grey4,
    grey8,
    grey9,
    holoDisabled,
    holoGreen,
    holoInfo,
    holoPink,
    holoYellow,
    orange,
    white,
    cardBackground,
    secondBackground,
    mainBackground,
    borderColor,
    holoBlack,
    cardShadow,
    inputPlaceholder,
  } = colorByMode;
  return createTheme({
    palette: {
      mode,
      primary: {
        main: holoPink,
        borderColor: borderColor,
      },
      secondary: {
        main: holoYellow,
      },
      success: {
        main: holoGreen,
      },
      error: {
        main: orange,
      },
      warning: {
        main: holoYellow,
      },
      info: {
        main: holoInfo,
      },
      text: {
        main: holoDark,
      },
      background: mainBackground,
      secondBackground: secondBackground,
      colors: colorByMode,
    },
    typography: {
      fontFamily: `UniversLTStd, "Roboto", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h5: {
        fontSize: 25,
        fontWeight: 500,
        [breakpoints.down("md")]: {
          fontSize: 22,
        },
        [breakpoints.down("sm")]: {
          fontSize: 28,
        },
      },
      h4: {
        fontSize: 36,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: 22,
        fontWeight: 700,
      },
      body2: {
        fontSize: 18,
        fontWeight: 400,
        [breakpoints.down("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down("md")]: {
          fontSize: 14,
        },
      },
      body1: {
        fontSize: 14,
        [breakpoints.down("lg")]: {
          fontSize: 11,
        },
        [breakpoints.down("md")]: {
          fontSize: 14,
        },
      },
    },
    spacing: (factor) => `${8.75 * factor}px`,
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: cardShadow,
            backgroundColor: cardBackground,
            backgroundImage: "none",
            backdropFilter: "blur(9.5px)",
            borderRadius: 10,
            width: "100%",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "20px 25px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: white,
          },
          outlined: {
            color: holoBlack,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: 12,
            minWidth: 110,
            padding: "8px 17px",
            lineHeight: 1,
            fontSize: 15,
            fontFamily: "PowerGrotesk-Regular",
          },
          contained: {
            [breakpoints.down("xl")]: {
              // height: 48,
            },
            [breakpoints.down("md")]: {
              // height: 40,
            },
            ":disabled": {
              color: white,
              backgroundColor: holoDisabled,
            },
            ":hover": {
              backgroundColor: grey4,
              color: white,
              boxShadow: "none",
            },
          },
          outlined: {
            [breakpoints.down("xl")]: {
              // height: 48,
            },
            [breakpoints.down("md")]: {
              // height: 40,
            },
            padding: "7px 16px",
          },
          sizeMedium: {
            padding: "8px 17px",
            lineHeight: 1,
            fontSize: 15,
          },
          sizeLarge: {
            padding: "15px 17px",
            lineHeight: 1,
            fontSize: 18,
            [breakpoints.down("md")]: {
              padding: "11px 17px 11px",
            },
          },

          containedInherit: {
            backgroundColor: ButtonInherit,
            color: grey4,
            borderRadius: 25,
            ":hover": {
              backgroundColor: holoDark,
            },
          },
          containedPrimary: {
            ":hover": {
              backgroundColor: grey4,
              color: ButtonPrimaryColor,
            },
          },
          containedSecondary: {
            color: ButtonSecondaryColor,
            borderRadius: 25,
            ":hover": {
              backgroundColor: grey4,
              color: ButtonSecondaryColor,
            },
          },
          claim: {
            height: 30,
            width: "100%",
            borderRadius: 25,
            ":disabled": {
              color: white,
              background: grey9,
            },
          },
          claimed: {
            height: 30,
            width: "100%",
            borderRadius: 25,
            ":disabled": {
              color: white,
              background: ButtonClaimedColor,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          message: {
            alignItems: "center",
            display: "flex",
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::placeholder": {
              color: inputPlaceholder,
              opacity: 1,
            },
          },
          input: {
            "&::placeholder": {
              color: inputPlaceholder,
              opacity: 1,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "&::placeholder": {
              color: inputPlaceholder,
              opacity: 1,
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            backgroundColor: mainBackground,
          },
        },
      },
    },
  });
};
export default defaultTheme;
