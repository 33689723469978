import { useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useWalletContext } from "../../context/WalletContext";
import SpaceComponent from "../../Components/SpaceComponent";
import HoloWrapConfig from "../../blockChainContexts/Testnet/HoloWrap/config";
import { useContractContext } from "../../context/ContractContext";
import { AlertStatus } from "../../constants/Status";
import { PriceInput } from "./WrapPanelPriceInput";
import AlertComponent from "../../Components/Alert";

const useStyles = makeStyles((theme) => ({
  description: {
    display: "block",
    fontSize: 16,
    color: theme.palette.colors.grey11,
    fontFamily: ["Light"].join(","),
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "21px",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      textAlign: "center",
    },
  },
  button: {
    height: 48,
    width: 240,
  },
  balance: {
    color: theme.palette.colors.grey4,
  },
}));

export default function WrapApprovePanel(props) {
  const classes = useStyles();
  const { wrapped } = props;
  const { t } = useTranslation();
  const [depositBalance, setDepositBalance] = useState();
  const [totalBalance, setTotalBalance] = useState();
  const [holoYieldAmount, setHoloYieldAmount] = useState();
  const [approvedHoloClear, setApprovedHoloClear] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    status: undefined,
    loading: false,
  });

  const { HoloWrapContract, HoloYieldContract } = useContractContext();
  const { account, isWalletConnected, setWalletConnected } = useWalletContext();

  useEffect(() => {
    try {
      if (isWalletConnected) {
        checkHoloClearApproved();
        loadHoloBalances();
        setTotalBalance();
        setDepositBalance();
      }
    } catch (error) {
      console.log(error);
    }
  }, [isWalletConnected]);

  const checkHoloClearApproved = async () => {
    try {
      const isAccountInteracted = await HoloYieldContract.has_interacted(
        account,
        HoloWrapConfig.address
      );
      setApprovedHoloClear(isAccountInteracted);
    } catch (e) {
      console.log(e);
    }
  };

  const loadHoloBalances = async () => {
    const depositB = await HoloYieldContract.balanceOf(account);
    const totalB = await HoloWrapContract.balanceOf(account);
    setTotalBalance(
      Number.parseFloat(ethers.utils.formatEther(totalB)).toFixed(4)
    );
    setDepositBalance(
      Number.parseFloat(ethers.utils.formatEther(depositB)).toFixed(4)
    );
  };

  const handleApproveClick = async () => {
    const isApproved = await HoloYieldContract.approve_max(
      HoloWrapConfig.address
    );
    setApprovedHoloClear(isApproved);
  };

  const handleMaxClick = async () => {
    let balance = 0;
    if (wrapped) {
      balance = await HoloYieldContract.balanceOf(account);
    } else {
      balance = await HoloWrapContract.balanceOf(account);
    }
    setHoloYieldAmount(ethers.utils.formatEther(balance));
  };

  const inputChangeHandler = (event) => {
    setHoloYieldAmount(event.target.value < 0 ? 0 : event.target.value);
  };

  const wrapUnwrap = () =>
    new Promise(async (resolve, reject) => {
      try {
        let transaction;
        if (wrapped) {
          transaction = await HoloWrapContract.wrap(
            ethers.utils.parseUnits(holoYieldAmount.toString(), "ether")
          );
        } else {
          transaction = await HoloWrapContract.unwrap(
            ethers.utils.parseUnits(holoYieldAmount.toString(), "ether")
          );
        }
        await transaction.wait();
        return resolve(true);
      } catch (error) {
        return reject(error);
      }
    });

  const handleWrapClick = async () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      message: "",
      status: "",
      loading: true,
    }));
    if (wrapped) {
      let holoYieldBalance = await HoloYieldContract.balanceOf(account);
      holoYieldBalance = ethers.utils.formatEther(holoYieldBalance);

      if (Math.floor(holoYieldAmount) <= Math.floor(holoYieldBalance) && Math.floor(holoYieldAmount) > 0) {
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("info.transaction"),
          status: AlertStatus.Info,
        }));
        try {
          wrapUnwrap()
            .then(() => {
              loadHoloBalances();
              setSubmitStatus((prevState) => ({
                ...prevState,
                message: t("success.transaction"),
                status: AlertStatus.Success,
                loading: false,
              }));
            })
            .catch((err) => {
              console.log(err);
              setSubmitStatus((prevState) => ({
                ...prevState,
                message: t("errors.transaction"),
                status: AlertStatus.Error,
                loading: false,
              }));
            })
            .finally(() => {
              setHoloYieldAmount(0);
              setSubmitStatus((prevState) => ({
                ...prevState,
                loading: false,
              }));
            });
        } catch (e) {
          console.log(e);
          setSubmitStatus((prevState) => ({
            ...prevState,
            message: t("errors.transaction"),
            status: AlertStatus.Error,
            loading: false,
          }));
        }
      } else {
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("errors.insufficientAmount", {
            amount: holoYieldAmount ? holoYieldAmount : 0,
          }),
          status: AlertStatus.Warning,
          loading: false,
        }));
      }
    } else {
      let holoWrapBalance = await HoloWrapContract.balanceOf(account);
      holoWrapBalance = ethers.utils.formatEther(holoWrapBalance);

      if (Math.floor(holoYieldAmount) <= Math.floor(holoWrapBalance) && Math.floor(holoYieldAmount) > 0) {
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("info.transaction"),
          status: AlertStatus.Info,
        }));
        try {
          wrapUnwrap()
            .then(() => {
              loadHoloBalances();
              setSubmitStatus((prevState) => ({
                ...prevState,
                message: t("success.transaction"),
                status: AlertStatus.Success,
                loading: false,
              }));
            })
            .catch((err) => {
              console.log(err);
              setSubmitStatus((prevState) => ({
                ...prevState,
                message: t("errors.transaction"),
                status: AlertStatus.Error,
                loading: false,
              }));
            })
            .finally(() => {
              setHoloYieldAmount(0);
              setSubmitStatus((prevState) => ({
                ...prevState,
                loading: false,
              }));
            });
        } catch (e) {
          console.log(e);
          setSubmitStatus((prevState) => ({
            ...prevState,
            message: t("errors.transaction"),
            status: AlertStatus.Error,
            loading: false,
          }));
        }
      } else {
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("errors.insufficientAmount", {
            amount: holoYieldAmount ? holoYieldAmount : 0,
          }),
          status: AlertStatus.Warning,
          loading: false,
        }));
      }
    }
  };

  const closeAlert = () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      message: "",
      status: undefined,
      loading: false,
    }));
  };

  return (
    <Grid container item xs={12} alignItems="center" spacing={4}>
      <Grid
        container
        item
        xs={12}
        justifyContent={{ md: "flex-end" }}
        alignItems={{ md: "center" }}
        display={isWalletConnected && approvedHoloClear ? "none" : undefined}
        sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
        rowSpacing={3}
      >
        <Grid item xs={12} md={9}>
          {!isWalletConnected && (
            <Typography className={classes.description} variant="string">
              {t("wrapPage.description")}
            </Typography>
          )}
          {isWalletConnected && wrapped && !approvedHoloClear && (
            <Typography className={classes.description} variant="string">
              {t("wrapPage.wrapApproveMessage")}
            </Typography>
          )}
          {isWalletConnected && !wrapped && !approvedHoloClear && (
            <Typography className={classes.description} variant="string">
              {t("wrapPage.unwrapApproveMessage")}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={3} justifyContent={{ sm: "flex-end" }}>
          {!isWalletConnected && (
            <Button
              variant="contained"
              onClick={() => setWalletConnected(true)}
              color="secondary"
              size="large"
              fullWidth
            >
              {t("buttons.connect")}
            </Button>
          )}
          {isWalletConnected && !approvedHoloClear && (
            <Button
              variant="contained"
              onClick={() => handleApproveClick()}
              color="secondary"
              size="large"
              fullWidth
            >
              {t("buttons.approve")}
            </Button>
          )}
        </Grid>
      </Grid>
      {submitStatus.status && (
        <AlertComponent
          severity={submitStatus.status}
          message={submitStatus.message}
          onclickHandler={closeAlert}
        />
      )}
      {isWalletConnected && approvedHoloClear && (
        <Grid item xs={12}>
          <PriceInput
            handleWrapClick={handleWrapClick}
            inputChangeHandler={inputChangeHandler}
            handleMaxClick={handleMaxClick}
            wrapped={wrapped}
            amount={holoYieldAmount}
            loading={submitStatus.loading}
          />
        </Grid>
      )}
      {isWalletConnected && (
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12}>
            <SpaceComponent>
              <Typography className={classes.balance}>
                {t("wrapPage.stakedBalance")}
              </Typography>
              <Typography className={classes.balance}>
                {depositBalance} {t("Holoyield")}
              </Typography>
            </SpaceComponent>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <SpaceComponent>
              <Typography className={classes.balance}>
                {t("wrapPage.totalWrappedBalance")}
              </Typography>
              <Typography className={classes.balance}>
                {totalBalance} {t("Holowrap")}
              </Typography>
            </SpaceComponent>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
