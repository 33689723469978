import React from "react";
import BNBBalanceCard from "../../../Components/BNBBalanceCard";
import BUSDBalanceCard from "../../../Components/BUSDBalanceCard";
import { useMintContext } from "../Context/MintContext";

const BalanceSelector = () => {
  const { selectedMarket } = useMintContext();
  if (selectedMarket === "HOLOBUSD") {
    return <BUSDBalanceCard />;
  }
  return <BNBBalanceCard />;
};

export default BalanceSelector;
