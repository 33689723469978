import constate from "constate";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "@mui/material";
import defaultTheme from "../Themes/Default";

const [ThemeContextProvider, useThemeContext] = constate(() => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const getDefaultTheme = () => {
    const localstorage_theme = localStorage.getItem("holoclear-theme-is-dark");
    if (localstorage_theme !== undefined) {
      return localstorage_theme === "true";
    }
    return prefersDarkMode;
  };
  const [themeMode, setThemeMode] = useState(
    getDefaultTheme() ? "dark" : "light"
  );

  const theme = useMemo(
    () => defaultTheme(themeMode === "dark" ? "dark" : "light"),
    [themeMode]
  );
  const switchDarkTheme = () => {
    setThemeMode("dark");
    localStorage.setItem("holoclear-theme-is-dark", "true");
  };
  const switchLightTheme = () => {
    setThemeMode("light");
    localStorage.setItem("holoclear-theme-is-dark", "false");
  };
  return {
    theme,
    switchDarkTheme,
    switchLightTheme,
    isLight: themeMode === "light",
  };
});

useThemeContext.Provider = ThemeContextProvider;
export { useThemeContext };
