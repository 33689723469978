import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { white } from "../constants/Colours";
import MenuList from "./MenuList";
import { makeStyles, useTheme } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: 6,
  },
}));
const drawerWidth = 96;

export default function MenuDrawer(props) {
  const { setOpen, open } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Drawer
      classes={{
        paper: classes.paper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.background,
          borderRight: "1px solid " + theme.palette.primary.borderColor,
        },
        ".css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
          backgroundColor: "rgba(243, 243, 243, 0.6)",
          backdropFilter: "blur(10px)",
        },
      }}
      open={open}
      onClose={() => setOpen(!open)}
    >
      <MenuList isMobile afterClick={() => setOpen(false)} />
    </Drawer>
  );
}
