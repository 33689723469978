import moment from "moment";

//this function accepts timestamp
export const getDiffFromNow = (timestamp) => {
  let currentTime = Math.floor(Date.now() / 1000).toString();
  let leftTime = timestamp - currentTime;
  let duration = moment.duration(leftTime, "seconds");
  duration = moment.duration(duration.asSeconds(), "seconds");
  return getReturnValues(duration);
};

const getReturnValues = (date) => {
  // calculate time left
  const days = getDefaultIsNaN(date.days());
  const hours = getDefaultIsNaN(date.hours());
  const minutes = getDefaultIsNaN(date.minutes());
  const seconds = getDefaultIsNaN(date.seconds());

  return { days, hours, minutes, seconds };
};
const getDefaultIsNaN = (number) => {
  if (isNaN(number)) {
    return 0;
  } else {
    return number;
  }
};

//this function accepts seconds
export const toDaysMinutesSeconds = (totalSeconds, includeSeconds = false) => {
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const days = Math.floor(totalSeconds / (3600 * 24));

  const secondsStr = makeHumanReadable(seconds, "sec");
  const minutesStr = makeHumanReadable(minutes, "min");
  const hoursStr = makeHumanReadable(hours, "hour");
  const daysStr = makeHumanReadable(days, "day");

  if (days > 0) {
    return `${daysStr}${hoursStr}`.replace(/,\s*$/, "");
  }
  return includeSeconds
    ? `${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, "")
    : `${hoursStr}${minutesStr}`.replace(/,\s*$/, "");
};

function makeHumanReadable(num, singular) {
  return num > 0
    ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
    : "";
}

export function secondsToPeriod(seconds) {
  const day = secondsToDay(seconds);
  const hour = secondsToHour(seconds);
  if (day >= 1) return { time: day, type: "day" };
  return { time: hour, type: "hour" };
}
function secondsToHour(seconds = 0) {
  return seconds / 60 / 60;
}

function secondsToDay(seconds = 0) {
  return seconds / 60 / 60 / 24;
}

export const formatRemainTime = (
  times = { months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 },
  options = {}
) => {
  const { maxPeriod } = options;
  const { days, hours, minutes, seconds } = times;
  const formattedTime = [];
  const periodCheck = (func) =>
    addIf(!maxPeriod || maxPeriod > formattedTime.length, func);
  if (days > 0) {
    periodCheck(() => formattedTime.push(`${days} days`));
  }
  if (hours > 0) {
    periodCheck(() => formattedTime.push(`${hours} hrs`));
  }
  if (minutes > 0) {
    periodCheck(() => formattedTime.push(`${minutes} mins`));
  }
  if (seconds > 0) {
    periodCheck(() => formattedTime.push(`${seconds} s`));
  }
  if (formattedTime.length === 0) return "0 s";
  return formattedTime.join(", ");
};
const addIf = (condition, op) => condition && op();
