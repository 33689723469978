import { useEffect, useState } from "react";
import constate from "constate";
import { useContractContext } from "../../../context/ContractContext";
import { useWalletContext } from "../../../context/WalletContext";
import VaultConfig from "../../../blockChainContexts/Testnet/Vault/config";

const [StakeContextProvider, useStakeContext] = constate(() => {
  const { HoloClearContract, HoloYieldContract } = useContractContext();
  const { account, isWalletConnected } = useWalletContext();
  const [isStake, setStake] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateCards, setUpdateCards] = useState(0);
  const needUpdate = () => setUpdateCards((prevUpdate) => prevUpdate + 1);
  useEffect(() => {
    setLoading(true);
    if (isStake) {
      isDepositApproved();
    } else {
      isWithdrawApproved();
    }
  }, [isStake, isApproved, isWalletConnected]);

  const isDepositApproved = async () => {
    try {
      const approved = await HoloClearContract.has_interacted(
        account,
        VaultConfig.address
      );
      setIsApproved(approved);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const isWithdrawApproved = async () => {
    try {
      const approved = await HoloYieldContract.has_interacted(
        account,
        VaultConfig.address
      );
      setIsApproved(approved);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const _setState = (state) => {
    if (isStake === state) return;
    setLoading(true);
    setStake(state);
  };
  return {
    isStake,
    setStake: _setState,
    loading,
    isApproved,
    setIsApproved,
    setLoading,
    needUpdate,
    updateCards,
  };
});

useStakeContext.Provider = StakeContextProvider;
export { useStakeContext };
