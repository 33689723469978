import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageLayout from "../../Components/Layout/PageLayout";
import PageTitle from "../../Components/Typography/PageTitle";
import HelpBubble from "../Dashboard/HelpBubble";
import ResourcesCard from "../Dashboard/Resources";
import NextDraw from "./Cards/NextDraw";
import PoolValue from "./Cards/PoolValue";
import { PrizeProgram } from "./Cards/PrizeProgram";
import Winners from "./Cards/Winners";
import { usePoolContext } from "./Context/PoolContext";
import LoadingPage from "../LoadingPage";
import AlertComponent from "../../Components/Alert";

const PoolPage = () => {
  return (
    <usePoolContext.Provider>
      <PoolPageContent />
    </usePoolContext.Provider>
  )
}

const PoolPageContent = () => {
  const { t } = useTranslation();
  const { loading, submitStatus, setSubmitStatus } = usePoolContext();

  const closeAlert = () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      message: "",
      status: undefined,
      loading: false,
    }));
  };

  if(loading) return<PageLayout><LoadingPage /></PageLayout> 

  return (
    <PageLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PageTitle> {t("pages.Pool")} </PageTitle>
        </Grid>
        <Grid item xs={12}>
          <PrizeProgram />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PoolValue />
        </Grid>
        <Grid item xs={12} lg={6}>
          <NextDraw />
        </Grid>
        {submitStatus.status && <AlertComponent severity={submitStatus.status} message={submitStatus.message} onclickHandler={closeAlert} />}
        <Grid item xs={12}>
          <Winners />
        </Grid>
        <Grid item xs={12}>
          <ResourcesCard />
        </Grid>
        <Grid item xs={12}>
          <HelpBubble />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default PoolPage;
