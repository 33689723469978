import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CurrentIndexCard from "../../Components/HoloPrice/CurrentIndex";
import HoloWrapPriceCard from "../../Components/HoloPrice/HoloWrapPrice";
import WrapPageHoloPriceCard from "../../Components/HoloPrice/WrapPageHoloPrice";
import PageLayout from "../../Components/Layout/PageLayout";
import PageTitle from "../../Components/Typography/PageTitle";
import HelpBubble from "../Dashboard/HelpBubble";
import ResourcesCard from "../Dashboard/Resources";
import { useWrapContext } from "./Context/WrapContext";
import WrapPanel from "./WrapPanel";
import { useContractContext } from "../../context/ContractContext";
import { ethers } from "ethers";
import HoloClearConfig from "../../blockChainContexts/Testnet/HoloClear/config";
import LoadingPage from "../LoadingPage";

const WrapPageContent = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true); //general page loading
  const { setHoloPrice, setHoloWrapPrice, setCurrentIndex } = useWrapContext();
  const {
    HoloYieldContract,
    MarketOracleContract,
    RouterContract
  } = useContractContext();

  const setHoloPriceFunc = async () => {
    try {
      const holoBnbArray = await MarketOracleContract.get_price_data(ethers.utils.formatBytes32String("HOLOBNB"));
      const bnbBusdArray = await MarketOracleContract.get_price_data(ethers.utils.formatBytes32String('BNBBUSD'));
      const weth = await RouterContract.WETH();
  
      let holoBnb, bnbBusd;
      if (holoBnbArray[1] === HoloClearConfig.address) {
        holoBnb = holoBnbArray[6];
      } else {
        holoBnb = holoBnbArray[5];
      }
  
      if (bnbBusdArray[1] === weth) {
        bnbBusd = bnbBusdArray[5];
      } else {
        bnbBusd = bnbBusdArray[6];
      }
  
      const hPrice = bnbBusd / holoBnb;
      setHoloPrice(Number.parseFloat(hPrice).toFixed(4));
  
      const index = await getHoloYieldIndex();
      const hwPrice = index * hPrice;
      setHoloWrapPrice(Number.parseFloat(hwPrice).toFixed(4));
      setCurrentIndex(Number.parseFloat(index).toFixed(4));
      setLoading(false);
    } catch(e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getHoloYieldIndex = (async () => {
    const index = await HoloYieldContract.index();
    return ethers.utils.formatEther(index);
  });

  useEffect(() => {
    if (MarketOracleContract) {
      setHoloPriceFunc();
    }
  }, [MarketOracleContract]);

  if(loading) return<PageLayout><LoadingPage /></PageLayout> 

  return (
    <PageLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PageTitle> {t("pages.Wrap")} </PageTitle>
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <Grid item xs={12} sm={4}>
            <WrapPageHoloPriceCard percentageChangeEnabled />
          </Grid>
          <Grid item xs={6} sm={4}>
            <HoloWrapPriceCard />
          </Grid>
          <Grid item xs={6} sm={4}>
            <CurrentIndexCard />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WrapPanel />
        </Grid>
        <Grid item xs={12}>
          <ResourcesCard />
        </Grid>
        <Grid item xs={12}>
          <HelpBubble />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

const WrapPage = () => {
  return (
    <useWrapContext.Provider>
      <WrapPageContent />
    </useWrapContext.Provider>
  )
}

export default WrapPage;
