import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { holoGreen, white } from "../../constants/Colours";

const useStyles = makeStyles({
  value: {
    fontSize: (props) => props.fontSize || 14,
    letterSpacing: "0.75px",
    color: white,
    lineHeight: "24px",
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
    paddingTop: 3,
  },
  chip: {
    backgroundColor: holoGreen,
    borderRadius: 25,
    minWidth: (props) => props.componentWidth || 60,
    width: (props) => props.width,
    margin: (props) => props.margin,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    padding: 4,
  },
});

const ChipField = (props) => {
  const { label, componentWidth, width, margin, fontSize } = props;
  const classes = useStyles({ componentWidth, width, margin, fontSize });
  return (
    <div className={classes.chip}>
      <Typography variant="string" className={classes.value}>
        {" "}
        {label}{" "}
      </Typography>
    </div>
  );
};

export default ChipField;
