import moment from "moment";
import { useRef, useEffect, useState } from "react";
const refreshPage = () => {
  window.location.reload(true); //#skip the cache and reload the page from the server
};
const useCountdown = (targetDate, onFinish = refreshPage) => {
  const interval = useRef();

  const calculateTimeLeft = () => {
    let eventTime = targetDate;
    let currentTime = Math.floor(Date.now() / 1000).toString();
    let leftTime = eventTime - currentTime;
    let duration = moment.duration(leftTime <= 0 ? 0 : leftTime, "seconds");

    if (duration.asSeconds() <= 0 || leftTime <= 0) {
      clearInterval(interval.current);
      onFinish();
      return duration;
    }
    duration = moment.duration(duration.asSeconds() - 1, "seconds");
    return duration;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (targetDate === undefined) return;

    interval.current = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(interval.current);
  }, [targetDate]);

  return getReturnValues(timeLeft);
};
const getDefaultIsNaN = (number) => {
  if (isNaN(number)) {
    return 0;
  } else {
    return number;
  }
};
const getReturnValues = (date) => {
  const days = getDefaultIsNaN(parseInt(date.asDays()));
  const hours = getDefaultIsNaN(date.hours());
  const minutes = getDefaultIsNaN(date.minutes());
  const seconds = getDefaultIsNaN(date.seconds());

  return { days, hours, minutes, seconds };
};

export { useCountdown };
