import * as React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid, useMediaQuery } from "@mui/material";
import MyGiltsHeaderValueField from "./MyGiltsHeaderValueField";
import GiltsChipField from "../fields/GiltsChipField";
import { dividerColor } from "../../../constants/Colours";
import { float2, float3 } from "../../../utils/Number";
import { useGiltContext } from "../Context/GiltContext";
import VestingTime from "./VestingTime";
import { ClaimButtonSection } from "./MyGiltsTable";
export default function MyGiltsSmallSizeTable(props) {
  const { rows, showClaimButtons } = props;
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <Grid container item xs={12} spacing={2} rowGap={5}>
      {rows.map((row, index) => (
        <useGiltContext.Provider key={index} gilt={row}>
          <Grid
            container
            item
            xs={12}
            spacing={4}
            style={{ paddingTop: 8, marginBottom: 16 }}
          >
            <Grid container item xs={12}>
              <Grid container item xs={3.5}>
                <MyGiltsHeaderValueField
                  fontFamily="Light"
                  headerText={t("mintingPage.minted")}
                  value={float3(row.mint)}
                />
              </Grid>
              <Grid container item xs={3.5}>
                <GiltsChipField
                  valueFontSize={matches ? 12 : 14}
                  headerText={t("mintingPage.discount")}
                  value={`${float2(row.discount)}%`}
                />
              </Grid>
              <Grid container item xs={5}>
                <GiltsChipField
                  valueFontSize={matches ? 12 : 14}
                  componentWidth={70}
                  headerText={t("mintingPage.interesGained")}
                  value={float3(row.interest_gained)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              style={{ marginBottom: showClaimButtons ? 16 : 44 }}
            >
              <Grid container item xs={6}>
                <MyGiltsHeaderValueField
                  fontFamily="Light"
                  centerAlign={matches}
                  headerText={t("mintingPage.vestingRemaning")}
                  value={<VestingTime />}
                />
              </Grid>
              <Grid container item xs={6}>
                <MyGiltsHeaderValueField
                  fontFamily="Light"
                  centerAlign={matches}
                  headerText={t("mintingPage.currentValue")}
                  value={t("mintingPage.holoMobile", {
                    value: float3(row.current_value),
                  })}
                  fontWeight="700"
                />
              </Grid>
            </Grid>
            {showClaimButtons && (
              <Grid item xs={12}>
                <ClaimButtonSection giltIndex={row._id} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider style={{ background: dividerColor }} />
            </Grid>
          </Grid>
        </useGiltContext.Provider>
      ))}
    </Grid>
  );
}
