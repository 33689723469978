import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Icon from "../../Components/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 44,
    paddingTop: 34,
    fontFamily: "UniversLTStd",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingTop: 25,
    },
  },
  textContainer: {
    paddingLeft: 17,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 13,
    },
  },
  title: {
    fontFamily: "UniversLTStd",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.colors.grey2,
    [theme.breakpoints.down("md")]: {
      fontSize: "12px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px!important",
    },
  },
  subText: {
    fontFamily: "PowerGrotesk-Medium!important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 24,
    color: theme.palette.colors.holoBlack,
    letterSpacing: "0.02em",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px!important",
    },
  },
  icon: {
    padding: 12,
    background: theme.palette.background,
    borderRadius: 10,
    height: "min-content",
  },
}));

const SummaryPanelItem = (props) => {
  const classes = useStyles();
  const { iconName, title, subText } = props;
  return (
    <Grid container item xs={12} className={classes.root} data-testid="grid">
      <div className={classes.icon}>
        <Icon icon={iconName} size={30} color="#FF5925" />
      </div>
      <Grid item xs={8} className={classes.textContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subText}>{subText}</Typography>
      </Grid>
    </Grid>
  );
};

export default SummaryPanelItem;
