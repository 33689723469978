import React from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import AlertComponent from "../../../Components/Alert";

const useStyles = makeStyles((theme) => ({
    balanceText: {
        display: "block",
        color: theme.palette.colors.grey4,
        fontSize: 16,
        fontWeight: 300,
        fontFamily: "Light",
        letterSpacing: "0.016em",
        [theme.breakpoints.down("md")]: {
            fontSize: 12,
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            fontSize: 16,
        },
    },
    balanceTextBold: {
        fontFamily: "PowerGrotesk-Medium",
        fontWeight: 700,
    },
}));

const TransferBalance = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { pHoloBalance, handleTranswerClick, submitStatus, closeHandler } = props;
    const { loading, message, status } = submitStatus;
    return (
        <Grid
            container
            direction={{ xs: "column-reverse", sm: "row" }}
            alignItems={{ sm: "center" }}
            rowSpacing={2}
        >
            <Grid item xs={12} sm={8} md={9}>
                <Typography variant="string" className={classes.balanceText}>
                    <Trans
                        values={{
                            token: "$pHOLO",
                            balance: pHoloBalance,
                        }}
                        i18nKey="presalePage.yourBalance"
                        components={[
                            <Typography
                                variant="string"
                                display="inline"
                                fontWeight={700}
                                className={classes.balanceTextBold}
                            />,
                        ]}
                    />
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <Button onClick={handleTranswerClick} variant="contained" fullWidth size="large" color="secondary">
                    {!loading && t("buttons.transfer")}
                    {loading && <CircularProgress size={18} />}
                </Button>
            </Grid>
            {status && <AlertComponent severity={status} message={message} onclickHandler={closeHandler} />}
        </Grid>
    );
};

export default TransferBalance;