import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { grey5, holoBlack, holoLightOrange } from "../../constants/Colours";
import { Grid, Stack } from "@mui/material";
import TaxRates from "../../Charts/TaxRates";
import { formatDate, useApiResult } from "../../context/ApiResultContext";
import { useContractContext } from "../../context/ContractContext";
import { float2 } from "../../utils/Number";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px!important",
    lineHeight: 26,
    color: theme.palette.colors.holoBlack,
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px!important",
    },
  },
  sell: {
    color: theme.palette.colors.holoLightOrange,
    fontFamily: "PowerGrotesk-Medium!important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px!important",
    lineHeight: 22,
    letterSpacing: 1,
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px!important",
    },
  },
  buy: {
    color: theme.palette.colors.grey5,
    fontFamily: "PowerGrotesk-Medium!important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px!important",
    lineHeight: 22,
    letterSpacing: 1,
    [theme.breakpoints.down("lg")]: {
      fontSize: "14px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px!important",
    },
  },
}));

const TaxRatesCard = () => {
  const [defaultRequestParameters, setDefaultRequestParameters] = useState({
    start: formatDate(moment().subtract(1, "days"), true),
    end: formatDate(moment()),
    freq: 300,
  });
  return (
    <useApiResult.Provider defaultRequestParameters={defaultRequestParameters}>
      <TaxRatesCardBody />
    </useApiResult.Provider>
  );
};
function TaxRatesCardBody() {
  const classes = useStyles();
  const { t } = useTranslation();
  const apiResult = useApiResult();
  const { HoloClearContract } = useContractContext();

  //these two last tax rates for chart highlight
  const [buyFee, setBuyFee] = useState("-");
  const [sellFee, setSellFee] = useState("-");

  const loadFees = async () => {
    try {
      const sFee = (await HoloClearContract.sell_fee()) / 1e18;
      const bFee = (await HoloClearContract.buy_fee()) / 1e18;

      setBuyFee(bFee * 100);
      setSellFee(sFee * 100);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadFees();
  }, []);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography component="div" className={classes.title}>
          {t("currentTaxRates")}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row">
              <Typography component="div" className={classes.sell}>
                {float2(sellFee)} % {t("sell")}&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography component="div" className={classes.buy}>
                {float2(buyFee)} % {t("buy")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <div>
        <Grid display={{ xs: "none", md: "block" }}>
          <TaxRates
            buy_fee={apiResult.data.graphs.buy_fee.data}
            sell_fee={apiResult.data.graphs.sell_fee.data}
            labels={apiResult.data.graphs.sell_fee.labels}
          />
        </Grid>
        <Grid display={{ xs: "block", md: "none" }}>
          <TaxRates
            isHalf
            buy_fee={apiResult.data.graphs.buy_fee.data}
            sell_fee={apiResult.data.graphs.sell_fee.data}
            labels={apiResult.data.graphs.sell_fee.labels}
          />
        </Grid>
      </div>
    </Card>
  );
}

export default TaxRatesCard;
