import Icon from ".";

const FlatIconSelector = ({ value = 0, style = {}, ...props }) => {
  return (
    <Icon
      icon="up-arrow-flat"
      size={22}
      style={{
        transform: value < 0 ? "rotate(180deg)" : undefined,
        ...style,
      }}
      color={value < 0 ? "#fd0000" : undefined}
      {...props}
    />
  );
};

export default FlatIconSelector;
