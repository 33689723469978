import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ValueCard from "../ValueCard";
import Icon from "../Icon";
import { useWrapContext } from "../../Pages/Wrap/Context/WrapContext";

const useStyles = makeStyles((theme) => ({
  value: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: "34px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));
const CurrentIndexCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentIndex } = useWrapContext();
  return (
    <ValueCard size="lg">
      <Grid container item xs={12} direction="column">
        <ValueCard.Title variantParam="string" className={classes.title}>
          {t("currentIndex")}{" "}
        </ValueCard.Title>
        <ValueCard.Value
          variantParam="string"
          className={classes.value}
          value={currentIndex}
        />
        <Icon icon="current-index" size={24} />
      </Grid>
    </ValueCard>
  );
};

export default CurrentIndexCard;
