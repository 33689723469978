import React, { useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { grey4, holoBlack } from "../../constants/Colours";
import { makeStyles } from "@mui/styles";
import { useWalletContext } from "../../context/WalletContext";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    color: theme.palette.colors.holoBlack,
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
  },
  content: {
    paddingTop: "33px!important",
    paddingBottom: "45px!important",
  },
  description: {
    fontSize: 16,
    color: theme.palette.colors.connectWalletDescription,
    fontFamily: ["Light"].join(","),
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "21px",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      textAlign: "center",
    },
  },
  button: {
    height: 48,
    width: 240,
  },
}));

export default function ConnectWalletPanel() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setWalletConnected } = useWalletContext();

  return (
    <Card>
      <CardContent className={classes.content}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="string">
              {t("connect")}
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} display={{ xs: "flex", md: "none" }}>
              <Button
                variant="contained"
                onClick={() => setWalletConnected(true)}
                color="secondary"
                size="large"
                fullWidth
              >
                {t("buttons.connect")}
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={9}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-start" }}
            >
              <Typography className={classes.description} variant="string">
                {t("accountPage.description")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} display={{ xs: "none", md: "flex" }}>
              <Button
                variant="contained"
                onClick={() => setWalletConnected(true)}
                color="secondary"
                size="large"
                fullWidth
              >
                {t("buttons.connect")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
