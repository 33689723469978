import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import VaultConfig from "../../../blockChainContexts/Testnet/Vault/config";
import { useContractContext } from "../../../context/ContractContext";
import { useStakeContext } from "../Context/Stake";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Light!important",
    fontSize: "16px!important",
    color: theme.palette.colors.grey11,
  },
}));
const FirstTime = ({}) => {
  const { HoloClearContract, HoloYieldContract } = useContractContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const { isStake, setIsApproved, setLoading } = useStakeContext();
  const onApprove = () => {
    if (isStake) {
      depositApprove();
    } else {
      withdrawApprove();
    }
  };
  const depositApprove = async () => {
    try {
      const approve = await HoloClearContract.approve_max(VaultConfig.address);
      setLoading(true);
      await approve.wait();
      setIsApproved(approve);
    } catch (error) {
      console.log(error);
    }
  };
  const withdrawApprove = async () => {
    try {
      const approve = await HoloYieldContract.approve_max(VaultConfig.address);
      setLoading(true);
      await approve.wait();
      setIsApproved(approve);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid
      container
      direction={{ xs: "column-reverse", sm: "row" }}
      rowSpacing={2}
    >
      <Grid item xs={12} sm={9}>
        <Typography
          className={classes.text}
          textAlign={{ xs: "center", sm: "left" }}
          justifyContent={{ xs: "center", sm: "start" }}
        >
          <Trans
            i18nKey={
              isStake
                ? "stakePage.FirstTimeStake"
                : "stakePage.FirstTimeWithdraw"
            }
            components={[
              <Typography
                className={classes.text}
                display="inline"
                fontWeight={700}
              />,
              <br />,
            ]}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button
          fullWidth
          onClick={onApprove}
          variant="contained"
          color="secondary"
          size="large"
        >
          {t("Approve")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FirstTime;
