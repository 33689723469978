import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import ConnectionRequired from "../../../Components/ConnectionRequired";
import { useContractContext } from "../../../context/ContractContext";
import { useWalletContext } from "../../../context/WalletContext";
import { usePresaleContext } from "../Context/PresaleContext";
import GrvsnapBonus from "./GrvsnapBonus";
import { AlertStatus } from "../../../constants/Status";
import TransferBalance from "./TransferBalance";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 200,
  },
  title: {
    display: "block",
    fontSize: 24,
    letterSpacing: "0.75px",
    color: theme.palette.colors.holoBlack,
    fontStyle: "normal",
    fontWeight: 400,
    marginBottom: "52px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "29px!important",
    },
  }
}));

const TransferSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { account } = useWalletContext();
  const { pHoloBalance, showRedeemBonus, fetchPHoloBalance } = usePresaleContext();
  const { PresaleTransferContract } = useContractContext();

  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    status: undefined,
    loading: false,
  });

  const swap = () => new Promise(async (resolve, reject) => {
    try {
      let transaction = await PresaleTransferContract.swap(account);
      await transaction.wait();
      await fetchPHoloBalance(account);
      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });

  const handleTranswerClick = async () => {
    if (pHoloBalance > 0) {
      setSubmitStatus((prevState) => ({
        ...prevState,
        loading: true
      }));
      swap()
        .then(() => {
          setSubmitStatus((prevState) => ({
            ...prevState,
            message: t("success.transaction"),
            status: AlertStatus.Success,
            loading: false
          }));
        })
        .catch((err) => {
          console.log(err);
          setSubmitStatus((prevState) => ({
            ...prevState,
            message: t("errors.transaction"),
            status: AlertStatus.Error,
            loading: false
          }));
        })
        .finally(() => {
          setSubmitStatus((prevState) => ({
            ...prevState,
            loading: false,
          }));
        });
    } else {
      setSubmitStatus((prevState) => ({
        ...prevState,
        message: t("errors.insufficientAmount", { amount: pHoloBalance }),
        status: AlertStatus.Warning,
        loading: false
      }));
    }
  }

  const closeAlert = () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      message: "",
      status: undefined,
      loading: false,
    }));
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        {!showRedeemBonus && (
          <Typography className={classes.title} variant="string">
            {t("presalePage.presaleTransfer")}
          </Typography>
        )}
        {showRedeemBonus &&
          (
            <Typography className={classes.title} variant="string">
              {t("presalePage.GrvsnapBonusTitle")}
            </Typography>
          )}

        {!showRedeemBonus ?
          <ConnectionRequired
            approveTranslationKey={"presalePage.didApprove"}
            connectionTranslationKey={"presalePage.didConnect"}
          >
            <TransferBalance
              submitStatus={submitStatus}
              handleTranswerClick={handleTranswerClick}
              pHoloBalance={Number.parseFloat(pHoloBalance).toFixed(2)}
              closeHandler={closeAlert}
            />
          </ConnectionRequired>
          :
          <GrvsnapBonus />
        }
      </CardContent>
    </Card>
  );
};

export default TransferSection;