import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageTitle from "../../Components/Typography/PageTitle";
import PageLayout from "../../Components/Layout/PageLayout";
import ResourcesCard from "../Dashboard/Resources";
import HelpBubble from "../Dashboard/HelpBubble";
import StakeSection from "./StakeSection";
import { useStakeContext } from "./Context/Stake";
import ValueDeposited from "./Components/ValueDeposited";
import Apy from "./Components/Apy";
import CurrentIndex from "./Components/CurrentIndex";

const StakePage = () => {
  const { t } = useTranslation();
  return (
    <useStakeContext.Provider>
      <PageLayout>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PageTitle> {t("pages.Stake")} </PageTitle>
          </Grid>
          <Grid item xs={12} display={{ xs: "block", md: "none" }}>
            <ValueDeposited />
          </Grid>
          <Grid item xs={6} md={4}>
            <Apy />
          </Grid>
          <Grid item xs={4} display={{ xs: "none", md: "block" }}>
            <ValueDeposited />
          </Grid>
          <Grid item xs={6} md={4}>
            <CurrentIndex />
          </Grid>
          <Grid item xs={12}>
            <StakeSection />
          </Grid>
          <Grid item xs={12}>
            <ResourcesCard />
          </Grid>
          <Grid item xs={12}>
            <HelpBubble />
          </Grid>
        </Grid>
      </PageLayout>
    </useStakeContext.Provider>
  );
};

export default StakePage;
