import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { black, holoYellow } from "../../constants/Colours";
import { makeStyles, useTheme } from "@mui/styles";
import Treasury from "../../Charts/Treasury";
import { Grid } from "@mui/material";
import { RangeSelectors } from "./TreasuryCard";
import { useApiResult } from "../../context/ApiResultContext";

const useStyles = makeStyles((theme) => ({
  card: {},
  TreasuryTitle: {
    fontSize: "24px!important",
    lineHeight: 26,
    letterSpacing: 1,
    color: theme.palette.colors.black,
    [theme.breakpoints.down("md")]: {
      fontSize: "22px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px!important",
    },
  },
  TreasuryValue: {
    fontFamily: "PowerGrotesk-Medium!important",
    fontSize: "20px!important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px!important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px!important",
    },
  },
}));

export default function HoloPrice(props) {
  const classes = useStyles();
  const { holoPrice } = props; //current price
  const { t } = useTranslation();
  const apiResult = useApiResult();
  const theme = useTheme();
  const color = "secondary";
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item xs={8} sm={6} md={4}>
            <Typography component="div" className={classes.TreasuryTitle}>
              {t("holoPrice")}
            </Typography>
            <Typography
              variant="body1"
              color={color}
              className={classes.TreasuryValue}
            >
              ${holoPrice} {t("Today")}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={6} md={8}>
            <RangeSelectors color={color} />
          </Grid>
        </Grid>
      </CardContent>
      <div>
        <Grid display={{ xs: "none", md: "block" }}>
          <Treasury
            variant={holoYellow}
            gradientStart={theme.palette.colors.treasurySecondaryGradientStart}
            gradientEnd={theme.palette.colors.treasurySecondaryGradientEnd}
            label={"$HOLO Price"}
            data={apiResult?.data?.graphs?.holo_price?.data}
            labels={apiResult?.data?.graphs?.holo_price?.labels}
          />
        </Grid>
        <Grid display={{ xs: "block", md: "none" }}>
          <Treasury
            variant={holoYellow}
            gradientStart={theme.palette.colors.treasurySecondaryGradientStart}
            gradientEnd={theme.palette.colors.treasurySecondaryGradientEnd}
            label={"$HOLO Price"}
            isHalf
            data={apiResult.data.graphs.holo_price.data}
            labels={apiResult.data.graphs.holo_price.labels}
          />
        </Grid>
      </div>
    </Card>
  );
}
