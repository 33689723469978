import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { grey7, holoBlack } from "../../../constants/Colours";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.colors.holoBlack,
    letterSpacing: "0.75px",
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    lineHeight: "29px",
    fontWeight: 400,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      fontWeight: 400,
      lineHeight: "26px",
    },
  },
  explanation: {
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: "0.75px",
    color: theme.palette.colors.grey7,
    fontFamily: "Light",
    lineHeight: "21px",
    fontStyle: "normal",
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      fontWeight: 300,
      letterSpacing: "0.016em",
      lineHeight: "140%",
    },
  },
}));

const MarketWrapperHeader = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="string" className={classes.title}>
          {t("mintingPage.assetType")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="string" className={classes.explanation}>
          {t("mintingPage.assetTypeInfo")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MarketWrapperHeader;
