import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { grey4 } from "../../constants/Colours";
import MarketCapCard from "./MarketCapCard";
import ApyCard from "./ApyCard";
import StakedCard from "./StakedCard";
import TreasuryCard from "./TreasuryCard";
import ResourcesCard from "./Resources";
import HelpBubble from "./HelpBubble";
import TaxRatesCard from "./TaxRatesCard";
import { makeStyles } from "@mui/styles";
import HoloPrice from "./HoloPrice";
import PageTitle from "../../Components/Typography/PageTitle";
import { useTranslation } from "react-i18next";
import { useContractContext } from "../../context/ContractContext";
import { stringToByte32 } from "../../utils/String";
import HoloClearConfig from "../../blockChainContexts/Testnet/HoloClear/config";
import RouterConfig from "../../blockChainContexts/Testnet/Router/config";
import { float4 } from "../../utils/Number";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 18,
  },
  title: {
    fontFamily: "PowerGrotesk-Medium!important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 25,
    lineHeight: 30,
    letterSpacing: "0.01em",
    color: theme.palette.colors.grey4,
  },
}));

const MarketCapPanel = () => {
  const classes = useStyles();
  const [holoPrice, setHoloPrice] = useState("-");
  const { t } = useTranslation();
  const { MarketOracleContract } = useContractContext();


  const getHoloPrice = async () => {
    try {
      const holobnb_array_req = MarketOracleContract.get_price_data(
        stringToByte32("HOLOBNB")
      );
      const bnbbusd_array_req = MarketOracleContract.get_price_data(
        stringToByte32("BNBBUSD")
      );
      Promise.all([holobnb_array_req, bnbbusd_array_req])
        .then(([holobnb_array, bnbbusd_array]) => {
          const holobnb =
            holobnb_array[1] === HoloClearConfig.address
              ? holobnb_array[6]
              : holobnb_array[5];
          const bnbbusd =
            bnbbusd_array[1] === RouterConfig.address
              ? bnbbusd_array[6]
              : bnbbusd_array[5];
          const holobusd = float4(bnbbusd / holobnb, 4);
          setHoloPrice(holobusd.toLocaleString());
        })
        .catch((errors) => {
          console.log(errors);
          setHoloPrice("Error");
        });
    } catch (error) {
      console.log(error);
      setHoloPrice("Error");
    }
  };
  
  useEffect(() => {
    getHoloPrice();
  }, []);

  return (
    <div className={classes.container}>
      <Grid
        container
        rowSpacing={5}
        p={{ md: 2 }}
        spacing={{ md: 2 }}
        columns={{ xs: 12, md: 12, lg: 18 }}
      >
        <Grid item sm={12} lg={18} display={{ xs: "none", sm: "block" }}>
          <PageTitle>{t("Dashboard")}</PageTitle>
        </Grid>
        <Grid container item rowSpacing={5} xs={12} spacing={3}>
          <Grid item xs={12} sm={5} xl={6}>
            <MarketCapCard holoPrice={holoPrice} setHoloPrice={setHoloPrice} />
          </Grid>
          <Grid item xs={6} sm={3.5} xl={3}>
            <ApyCard />
          </Grid>
          <Grid item xs={6} sm={3.5} xl={3}>
            <StakedCard />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={18}>
          <TreasuryCard />
        </Grid>
        <Grid item xs={12} lg={18}>
          <HoloPrice holoPrice={holoPrice} />
        </Grid>
        <Grid container item xs={12} rowSpacing={5} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TaxRatesCard />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ResourcesCard />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={18}>
          <HelpBubble />
        </Grid>
      </Grid>
    </div>
  );
};

export default MarketCapPanel;
