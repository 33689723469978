import React from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import PageTitle from "../../Components/Typography/PageTitle";
import PageLayout from "../../Components/Layout/PageLayout";
import { useWalletContext } from "../../context/WalletContext";
import ResourcesCard from "../Dashboard/Resources";
import HelpBubble from "../Dashboard/HelpBubble";
import ConnectWalletPanel from "./ConnectWalletPanel";
import MyAccountPanel from "./MyAccountPanel";
import { useMintContext } from "../Mint/Context/MintContext";


const AccountPage = () => {
    const { t } = useTranslation();
    const { isWalletConnected } = useWalletContext();

    return (
        <useMintContext.Provider>
            <PageLayout>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <PageTitle> {t("pages.Account")} </PageTitle>
                    </Grid>
                    {!isWalletConnected && <Grid item xs={12}>
                        <ConnectWalletPanel />
                    </Grid>}
                    {isWalletConnected && <Grid item xs={12}>
                        <MyAccountPanel />
                    </Grid>}
                    <Grid item xs={12}>
                        <ResourcesCard />
                    </Grid>
                    <Grid item xs={12}>
                        <HelpBubble />
                    </Grid>
                </Grid>
            </PageLayout>
        </useMintContext.Provider>

    );
};

export default AccountPage;
