import React from "react";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import moment from "moment";
import { useTheme } from "@emotion/react";
import { useThemeContext } from "../context/ThemeContext";
const TaxRates = ({
  variant = "#A4ABB7",
  gradientStart = "#EEEFF2",
  variant2 = "#FF5925",
  gradientStart2 = "#FEE1D9",
  isHalf,
  labels = [],
  sell_fee = [],
  buy_fee = [],
}) => {
  const { isLight } = useThemeContext();
  const theme = useTheme();
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        pointStyle: "circle",
        labels: {
          padding: 20,
          usePointStyle: true,
          generateLabels: (chart) => {
            var data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.datasets.map(function (dataset, i) {
                var meta = chart.getDatasetMeta(0);
                var ds = dataset;
                var fill = ds.borderColor;
                var stroke = ds.borderColor;
                var bw = ds.borderWidth;
                return {
                  fontColor: fill,
                  text: dataset.label,
                  fillStyle: fill,
                  strokeStyle: theme.palette.colors.cardBackground,
                  lineWidth: 8,
                  hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                  datasetIndex: i,
                };
              });
            } else {
              return [];
            }
          },
        },
      },
      title: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        caretSize: 0,
        usePointStyle: true,
        xAlign: "center",
        yAlign: "bottom",
        backgroundColor: "white",
        callbacks: {
          title: function () {},
          label: (item, data) => {
            return `${item.formattedValue}%`;
            // data.datasets[item.datasetIndex].data[item.index].myProperty2
          },
          labelTextColor: function (context) {
            return "black";
          },
          labelColor: function (context) {
            return {
              borderColor: context.dataset.borderColor,
              backgroundColor: context.dataset.borderColor,
            };
          },
          labelPointStyle: function (context, a) {
            return {
              pointStyle: "circle",
              boxWidth: 5,
              lineWidth: 20,
            };
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.15,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
  };
  const data = () => {
    return {
      labels: isHalf
        ? labels.filter((label, i) => i > labels.length / 2)
        : labels,
      datasets: [
        {
          label: "Sell Tax",
          data: isHalf
            ? sell_fee.filter((label, i) => i > sell_fee.length / 2)
            : sell_fee,
          borderWidth: 1,
          borderColor: variant2,
          pointRadius: 0,
          pointHoverStyle: "circle",
          pointHoverRadius: 6,
          hoverBorderWidth: 24,
          pointHoverBorderColor: variant2 + "4d",
          pointHoverBackgroundColor: variant2,
          fill: "start",
          backgroundColor: (context) => {
            // Init background gradient background color
            const ctx = context.chart.ctx;
            // Create gradient on svg
            const gradient = ctx.createLinearGradient(
              0,
              0,
              0,
              context.chart.height
            );
            gradient.addColorStop(0.2, isLight ? gradientStart2 : "#4E4B66");
            gradient.addColorStop(
              0.7,
              isLight ? theme.palette.colors.cardBackground + "00" : "#34394200"
            );
            // end gradient on svg
            return gradient;
          },
        },
        {
          label: "Buy Tax",
          data: isHalf
            ? buy_fee.filter((label, i) => i > buy_fee.length / 2)
            : buy_fee,
          borderWidth: 1,
          borderColor: variant,
          pointRadius: 0,
          pointHoverStyle: "circle",
          pointHoverRadius: 6,
          hoverBorderWidth: 24,
          pointHoverBorderColor: variant + "4d",
          pointHoverBackgroundColor: variant,
          fill: "start",
          backgroundColor: (context) => {
            // Init background gradient background color
            const ctx = context.chart.ctx;
            // Create gradient on svg
            const gradient = ctx.createLinearGradient(
              0,
              0,
              0,
              context.chart.height
            );
            gradient.addColorStop(0.1, isLight ? gradientStart : "#4E4B66");
            gradient.addColorStop(
              0.7,
              isLight ? theme.palette.colors.cardBackground + "00" : "#34394200"
            );
            // end gradient on svg
            return gradient;
          },
        },
      ],
    };
  };
  const plugins = [
    {
      id: "tooltipLine",
      afterDatasetsDraw: (chart) => {
        // if tooltip active
        if (chart.tooltip?._active?.length) {
          // Declare bases
          let x = chart.tooltip._active[0].element.x;
          let yAxis = chart.scales.y;
          let ctx = chart.ctx;
          let label = chart.tooltip.dataPoints[1].label;
          // Create gradient on svg
          const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
          gradient.addColorStop(0, variant2 + "3b");
          gradient.addColorStop(0.5, variant2);
          gradient.addColorStop(1, variant2 + "3b");
          // end gradient on svg

          // Draw line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, yAxis.top);
          ctx.lineTo(x, yAxis.bottom);
          ctx.lineWidth = 2;
          ctx.strokeStyle = gradient;
          ctx.stroke();
          ctx.fontSize = "12px";
          ctx.fillStyle = variant2;
          const formattedLabel = moment(label).format("DD MMMM 'YY HH:mm");
          if (chart.width / 2 > x) {
            ctx.fillText(formattedLabel, x + 20, yAxis.bottom - 15);
          } else {
            ctx.textAlign = "end";
            ctx.fillText(formattedLabel, x - 20, yAxis.bottom - 15);
          }
          ctx.restore();
        }
      },
    },
  ];

  return <Line options={options} data={data()} plugins={plugins} />;
};

export default TaxRates;
