import React, { useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomSwitch from "./navigation/CustomSwitch";
import MenuDrawer from "./navigation/MenuDrawer";
import MobileMenuDrawer from "./navigation/MobileMenuDrawer";
import HoloAppbar from "./navigation/HoloAppbar";
import { useWalletContext } from "./context/WalletContext";
import { useContractContext } from "./context/ContractContext";
import { useApiResult } from "./context/ApiResultContext";

import "./App.css";
import useScrollTopByRoute from "./Hooks/useScrollTopByRoute";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.background,
  },
}));
function App() {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);
  useScrollTopByRoute();
  return (
    <div className={classes.root}>
      <useWalletContext.Provider>
        <useApiResult.Provider>
          <useContractContext.Provider>
            {matches ? (
              <HoloAppbar
                appbarPosition="sticky"
                menuIconVisible={true}
                handleMenuIconClick={setOpen}
                drawerStatus={open}
              />
            ) : (
              <HoloAppbar />
            )}
            <Box sx={{ display: "flex" }}>
              {matches ? (
                <MobileMenuDrawer setOpen={setOpen} open={open} />
              ) : (
                <MenuDrawer />
              )}
              <CustomSwitch />
            </Box>
          </useContractContext.Provider>
        </useApiResult.Provider>
      </useWalletContext.Provider>
    </div>
  );
}

export default App;
