import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import SummaryPanel from "./SummaryPanel";
import MarketCapPanel from "./MarketCapPanel";
import { white } from "../../constants/Colours";
import PageLayout from "../../Components/Layout/PageLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100%",
  },
  dashboardRightPanel: {
    backgroundColor: theme.palette.background,
    minHeight: "100%",
  },
}));
const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container rowSpacing={2} className={classes.dashboardRightPanel}>
        <SummaryPanel />
        <Grid item xs={12} md={12} lg={9}>
          <PageLayout>
            <MarketCapPanel />
          </PageLayout>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
