import { alpha, styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { white } from "../../constants/Colours";

const BootstrapInput = styled(InputBase)(({ theme, ...otherProps }) => {
  return {
    "& .MuiInputBase-input": {
      // minWidth: 60,
      width: "min-content",
      padding: "3px 11px 3px 31px",
      paddingRight: "11px!important",
      boxShadow: "0px 6px 16px rgba(0, 222, 163, 0.06)",
      borderRadius: 12,
      background: theme.palette[otherProps.color].main,
      color: white,
    },
    "& .Mui-selected": {
      background: alpha(theme.palette[otherProps.color].main, 0.18),
    },
  };
});

export default function RangeSelect({ children, ...props }) {
  return (
    <Select {...props} input={<BootstrapInput />}>
      {children}
    </Select>
  );
}
