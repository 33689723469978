import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { grey10 } from "../../../constants/Colours";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 12,
    letterSpacing: "0.75px",
    color: theme.palette.colors.grey10,
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
  },
}));

const GiltsFieldHeader = (props) => {
  const { headerText } = props;
  const classes = useStyles();
  return (
    <Typography variant="string" className={classes.header}>
      {" "}
      {headerText}{" "}
    </Typography>
  );
};

export default GiltsFieldHeader;
