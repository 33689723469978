import React from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import GiltsFieldHeader from "./GiltsFieldHeader";
import GiltsFieldValue from "./GiltsFieldValue";
import { useMediaQuery } from "@mui/material";
import { secondsToPeriod } from "../../../utils/Date";
import { float2 } from "../../../utils/Number";

const VestingPeriodField = (props) => {
  const { vestingTime = 7 } = props;
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:900px)");
  const period = secondsToPeriod(vestingTime);
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid
        container
        item
        xs={12}
        justifyContent={matches ? "center" : "flex-start"}
      >
        <GiltsFieldHeader headerText={t("mintingPage.vestingPeriod")} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent={matches ? "center" : "flex-start"}
      >
        {period.type === "day" ? (
          <GiltsFieldValue
            value={t("mintingPage.days", { time: `${period.time}` })}
          />
        ) : (
          <GiltsFieldValue
            value={t("mintingPage.hour", { time: `${float2(period.time)}` })}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default VestingPeriodField;
