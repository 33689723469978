import { TextField } from "@mui/material";
import { styled } from "@mui/styles";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 25,
    paddingRight: "0!important",
    zIndex: 1,
    minHeight: 40,
  },
  "& .MuiButton-contained.MuiButton-root": {
    minWidth: 150,
    padding: "14px 17px!important",
    width: "max-content",
    zIndex: 2,
    borderRadius: 25,
  },
  "& .MuiInputBase-input": {
    padding: "3px 0px 0px 32px!important",
    "&::placeholder": {
      color: theme.palette.colors.inputPlaceholder,
      opacity: 1,
    },
  },
  "@media (max-width: 450px)": {
    "& .MuiInputBase-input": {
      fontSize: "12px!important",
      paddingLeft: "15px!important",
    },
  },
}));
export default CustomTextField;
