import growth_down_pink from "../../assets/growth_down_pink.png";
import growth_up_pink from "../../assets/growth_up_pink.png";
import growth_down_orange from "../../assets/growth_down_orange.png";
import growth_up_orange from "../../assets/growth_up_orange.png";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  growthChartIcon: {
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
const GraphStatus = ({
  variant = "primary",
  isDown = false,
  className = "",
  ...props
}) => {
  const classes = useStyles();
  let imageSource = growth_up_pink;
  if (variant === "secondary") {
    if (isDown) {
      imageSource = growth_down_orange;
    } else {
      imageSource = growth_up_orange;
    }
  } else {
    if (isDown) {
      imageSource = growth_down_pink;
    } else {
      imageSource = growth_up_pink;
    }
  }
  return (
    <img
      alt="Growth"
      className={`${classes.growthChartIcon} ${className}`}
      src={imageSource}
      {...props}
    />
  );
};

export default GraphStatus;
