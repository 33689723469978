import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { holoBlack } from "../../../constants/Colours";
const TitleEl = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.holoBlack,
  paddingBottom: 16,
  whiteSpace: "wrap",
}));
const Title = ({ children, ...props }) => {
  const { variantParam = "body2" } = props;
  return (
    <TitleEl variant={variantParam} {...props}>
      {children}
    </TitleEl>
  );
};

export default Title;
