import * as React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { holoBlack, orange, holoOrange } from "../../constants/Colours";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useContractContext } from "../../context/ContractContext";
import { useEffect } from "react";
import { useApiResult } from "../../context/ApiResultContext";
import { float4 } from "../../utils/Number";
import ValueCard from "../../Components/ValueCard";
import GraphStatus from "../../Components/Icon/GraphStatus";
const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
  },
  cardContent: {
    margin: "auto 0",
  },
  title: {
    paddingBottom: 16,
    color: theme.palette.colors.holoBlack,
    whiteSpace: "nowrap",
  },
  value: {
    paddingBottom: 16,
    color: theme.palette.colors.holoOrange,
    fontFamily: "PowerGrotesk-Medium!important",
  },
  change: {
    color: theme.palette.colors.orange,
    whiteSpace: "nowrap",
  },
  growthChart: {
    display: "flex",
    alignItems: "center",
  },
  changeIcon: {
    marginRight: 5,
  },
  growthChartIcon: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export default function StakedCard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [deposited, setDeposited] = useState("-");
  const { HoloYieldContract } = useContractContext();
  const { data } = useApiResult();
  useEffect(() => {
    getDepositedValue();
  }, []);
  const getDepositedValue = () => {
    try {
      const circulatingSupply_req = HoloYieldContract.circulatingSupply();
      const totalSupply_req = HoloYieldContract.totalSupply();
      Promise.all([circulatingSupply_req, totalSupply_req])
        .then(([circulatingSupply, totalSupply]) => {
          const deposit_fraction = (100 * circulatingSupply) / totalSupply;
          setDeposited(parseFloat(deposit_fraction.toFixed(3)));
        })
        .catch((errors) => {
          console.log(errors);
          setDeposited("Error");
        });
    } catch (error) {
      console.log(error);
      setDeposited("Error");
    }
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid xs={6}>
            <Typography variant="body2" className={classes.title}>
              {t("staked")}
            </Typography>
            <Typography variant="h5" className={classes.value}>
              {deposited}%
            </Typography>
            <ValueCard.Change
              noMargin
              change={float4(data?.change?.value_deposited)}
            />
          </Grid>
          <Grid
            xs={6}
            className={classes.growthChart}
            display={{ xs: "none", md: "flex" }}
          >
            <GraphStatus
              isDown={data?.change?.value_deposited < 0}
              variant="secondary"
              className={classes.growthChartIcon}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
