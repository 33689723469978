import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import TokenField from "../fields/TokenField";
import AssetMintedField from "../fields/AssetMintedField";
import PriceField from "../fields/PriceField";
import GiltsChipField from "../fields/GiltsChipField";
import VestingPeriodField from "../fields/VestingPeriodField";
import DayGiltHeader from "./DayGiltHeader";
import GiltButtonField from "../fields/GiltButtonField";
import VestedField from "../fields/VestedField";
import MintAmountField from "../fields/MintAmountField";
import { useMediaQuery } from "@mui/material";
import VestingInfoField from "../fields/VestingInfoField";
import { useWalletContext } from "../../../context/WalletContext";
import { float2 } from "../../../utils/Number";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 270,
    alignItems: "center",
    display: "flex",
    paddingTop: 6,
    paddingBottom: 6,
  },
  infoContainer: {},
  content: {
    paddingTop: "33px!important",
    paddingBottom: "45px!important",
  },
}));

const GiltCard = ({
  price = "-",
  discount = "-",
  vestingTime = 0,
  isBusd = false,
  gilt,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isWalletConnected, setWalletConnected } = useWalletContext();
  const matches = useMediaQuery("(max-width:1200px)");
  const buttonClickHandler = (buttonType) => {
    if (buttonType === "CONNECT") {
      setWalletConnected(!isWalletConnected);
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Grid container spacing={4}>
          <DayGiltHeader time={vestingTime} />
          <Grid container item xs={12} spacing={4}>
            <Grid container item xs={12} sm={6} lg={5}>
              <Grid container item xs={5}>
                <TokenField isBusd={isBusd} />
              </Grid>
              <Grid container item xs={7}>
                <AssetMintedField />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} lg={5}>
              <Grid container item xs={3}>
                <PriceField price={price} />
              </Grid>
              <Grid container item xs={4}>
                <GiltsChipField
                  headerText={t("mintingPage.discount")}
                  value={`-${float2(Math.abs(discount))} %`}
                />
              </Grid>
              <Grid container item xs={5}>
                <VestingPeriodField vestingTime={vestingTime} />
              </Grid>
            </Grid>
            {isWalletConnected && !matches && (
              <Grid container item xs={2}>
                <VestedField />
              </Grid>
            )}
            {!isWalletConnected && (
              <Grid container item xs={12} lg={2}>
                <GiltButtonField
                  label={t("buttons.connect")}
                  param="CONNECT"
                  onClickHandler={buttonClickHandler}
                />
              </Grid>
            )}
          </Grid>
          {isWalletConnected && (
            <Grid container item xs={!matches ? 11 : 12}>
              <MintAmountField isBusd={isBusd} gilt={gilt} />
            </Grid>
          )}
          {isWalletConnected && matches && (
            <Grid
              container
              item
              xs={!matches ? 11 : 12}
              justifyContent="center"
              className={classes.infoContainer}
            >
              <VestingInfoField />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GiltCard;
