import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ValueCard from "../ValueCard";
import { useWrapContext } from "../../Pages/Wrap/Context/WrapContext";

const useStyles = makeStyles((theme) => ({
  value: {
    fontSize: 25,
    fontWeight: 500,
    lineHeight: "34px",
    letterSpacing: "1px",
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));
const HoloWrapPriceCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { holoWrapPrice } = useWrapContext();
  return (
    <ValueCard size="lg">
      <Grid container item xs={12}>
        <ValueCard.Title variantParam="string" className={classes.title}>
          {t("holoWrapPrice")}{" "}
        </ValueCard.Title>
        <Grid container item xs={12} alignItems="flex-start">
          <ValueCard.Value
            variantParam="string"
            className={classes.value}
            value={`$ ${holoWrapPrice}`}
          />
        </Grid>
      </Grid>
    </ValueCard>
  );
};

export default HoloWrapPriceCard;
