import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { holoBlack } from "../../../constants/Colours";
import MyGiltsTable from "./MyGiltsTable";
import MyGiltsMediumSizeTable from "./MyGiltsMediumSizeTable";
import MyGiltsSmallSizeTable from "./MyGiltsSmallSizeTable";
import Loading from "../../../Components/Loading";
import AlertComponent from "../../../Components/Alert";
import { useMintContext } from "../Context/MintContext";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 270,
  },
  title: {
    fontSize: 24,
    letterSpacing: "0.75px",
    color: theme.palette.colors.holoBlack,
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    lineHeight: "29px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      fontWeight: 400,
    },
  },
  table: {
    paddingTop: 35,
  },
  infoContainer: {
    paddingTop: 32,
    paddingRight: 32,
  },
}));

const MyGiltsCard = (props) => {
  const { showClaimButtons = true } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { myGilts } = useMintContext();
  if (myGilts.data.length === 0 && !myGilts.loading) return <></>;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="string" className={classes.title}>
              {" "}
              {t("mintingPage.myGilts")}{" "}
            </Typography>
          </Grid>
          {myGilts.loading ? (
            <Grid item container justifyContent={"center"}>
              <Grid item>
                <Loading />
              </Grid>
            </Grid>
          ) : myGilts.status === undefined ? (
            <Grid container item xs={12} className={classes.table}>
              <Grid item container display={{ xs: "none", lg: "flex" }}>
                <MyGiltsTable
                  rows={myGilts.data}
                  showClaimButtons={showClaimButtons}
                />
              </Grid>
              <Grid
                item
                container
                display={{ xs: "none", md: "flex", lg: "none" }}
              >
                <MyGiltsMediumSizeTable
                  rows={myGilts.data}
                  showClaimButtons={showClaimButtons}
                />
              </Grid>
              <Grid
                item
                container
                display={{ xs: "flex", sm: "flex", md: "none" }}
              >
                <MyGiltsSmallSizeTable
                  rows={myGilts.data}
                  showClaimButtons={showClaimButtons}
                />
              </Grid>
            </Grid>
          ) : (
            <AlertComponent
              severity={myGilts.status}
              message={myGilts.message}
            />
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MyGiltsCard;
