import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { black } from "../../constants/Colours";
import { makeStyles, useTheme } from "@mui/styles";
import Treasury from "../../Charts/Treasury";
import { Chip, Grid, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import Icon from "../../Components/Icon";
import RangeSelect from "../../Components/Select/RangeSelect";
import { useApiResult } from "../../context/ApiResultContext";
import Loading from "../../Components/Loading";
import { chartRangeE } from "../../constants/DataResources";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 380,
  },
  TreasuryTitle: {
    fontSize: "24px!important",
    lineHeight: 26,
    letterSpacing: 1,
    color: theme.palette.colors.black,
    [theme.breakpoints.down("md")]: {
      fontSize: "22px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px!important",
    },
  },
  TreasuryValue: {
    letterSpacing: 1,
    fontFamily: "PowerGrotesk-Medium!important",
    fontSize: "20px!important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px!important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px!important",
    },
  },
  TreasuryChartContainer: {
    height: "100%",
  },
}));

export default function TreasuryCard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const apiResult = useApiResult();
  const theme = useTheme();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item xs={8} sm={6} md={4}>
            <Typography component="div" className={classes.TreasuryTitle}>
              {t("treasuryValue")}
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              className={classes.TreasuryValue}
            >
              $
              {!apiResult.loading &&
                apiResult?.data?.graphs?.treasury?.data?.length > 0 &&
                apiResult.data.graphs.treasury.data[
                  apiResult.data.graphs.treasury.data.length - 1
                ].toLocaleString()}{" "}
              {t("Today")}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={6} md={8}>
            <RangeSelectors />
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.TreasuryChartContainer}>
        <Grid display={{ xs: "none", md: "block" }}>
          {apiResult.loading ? (
            <Loading />
          ) : (
            <Treasury
              gradientStart={theme.palette.colors.treasuryPrimaryGradientStart}
              gradientEnd={theme.palette.colors.treasuryPrimaryGradientEnd}
              data={apiResult?.data?.graphs?.treasury?.data}
              labels={apiResult?.data?.graphs?.treasury?.labels}
            />
          )}
        </Grid>
        <Grid display={{ xs: "block", md: "none" }}>
          {apiResult.loading ? (
            <Loading />
          ) : (
            <Treasury
              gradientStart={theme.palette.colors.treasuryPrimaryGradientStart}
              gradientEnd={theme.palette.colors.treasuryPrimaryGradientEnd}
              isHalf
              data={apiResult?.data?.graphs?.treasury?.data}
              labels={apiResult?.data?.graphs?.treasury?.labels}
            />
          )}
        </Grid>
      </div>
    </Card>
  );
}

const useRangeSelectorStyle = makeStyles((theme) => ({
  icon: {
    top: "3px!important",
    left: 10,
    right: "unset!important",
  },
  chip: {
    height: "32px!important",
    fontSize: "12px!important",
    "&:hover": {
      opacity: 0.6,
    },
    borderRadius: 12,

    [theme.breakpoints.down("md")]: {
      height: "26px!important",
      fontSize: "10px!important",
    },
  },
  chipLabel: {
    paddingLeft: "15px!important",
    paddingRight: "15px!important",
  },
}));

export const RangeSelectors = ({ color = "primary" }) => {
  const { t } = useTranslation();
  const classes = useRangeSelectorStyle();
  const { chartRangeOnChange, chartRange } = useApiResult();
  const Selectors = [
    {
      value: chartRangeE.all,
      label: "rangeSelector.all",
    },
    {
      value: chartRangeE.oneM,
      label: "rangeSelector.1M",
    },
    {
      value: chartRangeE.sixM,
      label: "rangeSelector.6M",
    },
    {
      value: chartRangeE.oneY,
      label: "rangeSelector.1Y",
    },
    {
      value: chartRangeE.ytd,
      label: "rangeSelector.YTD",
    },
  ];
  const handleChange = (event) => {
    chartRangeOnChange(event.target.value);
  };
  return (
    <Grid container justifyContent="flex-end">
      <Grid item display={{ xs: "none", sm: "block" }}>
        <Stack direction="row" spacing={2}>
          {Selectors.map((selector) => (
            <Chip
              className={classes.chip}
              key={selector.value}
              variant={selector.value === chartRange ? undefined : "outlined"}
              classes={{
                label: classes.chipLabel,
              }}
              color={color}
              label={t(selector.label)}
              clickable
              onClick={() => chartRangeOnChange(selector.value)}
            />
          ))}
        </Stack>
      </Grid>
      <Grid item display={{ xs: "block", sm: "none" }}>
        <RangeSelect
          IconComponent={(props) => (
            <span {...props}>
              <Icon icon="select-arrow" size={17} />
            </span>
          )}
          classes={{
            icon: classes.icon,
          }}
          value={chartRange}
          onChange={handleChange}
          color={color}
        >
          {Selectors.map((selector) => (
            <MenuItem color={color} value={selector.value}>
              {t(selector.label)}
            </MenuItem>
          ))}
        </RangeSelect>
      </Grid>
    </Grid>
  );
};
