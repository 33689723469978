import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Logo from "../assets/logo.svg";
import Icon from "../Components/Icon";
import { menuList } from "./MenuNameExporter";
import { holoBlack, white3 } from "../constants/Colours";
import CustomTooltip from "../Components/Tooltip/CustomTooltip";
import { Typography } from "@mui/material";
import { useThemeContext } from "../context/ThemeContext";
import CustomSwitch, { ThemeSwitch } from "../Components/Switch/CustomSwitch";
import { useTheme } from "@emotion/react";
const useStyles = makeStyles((theme) => ({
  defaultMenuItem: {
    display: "flex",
    flexDirection: "column",
    "&.MuiButtonBase-root": {
      borderRadius: 12,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 56,
      height: 56,
    },
    "&.MuiButtonBase-root:hover": {
      background: theme.palette.colors.menuItembackgroundColor,
    },
  },
  selectedMenuItem: {
    display: "flex",
    flexDirection: "column",
    "&.MuiButtonBase-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 56,
      height: 56,
      background: theme.palette.colors.menuItembackgroundColor,
      borderRadius: 12,
    },
  },
  itemText: {
    fontSize: "10px!important",
    fontFamily: "PowerGrotesk-Regular!important",
    fontWeight: 300,
    textAlign: "center",
    marginTop: "5px!important",
    lineHeight: "1!important",
    color: theme.palette.colors.holoBlack,
  },
  SelectedItemText: {
    fontFamily: "PowerGrotesk-Bold!important",
    fontWeight: 500,
    marginTop: "5px!important",
    lineHeight: "1!important",
    textDecoration: "none",
  },
}));

export default function MenuList({ isMobile = false, afterClick = () => {} }) {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [selectedMenu, setSelectedMenu] = useState({
    url: location.pathname,
    name: location.pathname.replace("/", ""),
  });
  const listItemRender = (isSelected, iconName, menuName) => {
    return (
      <CustomTooltip title={menuName}>
        <ListItemButton
          className={
            isSelected ? classes.selectedMenuItem : classes.defaultMenuItem
          }
        >
          <Icon icon={isSelected ? iconName + "-active" : iconName} color={isSelected ? theme.palette.colors.menuItemaActiveColor: undefined} size="24" />
          {isMobile && (
            <Typography
              className={`${isSelected ? classes.SelectedItemText : ""} ${
                classes.itemText
              }`}
            >
              {menuName}
            </Typography>
          )}
        </ListItemButton>
      </CustomTooltip>
    );
  };

  const renderDocsLink = (isSelected, iconName, menu) => {
    return (
      <CustomTooltip title={menu.title}>
        <ListItemButton
          className={
            isSelected ? classes.selectedMenuItem : classes.defaultMenuItem
          }
        >
          <a
            href={menu.to}
            target="_blank"
            rel="noreferrer"
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              textDecoration: "none",
            }}
          >
            <Icon
              icon={isSelected ? iconName + "-active" : iconName}
              size="24"
            />
            {isMobile && (
              <Typography
                className={`${isSelected ? classes.SelectedItemText : ""} ${
                  classes.itemText
                }`}
              >
                {menu.title}
              </Typography>
            )}
          </a>
        </ListItemButton>
      </CustomTooltip>
    );
  };

  const menuItemClickHandler = (item) => {
    setSelectedMenu(item);
    navigate(item.url);
    if (isMobile) {
      afterClick();
    }
  };
  const { switchDarkTheme, switchLightTheme, isLight } = useThemeContext();
  return (
    <List>
      <ListItem
        style={{ justifyContent: "center", marginBottom: 10, marginTop: 5 }}
      >
        <img src={Logo} alt="logo" />
      </ListItem>
      {menuList.map((menu) => {
        if (menu.to) {
          return (
            <ListItem key={menu.name}>
              {renderDocsLink(selectedMenu?.url === menu.url, menu.icon, menu)}
            </ListItem>
          );
        }

        return (
          <ListItem key={menu.name} onClick={() => menuItemClickHandler(menu)}>
            {listItemRender(
              selectedMenu?.url === menu.url,
              menu.icon,
              isMobile ? menu.mobileTitle : menu.title
            )}
          </ListItem>
        );
      })}
      {isMobile && (
        <ListItem
          style={{ justifyContent: "center", marginBottom: 10, marginTop: 5 }}
        >
          <ThemeSwitch
            color="primary"
            checked={isLight}
            onClick={(e) => (isLight ? switchDarkTheme() : switchLightTheme())}
          />
        </ListItem>
      )}
    </List>
  );
}
