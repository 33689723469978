import constate from "constate";
import moment from "moment";
import { useEffect, useState } from "react";
import { chartRangeE } from "../constants/DataResources";
import { float1 } from "../utils/Number";

const [ApiResultProvider, useApiResult] = constate(
  ({ defaultRequestParameters = undefined }) => {
    const [chartRange, setChartRange] = useState(chartRangeE.all);
    const dayToSecond = 86400;
    const [requestParameters, setRequestParameters] = useState(
      defaultRequestParameters ?? {
        start: formatDate(getSDateByRange(chartRange), true),
        end: formatDate(moment()),
        freq: dayToSecond,
      }
    );
    const [result, setResult] = useState({
      data: {
        change: {
          apy: 0,
          but_fee: 0,
          fraction_deposited: 0,
          holo_price: 0,
          marketcap: 0,
          sell_fee: 0,
          treasury: 0,
          value_deposited: 0,
          holders: 0,
        },
        graphs: {
          apy: { labels: [], data: [] },
          buy_fee: { labels: [], data: [] },
          fraction_deposited: { labels: [], data: [] },
          holo_price: { labels: [], data: [] },
          marketcap: { labels: [], data: [] },
          sell_fee: { labels: [], data: [] },
          treasury: { labels: [], data: [] },
          value_deposited: { labels: [], data: [] },
          holders: { labels: [], data: [] },
        },
      },
      loading: true,
      message: "",
      status: undefined,
    });
    useEffect(() => {
      makeRequest();
    }, [requestParameters]);
    const makeRequest = async () => {
      const { start, end, freq } = requestParameters;
      fetch(
        `https://api.holoclear.xyz/data/?start=${encodeURIComponent(
          start
        )}&end=${encodeURIComponent(end)}&freq=${freq}`
      )
        .then((res) => res.json())
        .then((values) => {
          setResult((prevResult) => ({
            ...prevResult,
            data: {
              ...values,
              change: {
                ...values.change,
                apy: float1(values.change.apy),
                buy_fee: float1(values.change.buy_fee),
                fraction_deposited: float1(values.change.fraction_deposited),
                holo_price: float1(values.change.holo_price),
                marketcap: float1(values.change.marketcap),
                sell_fee: float1(values.change.sell_fee),
                treasury: float1(values.change.treasury),
                value_deposited: float1(values.change.value_deposited),
                holders: float1(values.change.holders),
              },
              graphs: {
                ...values.graphs,
                apy: responseToChartStruc(values.graphs.apy),
                buy_fee: responseToChartStruc(
                  values.graphs.buy_fee,
                  multiply(100)
                ),
                fraction_deposited: responseToChartStruc(
                  values.graphs.fraction_deposited
                ),
                holo_price: responseToChartStruc(values.graphs.holo_price),
                marketcap: responseToChartStruc(values.graphs.marketcap),
                sell_fee: responseToChartStruc(
                  values.graphs.sell_fee,
                  multiply(100)
                ),
                treasury: responseToChartStruc(values.graphs.treasury),
                value_deposited: responseToChartStruc(
                  values.graphs.value_deposited
                ),
                holders: responseToChartStruc(values.graphs.holders),
              },
            },
          }));
        })
        .catch((err) => {
          console.log(err);
          setResult((prevResult) => ({
            ...prevResult,
            message: err.toString(),
            status: "error",
          }));
        })
        .finally(() => {
          setResult((prevResult) => ({
            ...prevResult,
            loading: false,
          }));
        });
    };
    const chartRangeOnChange = (selectedRange = chartRangeE.all) => {
      setChartRange(selectedRange);
      setRequestParameters((prevParams) => ({
        ...prevParams,
        start: formatDate(getSDateByRange(selectedRange), true),
      }));
    };
    return {
      data: result.data,
      update: makeRequest,
      loading: result.loading,
      status: result.status,
      message: result.message,
      chartRangeOnChange,
      chartRange,
    };
  }
);

useApiResult.Provider = ApiResultProvider;
export { useApiResult };

const responseToChartStruc = (data = {}, dataformatter = (d) => d) => {
  return {
    labels: Object.keys(data),
    data: Object.keys(data).map((key) => dataformatter(data[key])),
  };
};

const multiply = (times) => {
  return (d) => d * times;
};

export const getSDateByRange = (selectedRange = chartRangeE.all) => {
  let sdate;
  switch (selectedRange) {
    case chartRangeE.oneM:
      sdate = moment().subtract(1, "months");
      break;
    case chartRangeE.sixM:
      sdate = moment().subtract(6, "month");
      break;
    case chartRangeE.oneY:
      sdate = moment().subtract(1, "years");
      break;
    case chartRangeE.ytd:
      sdate = moment().startOf("year");
      break;
    default:
      sdate = moment().startOf("year").set("years", 2020);
      break;
  }
  return sdate;
};

export const formatDate = (momentDate = moment(), startOfDay = false) => {
  if (startOfDay) return momentDate.format("YYYY-MM-DDT00:00:00.000000");
  return momentDate.format("YYYY-MM-DDTHH:MM:ss.SSSSSS");
};
