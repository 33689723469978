import React from "react";
import Grid from "@mui/material/Grid";
import ApyCard from "../Dashboard/ApyCard";
import HoloPriceCard from "../../Components/HoloPrice";
import MyGiltsCard from "../Mint/mygilts/MyGiltsCard";
import DepositedBalanceCard from "./DepositedBalanceCard";
import AddTokenSection from "./AddTokenSection";

const MyAccountPanel = () => {
  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} spacing={4}>
        <Grid container item xs={12} md={6}>
          <DepositedBalanceCard showUndepositedBalance={true} />
        </Grid>
        <Grid container item md={6} display={{ xs: "none", md: "flex" }}>
          <HoloPriceCard />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={4}>
        <Grid container item xs={6} md={4}>
          <DepositedBalanceCard showDepositedBalance={true} />
        </Grid>
        {/* only visible for xs, sm screens */}
        <Grid container item xs={6} display={{ xs: "flex", md: "none" }}>
          <HoloPriceCard />
        </Grid>
        <Grid container item xs={6} md={4}>
          <ApyCard isAccountPage />
        </Grid>
        <Grid container item xs={6} md={4}>
          <DepositedBalanceCard showROI={true} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MyGiltsCard showClaimButtons={false} />
      </Grid>
      <Grid item xs={12}>
        <AddTokenSection />
      </Grid>
    </Grid>
  );
};

export default MyAccountPanel;
