import React, { useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { AlertStatus } from "../../../constants/Status";
import { usePresaleContext } from "../Context/PresaleContext";
import AlertComponent from "../../../Components/Alert";
import { useContractContext } from "../../../context/ContractContext";
import { useWalletContext } from "../../../context/WalletContext";

const useStyles = makeStyles((theme) => ({
  balanceText: {
    display: "block",
    color: theme.palette.colors.grey4,
    fontSize: 16,
    fontWeight: 300,
    fontFamily: "Light",
    letterSpacing: "0.016em",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 16,
    },
  },
  balanceTextBold: {
    fontFamily: "PowerGrotesk-Medium",
    fontWeight: 700,
  },
}));

const GrvsnapBonus = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { GRVSNAPBonusContract } = useContractContext();
  const { checkRedeemBonus, fetchPHoloBalance, checkPholoAllowance } = usePresaleContext();
  const { account } = useWalletContext();
  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    status: undefined,
    loading: false,
  });

  const redeemBonus = () => new Promise(async (resolve, reject) => {
    try {
      let transaction = await GRVSNAPBonusContract.redeem();
      await transaction.wait();
      await fetchPHoloBalance();
      return resolve(transaction.value);
    } catch (error) {
      return reject(error);
    }
  });

  const handleRedeemClick = async () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      loading: true
    }));
    redeemBonus()
      .then((value) => {
        refreshData();
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("success.transaction"),
          status: AlertStatus.Success,
          loading: false
        }));
      })
      .catch((err) => {
        console.log(err.code);
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: err.code === "ACTION_REJECTED" ? t("errors.userRejected") : t("errors.transaction"),
          status: AlertStatus.Error,
          loading: false
        }));
      })
      .finally(() => {
        setSubmitStatus((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  }

  const refreshData = async () => {
    await checkRedeemBonus(account);
    await checkPholoAllowance();
    await fetchPHoloBalance();
  }

  const closeAlert = () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      message: "",
      status: undefined,
      loading: false,
    }));
  };

  return (
    <Grid
      container
      direction={{ xs: "column-reverse", sm: "row" }}
      alignItems={{ sm: "center" }}
      rowSpacing={2}
    >
      <Grid item xs={12} sm={8} md={9}>
        <Typography variant="string" className={classes.balanceText}>
          {t("presalePage.GrvsnapBonusDescription")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <Button
          variant="contained"
          fullWidth
          size="large"
          color="secondary"
          onClick={handleRedeemClick}
        >
          {!submitStatus.loading && t("buttons.RedeemBonus")}
          {submitStatus.loading && <CircularProgress size={18} />}
        </Button>
      </Grid>
      {submitStatus.status && <AlertComponent severity={submitStatus.status} message={submitStatus.message} onclickHandler={closeAlert} />}
    </Grid>
  );
};

export default GrvsnapBonus;
