import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import { holoDark } from "../../../constants/Colours";

const ValueField = styled(Typography)(({ theme, ...props }) => ({
  fontSize: props.fontSize || 14,
  letterSpacing: "0.75px",
  color: theme.palette.colors.holoBlack,
  fontFamily: props.fontFamily || "UniversLTStd",
  fontWeight: props.fontWeight || "400",
}));

const GiltsFieldValue = ({ value, ...props }) => {
  return (
    <ValueField variant="string" {...props}>
      {value}
    </ValueField>
  );
};

export default GiltsFieldValue;
