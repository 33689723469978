import { ThemeProvider } from "@mui/system";
import React from "react";
import { useThemeContext } from "../context/ThemeContext";

const Themes = ({ children }) => {
  return (
    <useThemeContext.Provider>
      <ThemesContent>{children}</ThemesContent>
    </useThemeContext.Provider>
  );
};
const ThemesContent = ({ children }) => {
  const { theme } = useThemeContext();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Themes;
