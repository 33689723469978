import * as React from "react";
import { styled } from "@mui/styles";
import { ButtonBase } from "@mui/material";

const ThemeButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.colors.white3 + "!important",
  padding: "16px 15px!important",
  borderRadius: "12px!important",
}));

export default ThemeButton;
