import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import { white } from "../../constants/Colours";
import moon from "../../assets/moon.svg";
import sun from "../../assets/sun.svg";

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, ...props }) => ({
  width: 44,
  height: 24,
  padding: 0,
  overflow: "visible",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    backgroundColor: white,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        transitionDuration: "400ms",
        background: "white",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    boxShadow: "none",
    background: theme.palette[props.color].main,
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
    backgroundColor: white,
    opacity: 1,
    border: "1px solid " + theme.palette[props.color].main,
    transition: theme.transitions.create(["background-color"], {
      duration: 200,
    }),
  },
}));

export const ThemeSwitch = styled(CustomSwitch)(({ theme, ...props }) => ({
  "& .MuiSwitch-switchBase": {
    backgroundColor: white,
  },
  "& .MuiSwitch-switchBase": {
    backgroundColor: white,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: white,
        opacity: 1,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        transitionDuration: "400ms",
        background: theme.palette[props.color].main,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 20,
    height: 20,
    boxShadow: "none",
    background: white,
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
    backgroundColor: theme.palette[props.color].main,
    opacity: 1,
    border: "1px solid " + theme.palette[props.color].main,
    transition: theme.transitions.create(["background-color"], {
      duration: 200,
    }),
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "52%",
      transform: "translateY(-50%)",
      width: 13,
      height: 13,
    },
    "&:before": {
      backgroundImage: `url(${sun})`,
      left: 5,
    },
    "&:after": {
      backgroundImage: `url(${moon})`,
      right: 5,
    },
  },
}));

export default CustomSwitch;
