import { useState, useEffect } from "react";
import { ethers } from "ethers";
import constate from "constate";
import { useTranslation } from "react-i18next";
import { AlertStatus } from "../../../constants/Status";
import { useWalletContext } from "../../../context/WalletContext";
import { useContractContext } from "../../../context/ContractContext";

const [PoolContextProvider, usePoolContext] = constate(() => {
  const { account } = useWalletContext();
  const { LotteryContract } = useContractContext();
  const { t } = useTranslation();
  const [holoPoolValue, setHoloPoolValue] = useState();
  const [nextDrawTime, setNextDrawTime] = useState();
  const [winners, setWinners] = useState(undefined);
  const [isWalletOwnerWinner, setWalletOwnerWinner] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    status: undefined,
    loading: false,
  });
  const [loading, setLoading] = useState(true); // Pool page loading
  
  useEffect(() => {
    try {
      getHoloPoolValue();
      setNextDrawTimeFunc();
      getAllWinners();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const getHoloPoolValue = (async () => {
    const busdBalance = await LotteryContract.busd_balance();
    setHoloPoolValue(ethers.utils.formatEther(busdBalance));
  });

  const setNextDrawTimeFunc = (async () => {
    const nextDrawTime = await LotteryContract.next_draw();
    setNextDrawTime(Number(nextDrawTime));
  });

  const getAllWinners = (async () => {
    const winnerInfo = await LotteryContract.view_winners();
    const winnerInfoArr = winnerInfo.toString().split(",");
    let winnersArray = [];
    if (winnerInfoArr.length % 5 !== 0) {
      setWinners([]);
    } else {
      for(let i = 0; i < winnerInfoArr.length; i = i+5) {
        //every 5 object in the array belongs to one winner
        // _id, _time, _amount, _address, _claimed
        const winner = {
          id: winnerInfoArr[i],
          timeDraw: Number(winnerInfoArr[i+1]),
          amount: ethers.utils.formatEther(winnerInfoArr[i+2]),
          address: winnerInfoArr[i+3],
          claimed: winnerInfoArr[i+4] === 'true'
        }
        winnersArray.push(winner);
        if (winnerInfoArr[i+1] === account && !winner.claimed) {
          setWalletOwnerWinner(true);
        }
      }
      winnersArray = winnersArray.sort((a,b) => a.timeDraw > b.timeDraw ? -1 : (a.timeDraw < b.timeDraw) ? 1 : 0 )
      setWinners(winnersArray);
    }
    setLoading(false);
  });

  const handleClaimPrizeClick = async () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      loading: true
    }));
      try {
        claimPrize()
          .then(() => {
            getAllWinners();
            getHoloPoolValue();
            setNextDrawTimeFunc();
            setSubmitStatus((prevState) => ({
              ...prevState,
              message: t("success.transaction"),
              status: AlertStatus.Success,
              loading: false
            }));
          })
          .catch((err) => {
            console.log(err);
            setSubmitStatus((prevState) => ({
              ...prevState,
              message: t("errors.transaction"),
              status: AlertStatus.Error,
              loading: false
            }));
          })
          .finally(() => {
            setSubmitStatus((prevState) => ({
              ...prevState,
              loading: false,
            }));
          });
      } catch (e) {
        console.log(e);
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("errors.transaction"),
          status: AlertStatus.Error,
          loading: false
        }));
      }
  }
  
  const claimPrize = () => new Promise(async (resolve, reject) => {
    try {
      const claim = await LotteryContract.claim({
        from: account,
        gasLimit: 100000
      });
      await claim.wait();
      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });

  return {
    holoPoolValue,
    nextDrawTime,
    winners,
    isWalletOwnerWinner,
    submitStatus,
    loading,
    handleClaimPrizeClick,
    setSubmitStatus,
    setWalletOwnerWinner,
  };
});

usePoolContext.Provider = PoolContextProvider;
export { usePoolContext };
