import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useContractContext } from "../../../context/ContractContext";
import { useWalletContext } from "../../../context/WalletContext";
import AlertComponent from "../../../Components/Alert";
import { AlertStatus } from "../../../constants/Status";
import { toDaysMinutesSeconds } from "../../../utils/Date";
import { usePresaleContext } from "../Context/PresaleContext";

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: 120,
    paddingTop: "33px!important",
    paddingBottom: "45px!important",
  },
  title: {
    display: "block",
    fontSize: 24,
    fontWeight: 400,
    lineHeight: "29px",
    letterSpacing: "0.016em",
    marginBottom: "30px!important",
  },
  tableItem: {
    display: "flex",
    flexDirection: "column!important",
    justifyContent: "space-between",
    rowGap: 9,
    ":nth-child(2)": {
      marginBottom: "8px!important",
    },
  },
  header: {
    display: "block",
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.colors.grey10,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  amount: {
    display: "block",
    width: "100%",
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.colors.grey4,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: 12,
    },
  },
  vestingRemaining: {
    display: "block",
    width: "100%",
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.colors.grey4,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: 12,
    },
  },
  redeemable: {
    display: "block",
    width: "100%",
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.colors.grey4,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: 12,
    },
  },
  redeemButton: {
    borderRadius: "25px!important",
    paddingTop: "9px!important",
    paddingBottom: "6px!important",
    height: "auto!important",
  }
}));
const TransferredTokens = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { PresaleTransferContract, HoloYieldContract } = useContractContext();
  const { account } = useWalletContext();
  const { pHoloBalance } = usePresaleContext();

  const [amount, setAmount] = useState();
  const [timeToNextRedeem, setTimeToNextRedeem] = useState();
  const [totalVestingRemaining, setTotalVestingRemaining] = useState();
  const [redeemableAmount, setRedeemableAmount] = useState();
  const [isRedeemable, setRedeemable] = useState(false);

  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    status: undefined,
    loading: false,
  });

  const checkLastVesting = async () => {
    try {
      const stakeInfo = await PresaleTransferContract.stakeInfo(account);
      return stakeInfo && stakeInfo.length >= 7 && stakeInfo[8] === true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  const getStakeInfo = async () => {
    try {
      const stakeInfo = await PresaleTransferContract.stakeInfo(account);
      if (stakeInfo && stakeInfo.length >= 7) {
        const currentVestedHoloYieldAmount = await HoloYieldContract.balanceForGons(stakeInfo[6])
        setAmount(Number.parseFloat(currentVestedHoloYieldAmount / 1e18).toFixed(5));
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getTimeToNextRedeem = async () => {
    try {
      const nextRedeem = await PresaleTransferContract.time_to_next_claim(account);
      setTimeToNextRedeem(Number(nextRedeem) === 0 ? 0 : toDaysMinutesSeconds(Number(nextRedeem)));
    } catch (e) {
      console.log(e);
    }
  }

  const getTotalVestingRemaining = async () => {
    try {
      const totalVesting = await PresaleTransferContract.total_vesting_remaining(account);
      setTotalVestingRemaining(Number(totalVesting) === 0 ? 0 : toDaysMinutesSeconds(Number(totalVesting)));
    } catch (e) {
      console.log(e);
    }
  }

  const getRedeemableAmount = async () => {
    try {
      const redeemable = await PresaleTransferContract.redeemable(account);
      setRedeemableAmount(Number.parseFloat(redeemable / 1e18).toFixed(5));
    } catch (e) {
      console.log(e);
    }
  }

  const redeem = () => new Promise(async (resolve, reject) => {
    try {
      let transaction = await PresaleTransferContract.claim(account);
      await transaction.wait();
      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });

  const handleRedeemClick = async () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      loading: true
    }));
    redeem()
      .then(() => {
        loadTransferTokens();
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("success.transaction"),
          status: AlertStatus.Success,
          loading: false
        }));
      })
      .catch((err) => {
        console.log(err);
        setSubmitStatus((prevState) => ({
          ...prevState,
          message: t("errors.transaction"),
          status: AlertStatus.Error,
          loading: false
        }));
      })
      .finally(() => {
        setSubmitStatus((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  }

  const closeAlert = () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      message: "",
      status: undefined,
      loading: false,
    }));
  };

  const loadTransferTokens = async () => {
    // additional requirement: When user redeemed their last vesting, after that everything should be zero
    const isLastVestingRedeemed = await checkLastVesting();
    if (isLastVestingRedeemed === true) {
      setAmount(0);
      setTimeToNextRedeem(0);
      setRedeemableAmount(0);
      setTotalVestingRemaining(0);
    } else {
      getStakeInfo();
      getTimeToNextRedeem();
      getRedeemableAmount();
      getTotalVestingRemaining();
    }
  };

  useEffect(() => {
    setRedeemable(Number(timeToNextRedeem) === 0 && redeemableAmount > 0);
  }, [redeemableAmount, timeToNextRedeem])

  useEffect(() => {
    loadTransferTokens();
  }, [pHoloBalance]);

  return (
    <Card>
      <CardContent className={classes.content}>
        <Typography variant="string" className={classes.title}>
          {t("presalePage.myTransferredTokens")}
        </Typography>
        <Grid item container rowSpacing={2} alignItems="end">
          <Grid item container xs={12} md={10} spacing={1} rowSpacing={4}>
            <Grid
              item
              xs={6}
              sm={3}
              className={classes.tableItem}
              style={{ justifyContent: "center" }}
            >
              <Typography
                className={classes.header}
                variant="string"
                display={{ xs: "block" }}
              >
                {t("presalePage.amount")}
              </Typography>
              <Typography className={classes.amount} variant="string">
                {amount}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              className={classes.tableItem}
              style={{ justifyContent: "center" }}
            >
              <Typography
                className={classes.header}
                variant="string"
              >
                {t("presalePage.timeToNextRedeem")}
              </Typography>
              {timeToNextRedeem}
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              className={classes.tableItem}
              style={{ justifyContent: "center" }}
            >
              <Typography
                className={classes.header}
                variant="string"
              >
                {t("presalePage.totalVestingRemaining")}
              </Typography>
              <Typography
                className={classes.tableItem}
                variant="string"
              >
                {totalVestingRemaining}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              className={classes.tableItem}
              style={{ justifyContent: "center" }}
            >
              <Typography
                className={classes.header}
                variant="string"
                display={{ xs: "block" }}
              >
                {t("presalePage.redeemable")}
              </Typography>
              <Typography className={classes.redeemable} variant="string">
                {redeemableAmount}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} className={classes.tableItem}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.redeemButton}
              size="small"
              disabled={!isRedeemable}
              onClick={handleRedeemClick}
            >
              {submitStatus.loading && <CircularProgress color="warning" size={18} />}
              {!submitStatus.loading && t("buttons.redeem")}
            </Button>
          </Grid>
        </Grid>
        {submitStatus.status && <AlertComponent severity={submitStatus.status} message={submitStatus.message} onclickHandler={closeAlert} />}
      </CardContent>
    </Card>
  );
};

export default TransferredTokens;
