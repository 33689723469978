import React from "react";
import Drawer from "@mui/material/Drawer";
import { white } from "../constants/Colours";
import MenuList from "./MenuList";
import { useTheme } from "@mui/styles";

const drawerWidth = 96;

export default function MenuDrawer() {
  const theme = useTheme();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.background,
          borderRight: "1px solid " + theme.palette.primary.borderColor,
        },
      }}
      variant="permanent"
    >
      <MenuList />
    </Drawer>
  );
}
