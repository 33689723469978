import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWalletContext } from "../../context/WalletContext";
import { useStakeContext } from "./Context/Stake";
import Steps from "./Steps";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    letterSpacing: "0.75px",
    color: theme.palette.colors.holoBlack,
    fontFamily: ["UniversLTStd"].join(","),
    fontStyle: "normal",
    fontWeight: 400,
  },
  card: {
    minHeight: 200,
    display: "flex",
    alignItems: "center",
  },
  content: {
    width: "100%",
  },
  buttons: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
const StakeSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isStake, setStake } = useStakeContext();
  const { isWalletConnected } = useWalletContext();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Stack
          paddingBottom={4}
          direction={{ sm: "row", xs: "column" }}
          justifyContent="space-between"
          spacing={3}
        >
          <Typography className={classes.title} variant="string">
            {isStake ? t("Stake") : t("Unstake")}
          </Typography>
          {isWalletConnected && (
            <Stack direction="row" spacing={3}>
              <Button
                variant={`${isStake ? "contained" : "outlined"}`}
                onClick={() => setStake(true)}
                color="primary"
                size="medium"
                className={classes.buttons}
              >
                {t("Stake")}
              </Button>
              <Button
                variant={`${!isStake ? "contained" : "outlined"}`}
                onClick={() => setStake(false)}
                color="primary"
                size="medium"
                className={classes.buttons}
              >
                {t("Unstake")}
              </Button>
            </Stack>
          )}
        </Stack>
        <Steps />
      </CardContent>
    </Card>
  );
};

export default StakeSection;
