import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const ValueEl = styled(Typography)({
  fontFamily: "PowerGrotesk-Medium!important",
  letterSpacing: "0.03em",
});

const Value = ({ value, color = "primary", ...props }) => {
  const { variantParam = "h5" } = props;
  return (
    <ValueEl variant={variantParam} {...props} color={color}>
      {value}
    </ValueEl>
  );
};

export default Value;
