import React from "react";
import { Stack } from "@mui/material";

const SpaceComponent = ({ children }) => {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            {children}
        </Stack>
    );
};

export default SpaceComponent;