import React from "react";
import Grid from "@mui/material/Grid";
import ChipField from "../../../Components/Chip";
import GiltsFieldHeader from "./GiltsFieldHeader";
import { useMediaQuery } from "@mui/material";

const GiltsChipField = (props) => {
  const {
    value,
    headerVisible = true,
    headerText,
    componentWidth = 60,
    valueFontSize,
  } = props;
  const matches = useMediaQuery("(max-width:900px)");
  return (
    <Grid container item xs={12} spacing={2}>
      {headerVisible && (
        <Grid
          container
          item
          xs={12}
          justifyContent={matches ? "center" : "flex-start"}
        >
          <GiltsFieldHeader headerText={headerText} />
        </Grid>
      )}
      <Grid
        container
        item
        xs={12}
        justifyContent={matches ? "center" : "flex-start"}
      >
        <ChipField
          fontSize={valueFontSize}
          label={value}
          componentWidth={componentWidth}
        />
      </Grid>
    </Grid>
  );
};

export default GiltsChipField;
