import constate from "constate";
import useContract from "../Hooks/useContract";
import HoloClearConfig from "../blockChainContexts/Testnet/HoloClear/config";
import HoloWrapConfig from "../blockChainContexts/Testnet/HoloWrap/config";
import HoloYieldConfig from "../blockChainContexts/Testnet/HoloYield/config";
import MarketOracleConfig from "../blockChainContexts/Testnet/MarketOracle/config";
import PholoConfig from "../blockChainContexts/Testnet/pHolo/config";
import SeriescConfig from "../blockChainContexts/Testnet/SeriesCManager/config";
import VaultConfig from "../blockChainContexts/Testnet/Vault/config";
import LotteryConfig from "../blockChainContexts/Testnet/Lottery/config";
import RouterConfig from "../blockChainContexts/Testnet/Router/config";
import PresaleTransferConfig from "../blockChainContexts/Testnet/PresaleTransfer/config";
import GRVSNAPHelperConfig from "../blockChainContexts/Testnet/GRVSNAPHelper/config";
import GRVSNAPBonusConfig from "../blockChainContexts/Testnet/GRVSNAPBonus/config";
import BusdConfig from "../blockChainContexts/Testnet/Busd/config";
import SwapConfig from "../blockChainContexts/Testnet/Swap/config";
import PancakeSwapConfig from "../blockChainContexts/pancakeSwap/config";
import GiltsConfig from "../blockChainContexts/Testnet/Gilts/config";

const [ContractContextProvider, useContractContext] = constate(() => {
  const HoloClearContract = useContract(
    HoloClearConfig.address,
    HoloClearConfig.abi
  );
  const HoloWrapContract = useContract(
    HoloWrapConfig.address,
    HoloWrapConfig.abi
  );
  const HoloYieldContract = useContract(
    HoloYieldConfig.address,
    HoloYieldConfig.abi
  );
  const MarketOracleContract = useContract(
    MarketOracleConfig.address,
    MarketOracleConfig.abi
  );
  const PholoContract = useContract(PholoConfig.address, PholoConfig.abi);
  const SeriescContract = useContract(SeriescConfig.address, SeriescConfig.abi);
  const VaultContract = useContract(VaultConfig.address, VaultConfig.abi);
  const LotteryContract = useContract(LotteryConfig.address, LotteryConfig.abi);
  const RouterContract = useContract(RouterConfig.address, RouterConfig.abi);
  const PresaleTransferContract = useContract(
    PresaleTransferConfig.address,
    PresaleTransferConfig.abi
  );
  //not in use anymore 31/10/2022
  const GRVSNAPHelperContract = useContract(
    GRVSNAPHelperConfig.address,
    GRVSNAPHelperConfig.abi
  );
  const GRVSNAPBonusContract = useContract(
    GRVSNAPBonusConfig.address,
    GRVSNAPBonusConfig.abi
  );
  const BUSDContract = useContract(BusdConfig.address, BusdConfig.abi);
  const SwapContract = useContract(SwapConfig.address, SwapConfig.abi);
  const PancakeSwapContract = useContract(
    PancakeSwapConfig.address,
    PancakeSwapConfig.abi
  );
  const GiltsContract = useContract(GiltsConfig.address, GiltsConfig.abi);
  return {
    HoloClearContract,
    HoloWrapContract,
    HoloYieldContract,
    MarketOracleContract,
    PholoContract,
    SeriescContract,
    VaultContract,
    LotteryContract,
    RouterContract,
    PresaleTransferContract,
    GRVSNAPHelperContract,
    GRVSNAPBonusContract,
    BUSDContract,
    SwapContract,
    PancakeSwapContract,
    GiltsContract,
  };
});

useContractContext.Provider = ContractContextProvider;
export { useContractContext };
