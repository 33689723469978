import { Stack } from "@mui/material";

const Space = ({ children, ...props }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      {...props}
    >
      {children}
    </Stack>
  );
};
export default Space;
