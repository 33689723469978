import {  Button, CircularProgress, Stack, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../Components/CustomTextField";


export const PriceInput = (props) => {
  const { wrapped, handleMaxClick, amount, inputChangeHandler, handleWrapClick, loading } = props;
  const { t } = useTranslation();

  return (
    <Grid container rowSpacing={2} marginBottom={7}>
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          type="number"
          variant="outlined"
          color={"secondary"}
          placeholder={
            wrapped
              ? t("wrapPage.yieldAmountPlaceHolder")
              : t("wrapPage.wrapAmountPlaceHolder")
          }
          InputProps={{
            endAdornment:
              <InputSubmitButton
                handleWrapClick={handleWrapClick}
                handleMaxClick={handleMaxClick}
                wrapped={wrapped}
                loading={loading}
              />,
          }}
          value={amount}
          onChange={inputChangeHandler}
        />
      </Grid>
      <Grid item xs={12} display={{ xs: "block", md: " none" }}>
        <Button fullWidth variant="contained" color={"secondary"} size="large">
          {wrapped
            ? t("wrapPage.wrapToHolowrap")
            : t("wrapPage.unwrapToHoloyield")}
        </Button>
      </Grid>
    </Grid>
  );
};

const InputSubmitButton = (props) => {
  const { t } = useTranslation();
  const { wrapped, handleMaxClick, handleWrapClick, loading } = props;
  return (
    <Stack direction="row">
      <Button onClick={handleMaxClick} color={"secondary"}>{t("buttons.max")}</Button>
      <Grid display={{ xs: "none", md: "block" }}>
        <Button onClick={handleWrapClick} variant="contained" color={"secondary"}>
          {loading && <CircularProgress size={18} />}
          {wrapped && !loading && t("wrapPage.wrapToHolowrap")}
          {!wrapped && !loading && t("wrapPage.unwrapToHoloyield")}
        </Button>
      </Grid>
    </Stack>
  );
};
