import { makeStyles } from "@mui/styles";
import { Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "../Icon";
import ValueCard from "../ValueCard";
import { useApiResult } from "../../context/ApiResultContext";

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingLeft: 16,
    paddingTop: 5,
  },
  iconMdDevice: {
    paddingLeft: 32,
    paddingTop: 20,
  },
  value: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: "34px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: "22px",
      letterSpacing: "0.03em",
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "29px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "14px",
    },
  },
}));

const CurrentTreasuryValueCard = (props) => {
  const { label, upDownArrowEnabled } = props;
  const matches = useMediaQuery("(max-width:900px)");
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, loading } = useApiResult();
  return (
    <ValueCard size="lg">
      <Grid container item xs={12}>
        <ValueCard.Title variantParam="string" className={classes.title}>
          {label || t("currentTreasuryValue")}{" "}
        </ValueCard.Title>
        <Grid container item xs={12} spacing={3}>
          <Grid container item xs={12} alignItems="flex-start">
            <ValueCard.Value
              variantParam="string"
              className={classes.value}
              value={`$ ${
                !loading && data?.graphs?.treasury?.data && data?.graphs?.treasury?.data.length > 0
                  ? data.graphs.treasury.data[
                      data.graphs.treasury.data.length - 1
                    ].toLocaleString()
                  : "-"
              }`}
            />
            {!matches && upDownArrowEnabled && (
              <Icon className={classes.icon} icon="up-arrow-flat" size={22} />
            )}
          </Grid>
          {upDownArrowEnabled && matches && (
            <Icon
              className={classes.iconMdDevice}
              icon="up-arrow-flat"
              size={22}
            />
          )}
        </Grid>
      </Grid>
    </ValueCard>
  );
};

export default CurrentTreasuryValueCard;
