import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Stack, Typography } from "@mui/material";
import { grey7, holoBlack } from "../../constants/Colours";
import { SmallHoloPinkButton } from "../../Components/Button/ConnectWalletButtons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import HoloClearConfig from "../../blockChainContexts/Testnet/HoloClear/config";
import HoloYieldConfig from "../../blockChainContexts/Testnet/HoloYield/config";
import HoloWrapConfig from "../../blockChainContexts/Testnet/HoloWrap/config";
import { useWalletContext } from "../../context/WalletContext";
import { AlertStatus } from "../../constants/Status";
import AlertComponent from "../../Components/Alert";
const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 270,
  },
  title: {
    fontSize: 24,
    letterSpacing: "0.75px",
    color: theme.palette.colors.holoBlack,
    lineHeight: "29px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      fontWeight: 400,
    },
  },
  description: {
    fontFamily: "Light",
    color: theme.palette.colors.grey7,
    fontWeight: 300,
    fontSize: 16,
    lineHeight: "21px",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  buttonGroup: {
    marginTop: 45,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
}));
const tokens = {
  Holo: {
    address: HoloClearConfig.address, // The address that the token is at.
    symbol: "HOLO", // A ticker symbol or shorthand, up to 5 chars.
    decimals: 18, // The number of decimals in the token
    image:
      "https://holoclear.xyz/wp-content/uploads/2022/10/HOLOCLEAR_ICON.png", // A string url of the token logo
  },
  Holowrap: {
    address: HoloWrapConfig.address, // The address that the token is at.
    symbol: "HOLOw", // A ticker symbol or shorthand, up to 5 chars.
    decimals: 18, // The number of decimals in the token
    image:
      "https://holoclear.xyz/wp-content/uploads/2022/10/HOLOCLEAR_ICON.png", // A string url of the token logo
  },
  Holoyield: {
    address: HoloYieldConfig.address, // The address that the token is at.
    symbol: "HOLOy", // A ticker symbol or shorthand, up to 5 chars.
    decimals: 18, // The number of decimals in the token
    image:
      "https://holoclear.xyz/wp-content/uploads/2022/10/HOLOCLEAR_ICON.png", // A string url of the token logo
  },
};
const AddTokenSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { library } = useWalletContext();
  const [status, setStatus] = useState({
    status: undefined,
    message: "",
    loading: undefined,
  });

  const addOnClick = async (tokenName = "Holo") => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      status: AlertStatus.Info,
      message: t("info.addToken"),
      loading: tokenName,
    }));
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await library.provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: tokens[tokenName],
        },
      });

      if (wasAdded) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          status: AlertStatus.Success,
          message: t("success.thanks"),
        }));
      } else {
        setStatus((prevStatus) => ({
          ...prevStatus,
          status: AlertStatus.Error,
          message: t("errors.rejected"),
        }));
      }
    } catch (error) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        status: AlertStatus.Error,
        message: t("errors.requestError"),
      }));
    }
    setStatus((prevStatus) => ({
      ...prevStatus,
      loading: undefined,
    }));
  };
  const closeAlert = () => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      status: undefined,
    }));
  };
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="string" className={classes.title}>
              {t("accountPage.addToWalletTitle")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="string" className={classes.description}>
              {t("accountPage.addToWalletDescription")}
              <a
                className={classes.description}
                href="https://docs.holoclear.xyz/using-the-dapp/adding-usdholo-to-my-wallet"
                target="_blank"
                rel="noreferrer"
              >
                {t("accountPage.docsLinkText")}
              </a>
              {t("accountPage.walletDescriptionRest")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction={"column"}
              spacing={{ xs: 2, sm: 3 }}
              className={classes.buttonGroup}
            >
              {status.status && (
                <AlertComponent
                  severity={status.status}
                  message={status.message}
                  onclickHandler={closeAlert}
                />
              )}
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 2, sm: 3 }}
              >
                <SmallHoloPinkButton
                  onClickHandler={() => addOnClick("Holo")}
                  label={t("buttons.addToken", { token: t("Holo") })}
                  loading={status.loading === "Holo"}
                />
                <SmallHoloPinkButton
                  onClickHandler={() => addOnClick("Holoyield")}
                  label={t("buttons.addToken", { token: t("Holoyield") })}
                  loading={status.loading === "Holoyield"}
                />
                <SmallHoloPinkButton
                  onClickHandler={() => addOnClick("Holowrap")}
                  label={t("buttons.addToken", { token: t("Holowrap") })}
                  loading={status.loading === "Holowrap"}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddTokenSection;
