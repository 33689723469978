import React from "react";
import Grid from "@mui/material/Grid";
import GiltsFieldHeader from "../fields/GiltsFieldHeader";
import GiltsFieldValue from "../fields/GiltsFieldValue";

const MyGiltsHeaderValueField = (props) => {
    const { value, headerText, fontWeight, fontSize, fontFamily, headerVisible = true, centerAlign = false } = props;
    return (
        <Grid container item xs={12} spacing={2}>
            {headerVisible &&
                <Grid container item xs={12} justifyContent={centerAlign ? "center" : "flex-start"}>
                    <GiltsFieldHeader headerText={headerText} />
                </Grid>
            }
            <Grid container item xs={12} justifyContent={centerAlign ? "center" : "flex-start"}>
                <GiltsFieldValue value={value} fontFamily={fontFamily} fontWeight={fontWeight} fontSize={fontSize} />
            </Grid>
        </Grid>
    );
};

export default MyGiltsHeaderValueField;