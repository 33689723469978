import { makeStyles } from "@mui/styles";
import { Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import ValueCard from "../../Components/ValueCard";
import Icon from "../Icon";
import { useWrapContext } from "../../Pages/Wrap/Context/WrapContext";
import { useApiResult } from "../../context/ApiResultContext";

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingLeft: 16,
    paddingTop: 5,
  },
  value: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: "34px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 34,
      fontWeight: 500,
      lineHeight: "22px",
      letterSpacing: "0.03em",
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const WrapPageHoloPriceCard = (props) => {
  const { label, percentageChangeEnabled, upDownArrowEnabled } = props;
  const { holoPrice } = useWrapContext();
  const { data: { change: { holo_price } } } = useApiResult();
  const matches = useMediaQuery("(max-width:900px)");
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <ValueCard size="lg">
      <Grid container item xs={12}>
        <ValueCard.Title variantParam="string" className={classes.title}>
          {label || t("currentHoloPrice")}{" "}
        </ValueCard.Title>
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12} alignItems="flex-start">
            <ValueCard.Value
              variantParam="string"
              className={classes.value}
              value={`$ ${holoPrice}`}
            />
            {!matches && upDownArrowEnabled && (
              <Icon className={classes.icon} icon="up-arrow-flat" size={22} />
            )}
          </Grid>
          {upDownArrowEnabled && matches && (
            <Icon className={classes.icon} icon="up-arrow-flat" size={22} />
          )}
        </Grid>
        {percentageChangeEnabled && <ValueCard.Change change={holo_price} />}
      </Grid>
    </ValueCard>
  );
};

export default WrapPageHoloPriceCard;
