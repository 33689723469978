import React from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import GiltsFieldHeader from "./GiltsFieldHeader";
import GiltsFieldValue from "./GiltsFieldValue";
import { useMediaQuery } from "@mui/material";
import { float4 } from "../../../utils/Number";

const PriceField = (props) => {
  const { price } = props;
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:900px)");
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid
        container
        item
        xs={12}
        justifyContent={matches ? "center" : "flex-start"}
      >
        <GiltsFieldHeader headerText={t("mintingPage.price")} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent={matches ? "center" : "flex-start"}
      >
        <GiltsFieldValue value={`$${float4(price)}`} fontWeight="700" />
      </Grid>
    </Grid>
  );
};

export default PriceField;
