import {
  Alert,
  IconButton,
  Button,
  Stack,
  Grid,
  CircularProgress,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import PriceField from "../../../Components/CustomTextField/PriceField";
import Icon from "../../../Components/Icon";
import TokenSelect from "../../../Components/Select/TokenSelect";
import { useWalletContext } from "../../../context/WalletContext";
import { Trans, useTranslation } from "react-i18next";
import { useSwapContext } from "../Context/SwapContext";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
const usePiceFormStyle = makeStyles(({ theme }) => ({
  swapBtn: {
    cursor: "pointer",
    borderRadius: "50%",
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const PriceForm = () => {
  const {
    fromToken,
    toToken,
    tokens,
    setFromToken,
    setToToken,
    fromTokenValue,
    setFromTokenValue,
    toTokenValue,
    setToTokenValue,
    submitStatus,
    setSubmitStatus,
    ableToSwapBusd,
    findToken,
    taxes,
  } = useSwapContext();
  const theme = useTheme();
  const classes = usePiceFormStyle();
  const { isWalletConnected } = useWalletContext();

  const selectOnChangeFromToken = (value) => {
    if (submitStatus.loading === true) return;

    if (value === "$HOLO") {
      updateToTokenValueSelect("BNB");
      setToToken(findToken("BNB"));
    } else {
      updateToTokenValueSelect("$HOLO");
      setToToken(findToken("$HOLO"));
    }

    updateFromTokenValueSelect(value);
    setFromToken(findToken(value));
  };

  const selectOnChangeToToken = (value) => {
    if (submitStatus.loading === true) return;

    if (value === "$HOLO") {
      updateFromTokenValueSelect("BNB");
      setFromToken(findToken("BNB"));
    } else {
      updateFromTokenValueSelect("$HOLO");
      setFromToken(findToken("$HOLO"));
    }

    updateToTokenValueSelect(value);
    setToToken(findToken(value));
  };

  const inputOnChangeFromToken = (value) => {
    if (submitStatus.loading === true) return;

    setFromTokenValue({
      default: value,
      fixed: value,
    });

    if (isWalletConnected) {
      updateToTokenValue(value);
    }
  };

  const inputOnChangeToToken = (value) => {
    if (submitStatus.loading === true) return;

    setToTokenValue({
      default: value,
      fixed: value,
    });

    updateFromTokenValue(value);
  };

  const updateFromTokenValueSelect = (value) => {
    if (fromTokenValue.default !== "") {
      let holoToken = findToken("$HOLO");
      let busdToken = findToken("BUSD");
      let price;
      if (fromToken.value === "BNB") {
        if (value === "$HOLO") {
          price = Number.parseFloat(
            fromTokenValue.default * holoToken.bnbPrice * (1 - taxes.holo.buy)
          );
        } else if (value === "BUSD") {
          price = Number.parseFloat(
            fromTokenValue.default * busdToken.bnbPrice
          );
        }
      } else if (fromToken.value === "$HOLO") {
        if (value === "BNB") {
          price = Number.parseFloat(
            (fromTokenValue.default * (1 - taxes.holo.sell)) /
              holoToken.bnbPrice
          );
        } else if (value === "BUSD") {
          price = Number.parseFloat(
            fromTokenValue.default *
              (1 - taxes.holo.sell) *
              (busdToken.bnbPrice / holoToken.bnbPrice)
          );
        }
      } else if (fromToken.value === "BUSD") {
        if (value === "BNB") {
          price = Number.parseFloat(
            fromTokenValue.default / busdToken.bnbPrice
          );
        } else if (value === "$HOLO") {
          price = Number.parseFloat(
            (fromTokenValue.default /
              (busdToken.bnbPrice / holoToken.bnbPrice)) *
              (1 - taxes.holo.buy)
          );
        }
      }
      setFromTokenValue({
        default: price,
        fixed: price.toFixed(4),
      });
    }
  };

  const updateToTokenValueSelect = (value) => {
    if (toTokenValue.default !== "") {
      let holoToken = findToken("$HOLO");
      let busdToken = findToken("BUSD");

      if (toToken.value === "BNB") {
        if (value === "$HOLO") {
          setToTokenValue({
            default: Number.parseFloat(
              toTokenValue.default * holoToken.bnbPrice
            ),
            fixed: Number.parseFloat(
              toTokenValue.default * holoToken.bnbPrice
            ).toFixed(4),
          });
        } else if (value === "BUSD") {
          setToTokenValue({
            default: Number.parseFloat(
              toTokenValue.default * busdToken.bnbPrice
            ),
            fixed: Number.parseFloat(
              toTokenValue.default * busdToken.bnbPrice
            ).toFixed(4),
          });
        }
      } else if (toToken.value === "$HOLO") {
        if (value === "BNB") {
          setToTokenValue({
            default: Number.parseFloat(
              toTokenValue.default / holoToken.bnbPrice
            ),
            fixed: Number.parseFloat(
              toTokenValue.default / holoToken.bnbPrice
            ).toFixed(4),
          });
        } else if (value === "BUSD") {
          setToTokenValue({
            default: Number.parseFloat(
              toTokenValue.default * (busdToken.bnbPrice / holoToken.bnbPrice)
            ),
            fixed: Number.parseFloat(
              toTokenValue.default * (busdToken.bnbPrice / holoToken.bnbPrice)
            ).toFixed(4),
          });
        }
      } else if (toToken.value === "BUSD") {
        if (value === "BNB") {
          setToTokenValue({
            default: Number.parseFloat(
              toTokenValue.default / busdToken.bnbPrice
            ),
            fixed: Number.parseFloat(
              toTokenValue.default / busdToken.bnbPrice
            ).toFixed(4),
          });
        } else if (value === "$HOLO") {
          setToTokenValue({
            default: Number.parseFloat(
              toTokenValue.default / (busdToken.bnbPrice / holoToken.bnbPrice)
            ),
            fixed: Number.parseFloat(
              toTokenValue.default / (busdToken.bnbPrice / holoToken.bnbPrice)
            ).toFixed(4),
          });
        }
      }
    }
  };

  const updateToTokenValue = (value) => {
    let holoToken = findToken("$HOLO");
    let busdToken = findToken("BUSD");

    if (fromToken.value === "BNB") {
      if (toToken.value === "$HOLO") {
        setToTokenValue({
          default: Number.parseFloat(value * holoToken.bnbPrice),
          fixed: Number.parseFloat(value * holoToken.bnbPrice).toFixed(4),
        });
      } else if (toToken.value === "BUSD") {
        setToTokenValue({
          default: Number.parseFloat(value * busdToken.bnbPrice),
          fixed: Number.parseFloat(value * busdToken.bnbPrice).toFixed(4),
        });
      }
    } else if (fromToken.value === "$HOLO") {
      if (toToken.value === "BNB") {
        setToTokenValue({
          default: Number.parseFloat(value / holoToken.bnbPrice),
          fixed: Number.parseFloat(value / holoToken.bnbPrice).toFixed(4),
        });
      } else if (toToken.value === "BUSD") {
        setToTokenValue({
          default: Number.parseFloat(
            value * (busdToken.bnbPrice / holoToken.bnbPrice)
          ),
          fixed: Number.parseFloat(
            value * (busdToken.bnbPrice / holoToken.bnbPrice)
          ).toFixed(4),
        });
      }
    } else if (fromToken.value === "BUSD") {
      if (toToken.value === "BNB") {
        setToTokenValue({
          default: Number.parseFloat(value / busdToken.bnbPrice),
          fixed: Number.parseFloat(value / busdToken.bnbPrice).toFixed(4),
        });
      } else if (toToken.value === "$HOLO") {
        setToTokenValue({
          default: Number.parseFloat(
            value / (busdToken.bnbPrice / holoToken.bnbPrice)
          ),
          fixed: Number.parseFloat(
            value / (busdToken.bnbPrice / holoToken.bnbPrice)
          ).toFixed(4),
        });
      }
    }
  };

  const updateFromTokenValue = (value) => {
    let holoToken = findToken("$HOLO");
    let busdToken = findToken("BUSD");

    if (toToken.value === "BNB") {
      if (fromToken.value === "$HOLO") {
        setFromTokenValue({
          default: Number.parseFloat(value * holoToken.bnbPrice),
          fixed: Number.parseFloat(value * holoToken.bnbPrice).toFixed(4),
        });
      } else if (fromToken.value === "BUSD") {
        setFromTokenValue({
          default: Number.parseFloat(value * busdToken.bnbPrice),
          fixed: Number.parseFloat(value * busdToken.bnbPrice).toFixed(4),
        });
      }
    } else if (toToken.value === "$HOLO") {
      if (fromToken.value === "BNB") {
        setFromTokenValue({
          default: Number.parseFloat(value / holoToken.bnbPrice),
          fixed: Number.parseFloat(value / holoToken.bnbPrice).toFixed(4),
        });
      } else if (fromToken.value === "BUSD") {
        setFromTokenValue({
          default: Number.parseFloat(
            value * (busdToken.bnbPrice / holoToken.bnbPrice)
          ),
          fixed: Number.parseFloat(
            value * (busdToken.bnbPrice / holoToken.bnbPrice)
          ).toFixed(4),
        });
      }
    } else if (toToken.value === "BUSD") {
      if (fromToken.value === "BNB") {
        setFromTokenValue({
          default: Number.parseFloat(value / busdToken.bnbPrice),
          fixed: Number.parseFloat(value / busdToken.bnbPrice).toFixed(4),
        });
      } else if (fromToken.value === "$HOLO") {
        setFromTokenValue({
          default: Number.parseFloat(
            value / (busdToken.bnbPrice / holoToken.bnbPrice)
          ),
          fixed: Number.parseFloat(
            value / (busdToken.bnbPrice / holoToken.bnbPrice)
          ).toFixed(4),
        });
      }
    }
  };

  const toFixed = (number, fixed) => {
    if (isNaN(number)) return "";
    var updatedNumber = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return String(number).match(updatedNumber)[0];
  };

  const closeAlert = () => {
    setSubmitStatus((prevState) => ({
      ...prevState,
      message: "",
      status: undefined,
      loading: false,
    }));
  };

  const handleSwapIconClick = () => {
    if (submitStatus.loading === true) return;

    updateFromTokenValueSelect(toToken.value);
    setFromToken(findToken(toToken.value));

    updateToTokenValueSelect(fromToken.value);
    setToToken(findToken(fromToken.value));
  };

  return (
    <Grid container rowSpacing={5}>
      <Grid container rowSpacing={2}>
        {submitStatus.status && (
          <Grid item xs={12}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={closeAlert}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={submitStatus.status}
            >
              {submitStatus.message}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TokenForm
            selected={fromToken}
            onChange={selectOnChangeFromToken}
            input={{
              onChange: inputOnChangeFromToken,
              value: fromTokenValue.fixed,
              maxValue: toFixed(fromToken.balance, 4),
              hideMaxButton: false,
            }}
            tokens={ableToSwapBusd ? tokens : tokens.slice(0, -1)}
          />
        </Grid>
        <Grid container item justifyContent="center" alignItems="center">
          <div
            onClick={handleSwapIconClick}
            style={{
              backgroundColor: isWalletConnected
                ? theme.palette.colors.holoYellow
                : theme.palette.colors.SwapButtonPassiveBackground,
            }}
            className={classes.swapBtn}
          >
            <Icon icon={`down-arrow-white`} size="19" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <TokenForm
            selected={toToken}
            onChange={selectOnChangeToToken}
            input={{
              onChange: inputOnChangeToToken,
              value: toTokenValue.fixed,
              maxValue: "",
              hideMaxButton: true,
            }}
            tokens={ableToSwapBusd ? tokens : tokens.slice(0, -1)}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TradeButtons />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Light",
    letterSpacing: "0.016em",
    color: theme.palette.colors.grey11,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  balanceText: {
    color: theme.palette.colors.grey11,
  },
}));

export const TokenForm = ({
  selected,
  onChange = () => {},
  tokens,
  readOnly,
  input = {
    onChange: () => {},
    value: undefined,
    hideMaxButton: false,
    maxValue: undefined,
  },
}) => {
  const classes = useStyles();
  const { isWalletConnected } = useWalletContext();

  const toFixed = (number, fixed) => {
    if (isNaN(number)) return "";
    var updatedNumber = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return String(number).match(updatedNumber)[0];
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TokenSelect
          tokens={tokens}
          value={selected.value}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          readOnly={readOnly}
          color="secondary"
        />
        {isWalletConnected && (
          <Typography
            variant="string"
            className={classes.text}
            display={{ xs: "none", sm: "block" }}
          >
            <Trans
              values={{
                token: selected.value,
                balance: toFixed(selected.balance, 4),
              }}
              i18nKey="swapPage.currentBalance"
              components={[
                <Typography
                  variant="string"
                  display="inline"
                  fontWeight={500}
                  className={classes.balanceText}
                />,
              ]}
            />
          </Typography>
        )}
        {isWalletConnected && (
          <Typography
            variant="string"
            className={classes.text}
            display={{ xs: "block", sm: "none" }}
          >
            <Trans
              values={{
                token: selected.value,
                balance: toFixed(selected.balance, 4),
              }}
              i18nKey="swapPage.currentBalanceMobile"
              components={[
                <Typography
                  variant="string"
                  display="inline"
                  fontWeight={500}
                  className={classes.balanceText}
                />,
              ]}
            />
          </Typography>
        )}
      </Stack>
      <PriceField
        placeholder={`0.0000 ${selected.value}`}
        readOnly={readOnly}
        color="secondary"
        InputProps={{
          style: {
            minHeight: 50,
          },
        }}
        {...input}
      />
    </>
  );
};

const TradeButtons = () => {
  const { isWalletConnected, setWalletConnected } = useWalletContext();
  const { t } = useTranslation();
  const {
    submitStatus,
    handleSwapTokensClick,
    showApproveHoloClearButton,
    showApproveBusdButton,
    handleApproveHoloClearClick,
    handleApproveBusdClick,
    approvedBusd,
    approvedHoloClear,
    approveTrxLoading,
  } = useSwapContext();

  if (!isWalletConnected) {
    return (
      <Button
        color="secondary"
        variant="contained"
        fullWidth
        size="large"
        onClick={() => {
          setWalletConnected(true);
        }}
      >
        {t("buttons.connectWallet")}
      </Button>
    );
  }
  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
      {showApproveBusdButton ? (
        <Button
          color="inherit"
          variant="contained"
          size="large"
          onClick={() => handleApproveBusdClick()}
          disabled={
            submitStatus.disabled || submitStatus.loading || approvedBusd
          }
          fullWidth
        >
          {approveTrxLoading && <CircularProgress size={18} />}
          {!approveTrxLoading && t("buttons.approve")}
        </Button>
      ) : (
        <></>
      )}
      {showApproveHoloClearButton ? (
        <Button
          color="inherit"
          variant="contained"
          size="large"
          onClick={() => handleApproveHoloClearClick()}
          disabled={
            submitStatus.disabled || submitStatus.loading || approvedHoloClear
          }
          fullWidth
        >
          {approveTrxLoading && <CircularProgress size={18} />}
          {!approveTrxLoading && t("buttons.approve")}
        </Button>
      ) : (
        <></>
      )}
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={() => handleSwapTokensClick()}
        disabled={
          submitStatus.disabled ||
          submitStatus.loading ||
          (showApproveHoloClearButton && !approvedHoloClear) ||
          (showApproveBusdButton && !approvedBusd)
        }
        fullWidth
      >
        {submitStatus.loading && <CircularProgress size={18} />}
        {!submitStatus.loading && t("buttons.swap")}
      </Button>
    </Stack>
  );
};

export default PriceForm;
