import { Divider, Grid, Stack, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertComponent from "../../../Components/Alert";
import LoadingButton from "../../../Components/Button/LoadingButton";
import CustomTextField from "../../../Components/CustomTextField";
import { useContractContext } from "../../../context/ContractContext";
import { useWalletContext } from "../../../context/WalletContext";
import { float4 } from "../../../utils/Number";
import Cooldown from "../Components/Cooldown";
import Space from "../Components/Space";
import { useStakeContext } from "../Context/Stake";
import { AlertStatus } from "../../../constants/Status";
export const useDepositStyled = makeStyles((theme) => ({
  textBases: {
    color: theme.palette.colors.grey4,
  },
  muted: {
    color: theme.palette.colors.grey10,
    fontSize: "12px!important",
    minWidth: "max-content",
  },
  description: {
    fontFamily: "Light",
    color: theme.palette.colors.grey7,
    fontWeight: 300,
    fontSize: 16,
    lineHeight: "21px",
    paddingBottom: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 14
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    },
  }
}));

const Deposit = () => {
  const { t } = useTranslation();
  const classes = useDepositStyled();
  const { HoloClearContract, HoloYieldContract, VaultContract } =
    useContractContext();
  const { account } = useWalletContext();
  const { isStake } = useStakeContext();
  const [Values, _setValues] = useState({
    HoloBalance: "-",
    HoloYieldBalance: "-",
    nextInterestPayment: "-",
    nextInterestPaymentYield: "-",
    rate: "-",
    ROI: "-",
  });
  const [updateCooldown, setUpdateCooldown] = useState(false);
  useEffect(() => {
    getHoloBalance();
    getHoloYieldBalance();
  }, []);

  useEffect(() => {
    if (Values.HoloYieldBalance > -1) getNextInterestPayment();
  }, [Values.HoloYieldBalance]);

  useEffect(() => {
    if (Values.rate > -1) {
      getROI();
    }
  }, [Values.rate]);

  const getHoloBalance = async () => {
    try {
      const _holoBalance_gwei = await HoloClearContract.balanceOf(account);
      const _holoBalance = ethers.utils.formatEther(_holoBalance_gwei);
      setValues("HoloBalance", parseFloat(_holoBalance));
    } catch (error) {
      setValues("HoloBalance", "Error");
    }
  };
  const getHoloYieldBalance = async () => {
    try {
      const _holoYieldBalance_gwei = await HoloYieldContract.balanceOf(account);
      const _holoYieldBalance = ethers.utils.formatEther(
        _holoYieldBalance_gwei
      );
      setValues("HoloYieldBalance", parseFloat(_holoYieldBalance));
    } catch (error) {
      setValues("HoloYieldBalance", "Error");
    }
  };
  const getNextInterestPayment = async () => {
    try {
      const rate_gwei = await HoloYieldContract.rate();
      let rate = ethers.utils.formatEther(rate_gwei);
      const nextInterestPaymentYield = rate - 1;
      const _nextInterestPayment =
        Values.HoloYieldBalance * nextInterestPaymentYield;
      setValues("rate", rate);
      setValues("nextInterestPayment", _nextInterestPayment);
      setValues("nextInterestPaymentYield", nextInterestPaymentYield * 100);
    } catch (error) {
      setValues("rate", "Error");
      setValues("nextInterestPayment", "Error");
      setValues("nextInterestPaymentYield", "Error");
    }
  };
  const getROI = async () => {
    try {
      const epoch = await VaultContract.epoch();
      const _ROI =
        100 * (Math.pow(Values.rate, (7 * 86400) / epoch[0].toNumber()) - 1);
      setValues("ROI", _ROI.toFixed(3));
    } catch (error) {
      setValues("ROI", "Error");
    }
  };
  const setValues = (key, value) => {
    _setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const updateBalance = () => {
    getHoloBalance();
    getHoloYieldBalance();
    setUpdateCooldown((prevState) => !prevState);
  };
  return (
    <Grid
      container
      direction="column"
      rowSpacing={2}
      className={classes.textBases}
    >
      {!isStake &&
        <Typography variant="string" className={classes.description}>
          {t("stakePage.withdrawnDescription1")}
          <a className={classes.description} href="https://docs.holoclear.xyz/using-the-dapp/how-to-deposit-usdholo-to-earn-interest" target="_blank" rel="noreferrer">{t("stakePage.here")}</a>
          {t("stakePage.withdrawnDescription2")}
        </Typography>
      }
      <Grid item xs={12}>
        <PriceInput
          UpdateBalance={updateBalance}
          HoloYieldBalance={Values.HoloYieldBalance}
          HoloBalance={Values.HoloBalance}
        />
      </Grid>
      <Grid container item direction="column" rowSpacing={2}>
        <Grid item>
          <Space>
            <Typography>{t("stakePage.holoBalance")}</Typography>
            <Typography>
              {float4(Values.HoloBalance, true)} ${t("Holo")}
            </Typography>
          </Space>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Space>
            <Typography>{t("stakePage.StakedBalance")}</Typography>
            <Typography>
              {float4(Values.HoloYieldBalance, true)} {t("Holoyield")}
            </Typography>
          </Space>
        </Grid>
        {!isStake && (
          <Cooldown
            holoYield={float4(Values.HoloYieldBalance)}
            needUpdate={updateCooldown}
            updateHoloPrice={getHoloBalance}
          />
        )}
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Space>
            <Typography>{t("stakePage.NextInterestPayment")}</Typography>
            <Typography>
              {float4(Values.nextInterestPayment, true)} {t("Holoyield")}
            </Typography>
          </Space>
          <Grid
            container
            rowSpacing={1}
            direction="column"
            p={"16px 0px 20px 20px"}
          >
            <Grid item>
              <Space>
                <Typography className={classes.muted}>
                  {t("stakePage.NextInterestPaymentYield")}
                </Typography>
                <Typography className={classes.muted}>
                  {float4(Values.nextInterestPaymentYield, true)}%
                </Typography>
              </Space>
            </Grid>
            <Grid item>
              <Space>
                <Typography className={classes.muted}>
                  {t("stakePage.7DayROI")}
                </Typography>
                <Typography className={classes.muted}>{Values.ROI}%</Typography>
              </Space>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Deposit;

const PriceInput = ({
  UpdateBalance = () => { },
  HoloBalance = 0,
  HoloYieldBalance = 0,
}) => {
  const { t } = useTranslation();
  const { isStake, needUpdate } = useStakeContext();
  const [inputPrice, setInputPrice] = useState(undefined);
  const { HoloClearContract, HoloYieldContract, VaultContract } =
    useContractContext();
  const [minStakeAmount, setMinStakeAmount] = useState(0);
  const { account } = useWalletContext();
  const [submitStatus, setSubmitStatus] = useState({
    status: undefined,
    message: undefined,
    loading: false,
  });
  useEffect(() => {
    getMinStakeAmount();
  }, []);

  const getMinStakeAmount = async () => {
    try {
      const _minStakeAmount_gwei = await VaultContract.minimum_stake_amount();
      const _minStakeAmount = ethers.utils.formatEther(_minStakeAmount_gwei);
      setMinStakeAmount(parseFloat(_minStakeAmount));
    } catch (error) {
      console.log(error);
    }
  };

  const changeToMax = async () => {
    try {
      let maxValue;
      if (isStake) maxValue = await HoloClearContract.balanceOf(account);
      else maxValue = await HoloYieldContract.balanceOf(account);
      setInputPrice(ethers.utils.formatEther(maxValue));
    } catch (error) {
      alert("Couldn't reciev max balance!!");
    }
  };
  const setError = (message) => {
    setSubmitStatus((prevStatus) => ({
      ...prevStatus,
      status: "warning",
      loading: false,
      message,
    }));
  };
  const onSubmit = async () => {
    try {
      if (isStake) {
        if (minStakeAmount > parseFloat(inputPrice)) {
          return setError(
            t("errors.minTokeTransaction", {
              minPurchaseAmount: minStakeAmount,
              token: `$${t("Holo")}`,
            })
          );
        }
        if (HoloBalance < parseFloat(inputPrice)) {
          return setError(
            t("errors.insufficientAmount", { amount: HoloBalance })
          );
        }
      } else {
        if (HoloYieldBalance < parseFloat(inputPrice)) {
          return setError(
            t("errors.insufficientAmount", { amount: HoloYieldBalance })
          );
        }
      }

      setSubmitStatus((prevStatus) => ({
        ...prevStatus,
        status: AlertStatus.Info,
        message: t("info.transaction"),
        loading: true,
      }));
      const inputPrice_gwei = ethers.utils.parseUnits(inputPrice);
      let transaction;
      if (isStake)
        transaction = await VaultContract.stake(account, inputPrice_gwei);
      else transaction = await VaultContract.unstake(account, inputPrice_gwei);
      await transaction.wait();
      needUpdate();
      UpdateBalance();
    } catch (error) {
      setSubmitStatus((prevStatus) => ({
        ...prevStatus,
        status: AlertStatus.Warning,
        message: t("errors.transaction"),
      }));
    }
    setSubmitStatus((prevStatus) => ({
      ...prevStatus,
      status: AlertStatus.Success,
      message: t("success.transaction"),
      loading: false,
    }));
  };
  const closeHandler = () => {
    setSubmitStatus((prevStatus) => ({
      ...prevStatus,
      status: undefined,
    }));
  };
  return (
    <Grid container rowSpacing={2} marginBottom={8}>
      {submitStatus.status && (
        <Grid item xs={12}>
          <AlertComponent
            severity={submitStatus.status}
            message={submitStatus.message}
            onclickHandler={closeHandler}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          variant="outlined"
          placeholder={
            isStake
              ? t("stakePage.holoAmountPlaceHolder")
              : t("stakePage.yieldAmountPlaceHolder")
          }
          color={"secondary"}
          InputProps={{
            endAdornment: (
              <InputSubmitButton
                maxOnClick={changeToMax}
                onSubmit={onSubmit}
                loading={submitStatus.loading}
              />
            ),
          }}
          value={inputPrice}
          onChange={(e) => setInputPrice(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} display={{ xs: "block", md: " none" }}>
        <LoadingButton
          size="large"
          onClick={onSubmit}
          fullWidth
          variant="contained"
          color={"secondary"}
          loading={submitStatus.loading}
        >
          {isStake
            ? t("buttons.StakeToHoloYield")
            : t("buttons.WithdrawToHolo")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

const InputSubmitButton = ({
  maxOnClick = () => { },
  onSubmit = () => { },
  loading = false,
}) => {
  const { t } = useTranslation();
  const { isStake } = useStakeContext();
  return (
    <Stack direction="row" marginTop={"-1px"}>
      <Button color={"secondary"} onClick={maxOnClick} disabled={loading}>
        {t("buttons.max")}
      </Button>
      <Grid display={{ xs: "none", md: "block" }}>
        <LoadingButton
          variant="contained"
          color={"secondary"}
          onClick={onSubmit}
          loading={loading}
        >
          {isStake
            ? t("buttons.StakeToHoloYield")
            : t("buttons.WithdrawToHolo")}
        </LoadingButton>
      </Grid>
    </Stack>
  );
};
