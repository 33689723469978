import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: "flex",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
    paddingLeft: 16,
    widht: 90,
    height: 32,
    gap: 8,
    background: theme.palette.colors.TooltipBackground,
    boxShadow: "0px 6px 16px rgba(27, 192, 237, 0.12)",
    borderRadius: 24,
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: "0.016em",
    color: theme.palette.colors.holoBlack,
    fontFamily: "PowerGrotesk-Regular!important",
    fontStyle: "normal",
    fontWeight: 400,
  },
}));

export default function CustomTooltip(props) {
  const { children, title, placement = "right" } = props;
  return (
    <LightTooltip title={title} placement={placement}>
      {children}
    </LightTooltip>
  );
}
