import React from "react";
import Grid from "@mui/material/Grid";
import PageTitle from "../../Components/Typography/PageTitle";
import PageLayout from "../../Components/Layout/PageLayout";
import { useTranslation } from "react-i18next";
import HoloPriceCard from "../../Components/HoloPrice";
import CurrentTreasuryValueCard from "../../Components/TreasuryValue";
import MyGiltsCard from "./mygilts/MyGiltsCard";
import { useWalletContext } from "../../context/WalletContext";
import ResourcesCard from "../Dashboard/Resources";
import HelpBubble from "../Dashboard/HelpBubble";
import { useMintContext } from "./Context/MintContext";
import MarketWrapper from "./gilt/MarketWrapper";
import BalanceSelector from "./components/BalanceSelector";

const MintPage = () => {
  const { t } = useTranslation();
  const { isWalletConnected } = useWalletContext();

  return (
    <useMintContext.Provider>
      <PageLayout>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PageTitle> {t("pages.Mint")} </PageTitle>
          </Grid>
          <Grid container item xs={12} spacing={4}>
            <Grid
              container
              item
              xs={12}
              sm={isWalletConnected ? 8 : 12}
              spacing={4}
            >
              <Grid container item xs={6}>
                <CurrentTreasuryValueCard
                  walletConnected={isWalletConnected}
                  upDownArrowEnabled
                />
              </Grid>
              <Grid container item xs={6}>
                <HoloPriceCard
                  walletConnected={isWalletConnected}
                  upDownArrowEnabled
                />
              </Grid>
            </Grid>
            {isWalletConnected && (
              <Grid container item xs={12} sm={4}>
                <BalanceSelector />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <MarketWrapper />
          </Grid>
          {isWalletConnected && (
            <Grid item xs={12}>
              <MyGiltsCard />
            </Grid>
          )}
          <Grid item xs={12}>
            <ResourcesCard />
          </Grid>
          <Grid item xs={12}>
            <HelpBubble />
          </Grid>
        </Grid>
      </PageLayout>
    </useMintContext.Provider>
  );
};

export default MintPage;
