import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { AppBar, Toolbar, Badge, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import {
  ConnectWalletDesktopButton,
  SmallHoloPinkButton,
} from "../Components/Button/ConnectWalletButtons";
import ThemeButton from "../Components/Button/ThemeButton";
import Icon from "../Components/Icon";
import { useWalletContext } from "../context/WalletContext";
import CustomizedSnackbar from "../Components/Snackbar";
import Holoclear from "../Components/Holoclear";
import { useThemeContext } from "../context/ThemeContext";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: `${theme.palette.background}!important`,
    backgroundImage: "none!important",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 96,
    },
  },
  switchNetworkBtn: {
    fontFamily: "PowerGrotesk-Regular!important",
    height: "100%",
    borderRadius: "25px!important",
  },
}));

const HoloAppbar = (props) => {
  const { t } = useTranslation();
  const { switchDarkTheme, switchLightTheme, isLight } = useThemeContext();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const theme = useTheme();
  const {
    isWalletConnected,
    setWalletConnected,
    showSwitchNetwork,
    handleSwitchNetwork,
  } = useWalletContext();
  const {
    menuIconVisible = false,
    handleMenuIconClick,
    drawerStatus,
    appbarPosition = "sticky",
  } = props;
  useEffect(() => {
    if (showSwitchNetwork) setNetworkError(true);
  }, [showSwitchNetwork]);

  const walletClickHandler = () => {
    setWalletConnected(!isWalletConnected);
    //when this method gets called and wallet is connected, it means, user clicked on disconnect button
    if (isWalletConnected) {
      setOpen(true);
    }
  };
  const getLogo = () => {
    if (process.env.REACT_APP_ISMAINNET !== "true") {
      return (
        <Badge
          color="info"
          badgeContent={"Testnet"}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Holoclear
            color={theme.palette.colors.holoGrey}
            style={{ width: "90%", maxWidth: 195 }}
            alt="holoclear"
          />
        </Badge>
      );
    } else {
      return (
        <Holoclear
          color={theme.palette.colors.holoGrey}
          style={{ width: "90%", maxWidth: 195 }}
          alt="holoclear"
        />
      );
    }
  };
  return (
    <AppBar
      position={appbarPosition}
      className={classes.appBar}
      style={{
        height: 96,
        borderBottom: "1px solid " + theme.palette.colors.white3,
        boxShadow: "none",
      }}
    >
      <Toolbar style={{ height: "100%" }}>
        <Grid container item xs={12}>
          {menuIconVisible && (
            <Grid item xs={1}>
              <IconButton
                aria-label="open drawer"
                onClick={() => handleMenuIconClick(!drawerStatus)}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(drawerStatus && { display: "none" }),
                }}
              >
                <Icon
                  icon={isLight ? "Hamburger" : "Hamburger_Dark"}
                  size={24}
                />
              </IconButton>
            </Grid>
          )}
          <Grid
            container
            item
            xs={menuIconVisible ? 6 : 6}
            alignItems="center"
            justifyContent="flex-start"
          >
            {getLogo()}
          </Grid>
          <Grid item xs={menuIconVisible ? 1 : 0} />
          <Grid
            container
            item
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
            display={{ xs: "none", sm: "flex" }}
            columnSpacing={2}
          >
            <Grid item>
              <ThemeButton
                onClick={(e) =>
                  isLight ? switchDarkTheme() : switchLightTheme()
                }
              >
                {isLight ? (
                  <Icon size="16" icon="moon" />
                ) : (
                  <Icon size="16" icon="sun" />
                )}
              </ThemeButton>
            </Grid>
            {showSwitchNetwork && (
              <Grid item height={"100%"}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.switchNetworkBtn}
                  onClick={handleSwitchNetwork}
                >
                  {t("buttons.SwitchNetwork")}
                </Button>
              </Grid>
            )}
            <Grid item>
              <ConnectWalletDesktopButton
                onClickHandler={walletClickHandler}
                label={
                  isWalletConnected
                    ? t("buttons.disconnect")
                    : t("buttons.connectWallet")
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            alignItems="center"
            justifyContent="flex-end"
            display={{ xs: "flex", sm: "none" }}
          >
            <ConnectWalletDesktopButton
              onClickHandler={walletClickHandler}
              label={
                isWalletConnected
                  ? t("buttons.disconnect")
                  : t("buttons.connect")
              }
            />
          </Grid>
        </Grid>
        <CustomizedSnackbar
          open={networkError}
          setOpen={setNetworkError}
          message={t("errors.switchNetwork")}
          severity="warning"
        />
        <CustomizedSnackbar
          open={open}
          setOpen={setOpen}
          message={t("success.walletDisconnected")}
          severity="warning"
        />
      </Toolbar>
    </AppBar>
  );
};

export default HoloAppbar;
