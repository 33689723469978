import { useState } from "react";
import constate from "constate";

const [WrapContextProvider, useWrapContext] = constate(() => {
  const [holoPrice, setHoloPrice] = useState("-");
  const [holoWrapPrice, setHoloWrapPrice] = useState("-");
  const [currentIndex, setCurrentIndex] = useState("-");

  return {
    holoPrice,
    holoWrapPrice,
    currentIndex,
    setHoloPrice,
    setHoloWrapPrice,
    setCurrentIndex
  };
});

useWrapContext.Provider = WrapContextProvider;
export { useWrapContext };
