import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { grey7 } from "../../../constants/Colours";

const useStyles = makeStyles((theme) => ({
  info: {
    letterSpacing: "0.75px",
    lineHeight: "16px",
    color: theme.palette.colors.grey7,
    fontFamily: ["Light"].join(","),
    fontSize: 12,
    fontWeight: 300,
    [theme.breakpoints.down("md")]: {
      lineHeight: "16px",
      letterSpacing: "0.016em",
      textAlign: "center",
    },
  },
}));

const VestingInfoField = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Typography variant="string" className={classes.info}>
      {" "}
      {t("mintingPage.vestingInfo")}{" "}
    </Typography>
  );
};

export default VestingInfoField;
