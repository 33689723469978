const Status = {
  Empty: "Empty",
  Pending: "Pending",
  Fullfilled: "Fullfilled",
  Rejected: "Rejected",
};
export const AlertStatus = {
  Warning: "warning",
  Success: "success",
  Error: "error",
  Info: "info",
};
export default Status;
