import * as React from "react";
import { styled } from "@mui/styles";
import { holoDark, holoOrange, holoPink, white } from "../../constants/Colours";
import { CircularProgress } from "@mui/material";

const BootstrapButton = styled("button")({
  fontFamily: "PowerGrotesk-Regular!important",
  fontStyle: "normal",
  fontWeight: 400,
  width: 180,
  height: 47,
  fontSize: 16,
  letterSpacing: "0.02em",
  backgroundColor: holoOrange,
  border: "none",
  boxShadow: "none",
  borderRadius: 25,
  color: white,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: holoOrange,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: holoOrange,
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
  "@media (max-width: 600px)": {
    fontSize: "14px!important",
    padding: "9px 12px!important",
    height: "min-content",
  },
});

const SmallConnectWalletButton = styled("button")({
  fontFamily: "PowerGrotesk-Regular!important",
  fontStyle: "normal",
  fontWeight: 400,
  width: "100%",
  height: 30,
  fontSize: 14,
  letterSpacing: "0.07em",
  lineHeight: "17px",
  backgroundColor: holoPink,
  border: "none",
  boxShadow: "none",
  borderRadius: 25,
  color: white,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: holoDark,
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: holoPink,
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
  "&:disabled": {
    backgroundColor: "#D2D2D2",
  },
  "@media (max-width: 600px)": {
    fontSize: "14px!important",
    padding: "9px 12px!important",
    height: "min-content",
  },
});

export function ConnectWalletDesktopButton(props) {
  const { label, onClickHandler } = props;
  return <BootstrapButton onClick={onClickHandler}>{label}</BootstrapButton>;
}

export function SmallHoloPinkButton(props) {
  const { label, onClickHandler, param, loading, ...rest } = props;
  return (
    <SmallConnectWalletButton
      {...rest}
      disabled={rest.disabled || loading}
      onClick={() => onClickHandler(param)}
    >
      {loading && <CircularProgress size={10} />}
      {!loading && label}
    </SmallConnectWalletButton>
  );
}
