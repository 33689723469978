import { useState, useEffect } from "react";
import { defaultProvider } from "../blockChainContexts/ProviderOptions";
import { useWalletContext } from "../context/WalletContext";
import { ethers } from "ethers";
const useContract = (address, abi) => {
  const { isWalletConnected, library } = useWalletContext();
  const [contract, setContract] = useState(undefined);
  useEffect(() => {
    try {
      const getProvider = () => {
        return library ? library.getSigner() : defaultProvider;
      };
      const contractInstance = new ethers.Contract(address, abi, getProvider());
      setContract(contractInstance);
    } catch (error) {
      console.log("Error while create instance of contract: ", error);
    }
  }, [library, isWalletConnected]);

  return contract;
};

export default useContract;
