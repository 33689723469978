import React from "react";
import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/logo.svg";
import GiltsFieldValue from "./GiltsFieldValue";
import GiltsFieldHeader from "./GiltsFieldHeader";

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        display: "flex",
        alignItems: "center"
    }
}));

const AssetMintedField = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const matches = useMediaQuery("(max-width:900px)");
    return (
        <Grid container item xs={12} spacing={2}>
            <Grid container item xs={12} justifyContent={matches ? "center" : "flex-start"}>
                <GiltsFieldHeader headerText={t("mintingPage.assetMinted")} />
            </Grid>
            <Grid container item xs={12} justifyContent={matches ? "center" : "flex-start"}>
                <div className={classes.iconContainer}>
                    <img src={Logo} style={{ width: 20, height: 20, marginRight: 12 }} alt="logo" />
                    <GiltsFieldValue fontFamily="PowerGrotesk-Regular" value={t("mintingPage.holoClear")} fontSize={18} />
                </div>
            </Grid>
        </Grid>
    );
};

export default AssetMintedField;