import { Card, CardContent, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import PriceForm from "./PriceForm";
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    fontSize: 24,
    lineHeight: "29px",
    letterSpacing: "0.016em",
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
  },
  explanation: {
    fontSize: 16,
    letterSpacing: "0.016em",
    color: theme.palette.colors.grey11,
    fontFamily: "Light",
    lineHeight: "21px",
    fontStyle: "normal",
    fontWeight: 300,
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  space: {
    height: 38,
  },
  cardContent: {
    paddingBottom: "32px!important"
  }
}));
const TradeTokens = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Stack spacing={1}>
          <Typography variant="string" className={classes.title}>
            {t("swapPage.TradeTokens")}
          </Typography>
          <Typography variant="string" className={classes.explanation}>
            {t("swapPage.SwapYourToken")}
          </Typography>
          <div className={classes.space} />
          <PriceForm />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TradeTokens;
