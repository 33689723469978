export const lightTheme = {
  holoBlack: "#232927",
  holoDark: " #4E4B66",
  black: "#000",

  holoGrey: "#778295",
  grey2: "#6F767E",
  grey3: "#6E7191",
  grey4: "#4E4B66",
  grey5: "#A4ABB7",
  grey6: "#919191",
  grey7: "#B9B9B9",
  grey8: "#A2A0AF",
  grey9: "#E2E2E2",
  grey10: "#909090",
  grey11: "#7C818B",
  connectWalletDescription: "#4E4B66",
  ButtonGray: "#EBEAF2",
  ButtonPrimaryColor: "#EB5C9D",
  ButtonSecondaryColor: "#FFFFFF",
  ButtonClaimedColor: "#A2A0AF",
  ButtonInherit: "#D2D2D2",
  SwapButtonPassiveBackground: "#EBECF5",
  greySeperator: "#D7DCE5",

  holoLightBlue: "#F7F9FE",

  orange: "#FF0000",
  holoOrange: "#EC6439",
  holoLightOrange: "#FF5925",

  holoPink: "#EB5C9D",
  holoYellow: "#F8DA58",

  holoGreen: "#00DEA3",
  holoInfo: "#422DC0",
  holoDisabled: "#D2D2D2",

  white: "#FFF",
  white2: "#E5E5E5",
  white3: "#E4E4E4",

  chipDefault: "#F8F9FB",

  dividerColor: "#D9DBE9",

  telegramColor: "#0088CC",
  discordColor: "#5865F2",
  secondBackground: "#F7F9FE",
  mainBackground: "#FFFFFF",
  borderColor: "#E4E4E4",
  cardBackground: "#FFFFFF",
  cardShadow: "-10px 56px 140px rgba(143, 155, 186, 0.2)",
  cardDropFilter: "blur(9.5px)",

  pageTitleColor: "#4E4B66",
  menuItembackgroundColor: "#e4e4e44d",
  menuItemaActiveColor: "#26273B",
  TooltipBackground:
    "linear-gradient(128.8deg, #FFFFFF -1.91%, rgba(255, 255, 255) 103.54%)",
  inputPlaceholder: "#4E4B66",

  treasuryPrimaryGradientStart: "#FEC4B8",
  treasuryPrimaryGradientEnd: "#FFFFFF",
  treasurySecondaryGradientStart: "#FAEBAD",
  treasurySecondaryGradientEnd: "#FFFFFF",
};
export const darkTheme = {
  holoBlack: "#FFFFFF",
  holoDark: " #4E4B66",
  black: "#ffffff",

  holoGrey: "#ffffff",
  grey2: "#6F767E",
  grey3: "#FFFFFF",
  grey4: "#FFFFFF",
  grey5: "#A4ABB7",
  grey6: "#919191",
  grey7: "#B9B9B9",
  grey8: "#A2A0AF",
  grey9: "#E2E2E2",
  grey10: "#778295",
  grey11: "#778295",
  connectWalletDescription: "#E4E4E4",

  ButtonGray: "#EBEAF2",
  ButtonPrimaryColor: "#EB5C9D",
  ButtonSecondaryColor: "#4E4B66",
  ButtonClaimedColor: "#2C3038",
  ButtonInherit: "#778295",
  SwapButtonPassiveBackground: "#778295",
  greySeperator: "#D7DCE5",

  holoLightBlue: "#F7F9FE",

  orange: "#FF0000",
  holoOrange: "#EC6439",
  holoLightOrange: "#FF5925",

  holoPink: "#EB5C9D",
  holoYellow: "#F8DA58",

  holoGreen: "#00DEA3",
  holoInfo: "#422DC0",
  holoDisabled: "#D2D2D2",

  white: "#FFF",
  white2: "#E5E5E5",
  white3: "#24282F",

  chipDefault: "#F8F9FB",

  dividerColor: "#D9DBE9",

  telegramColor: "#0088CC",
  discordColor: "#5865F2",
  secondBackground: "#2C3038",
  mainBackground: "#2f333b",
  borderColor: "#24282F",

  cardBackground: "#343942",
  cardShadow: "none",
  cardDropFilter: "blur(9.5px)",
  pageTitleColor: "#778295",

  menuItembackgroundColor: "#ffffff4d",
  menuItemaActiveColor: "#FFFFFF",
  TooltipBackground:
    "linear-gradient(128.8deg, #404752 -1.91%, #778295 103.54%)",
  inputPlaceholder: "#FFF",

  treasuryPrimaryGradientStart: "#4E4B66",
  treasuryPrimaryGradientEnd: "#343942",
  treasurySecondaryGradientStart: "#4E4B66",
  treasurySecondaryGradientEnd: "#343942",
};
export const holoBlack = "#232927";
export const holoDark = " #4E4B66";
export const black = "#000";

export const holoGrey = "#778295";
export const grey2 = "#6F767E";
export const grey3 = "#6E7191";
export const grey4 = "#4E4B66";
export const grey5 = "#A4ABB7";
export const grey6 = "#919191";
export const grey7 = "#B9B9B9";
export const grey8 = "#A2A0AF";
export const grey9 = "#E2E2E2";
export const grey10 = "#909090";
export const grey11 = "#7C818B";
export const greyMuted = grey6;
export const ButtonGray = "#EBEAF2";
export const greySeperator = "#D7DCE5";

export const holoLightBlue = "#F7F9FE";

export const orange = "#FF0000";
export const holoOrange = "#EC6439";
export const holoLightOrange = "#FF5925";

export const holoPink = "#EB5C9D";
export const holoYellow = "#F8DA58";

export const holoGreen = "#00DEA3";
export const holoInfo = "#422DC0";
export const holoDisabled = "#D2D2D2";

export const white = "#FFF";
export const white2 = "#E5E5E5";
export const white3 = "#E4E4E4";

export const chipDefault = "#F8F9FB";

export const dividerColor = "#D9DBE9";

export const telegramColor = "#0088CC";
export const discordColor = "#5865F2";
