import { Divider as MUIDivider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { grey4 } from "../../constants/Colours";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "PowerGrotesk-Medium!important",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 25,
    lineHeight: "30px ",
    letterSpacing: "0.01em",
    color: theme.palette.colors.pageTitleColor,
  },
}));

const Divider = styled(MUIDivider)({
  width: "calc(100% + 30px)",
  marginTop: 10,
});
const PageTitle = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="h5"
      gutterBottom
      display="inline-block"
      fontWeight={"medium"}
      className={classes.title}
    >
      {children}
      <Divider flexItem />
    </Typography>
  );
};

export default PageTitle;
