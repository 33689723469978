import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import MUICardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import Change from "./Components/Change";
import Title from "./Components/Title";
import Value from "./Components/Value";
import { styled } from "@mui/system";

const useStyles = makeStyles({
  card: {
    height: "100%",
    display: "flex",
  },
  cardContent: {
    height: "max-content",
    margin: "0",
    width: "100%",
  },
});
const CardContent = styled(MUICardContent)(({ size }) => {
  switch (size) {
    case "lg":
      return {
        paddingTop: "23px!important",
        paddingBottom: "32px!important",
      };
    case "sm":
      return {
        paddingTop: "20px!important",
        paddingBottom: "30px!important",
      };
    default:
      return {};
  }
});

function ValueCardBase({ children, size = "md", className, ...props }) {
  const classes = useStyles();
  return (
    <Card className={`${classes.card}`} {...props}>
      <CardContent
        size={size}
        className={`${classes.cardContent} ${className}`}
      >
        <Grid container>{children}</Grid>
      </CardContent>
    </Card>
  );
}
const ValueCard = Object.assign(ValueCardBase, {
  Change,
  Title,
  Value,
});
export default ValueCard;
