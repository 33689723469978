import { Divider, Grid, Typography } from "@mui/material";
import { ethers } from "ethers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertComponent from "../../../Components/Alert";
import { SmallHoloPinkButton } from "../../../Components/Button/ConnectWalletButtons";
import { useContractContext } from "../../../context/ContractContext";
import { useWalletContext } from "../../../context/WalletContext";
import { useCountdown } from "../../../Hooks/useCountDown";
import { formatRemainTime, getDiffFromNow } from "../../../utils/Date";
import { float4 } from "../../../utils/Number";
import { useDepositStyled } from "../Steps/Deposit";
import Space from "./Space";

const Cooldown = ({
  holoYield = "-",
  needUpdate,
  updateHoloPrice = () => {},
}) => {
  const classes = useDepositStyled();
  const { t } = useTranslation();
  const { account } = useWalletContext();
  const { VaultContract } = useContractContext();
  const [claimStatus, setClaimStatus] = useState({
    loading: false,
    status: undefined,
    message: "",
  });
  const [values, setValues] = useState({
    amount: 0,
    expiry: "-",
    claimable: false,
  });
  const getInto = async () => {
    try {
      const cooldownInfo = await VaultContract.stakeInfo(account);
      const amount_gwei = cooldownInfo[0];
      const amount = ethers.utils.formatEther(amount_gwei);
      const expiry = cooldownInfo[1].toNumber();
      const claimable = moment.unix(expiry).isBefore();
      setValues((prevState) => ({
        ...prevState,
        amount: parseFloat(amount),
        expiry: expiry,
        claimable: claimable,
      }));
    } catch (error) {
      console.log(error);
      setValues((prevState) => ({
        ...prevState,
        amount: "Error",
        expiry: "Error",
        claimable: false,
      }));
    }
  };

  useEffect(() => {
    getInto();
  }, [needUpdate]);

  const claimOnclick = async () => {
    try {
      setClaimStatus((prevStatus) => ({
        ...prevStatus,
        status: undefined,
        loading: true,
      }));
      const claim = await VaultContract.claim(account);
      await claim.wait();
      updateHoloPrice();
      getInto();
    } catch (error) {
      setClaimStatus((prevStatus) => ({
        ...prevStatus,
        status: "warning",
        message: t("errors.transaction"),
      }));
    }
    setClaimStatus((prevStatus) => ({
      ...prevStatus,
      loading: false,
    }));
  };
  const enableClaimButton = () => {
    if (values.claimable) return;
    setValues((prevState) => ({ ...prevState, claimable: true }));
  };
  const expiry = useCountdown(values.expiry, enableClaimButton);
  const closeHandler = () => {
    setClaimStatus((prevStatus) => ({
      ...prevStatus,
      status: undefined,
      message: "",
    }));
  };
  if (values.amount <= 0) return <></>;
  return (
    <>
      <Grid item>
        <Divider />
      </Grid>
      {claimStatus.status && (
        <Grid item>
          <AlertComponent
            severity={claimStatus.status}
            message={claimStatus.message}
            onclickHandler={closeHandler}
          />
        </Grid>
      )}
      <Grid item>
        <Space>
          <Typography>{t("stakePage.cooldown")}</Typography>
          <Typography>
            {holoYield} {t("Holoyield")}
          </Typography>
        </Space>
        <Grid
          container
          rowSpacing={1}
          direction="column"
          p={"16px 0px 20px 20px"}
        >
          <Grid item>
            <Space>
              <Typography className={classes.muted}>
                {t("stakePage.balanceInCooldown")}
              </Typography>
              <Space spacing={2}>
                <Typography className={classes.muted}>
                  {float4(values.amount)} $HOLO
                </Typography>
                <SmallHoloPinkButton
                  style={{ minWidth: 90 }}
                  label={t("buttons.claim")}
                  disabled={!values.claimable}
                  onClickHandler={claimOnclick}
                  loading={claimStatus.loading}
                />
              </Space>
            </Space>
          </Grid>
          <Grid item>
            <Space>
              <Typography className={classes.muted}>
                {t("stakePage.timeRemaining")}
              </Typography>
              <Typography className={classes.muted}>
                {formatRemainTime(expiry)}
              </Typography>
            </Space>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Cooldown;
