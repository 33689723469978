import { Button, CircularProgress } from "@mui/material";
const LoadingButton = ({ loading, children, ...props }) => {
  return (
    <Button {...props} disabled={props.disabled || loading}>
      {loading && <CircularProgress size={18} />}
      {!loading && children}
    </Button>
  );
};

export default LoadingButton;
