import { useState } from "react";
import constate from "constate";
import { useContractContext } from "../../../context/ContractContext";
import { ethers } from "ethers";
import { useWalletContext } from "../../../context/WalletContext";

const [PresaleContextProvider, usePresaleContext] = constate(() => {
  const [showRedeemBonus, setShowRedeemBonus] = useState(false); // GRVSNAP bonus
  const [isHoloClearApproved, setHoloClearApproved] = useState(false); // approve button
  const [pHoloBalance, setPHoloBalance] = useState("-");

  const { PholoContract, GRVSNAPBonusContract } = useContractContext();
  const { account } = useWalletContext();

  const fetchPHoloBalance = async (account) => {
    try {
      const balance = await PholoContract.balanceOf(account);
      setPHoloBalance(ethers.utils.formatEther(balance));
    } catch (e) {
      console.log(e);
    }
  }

  const checkRedeemBonus = async (account) => {
    try {
      const redeemBonusCount = await GRVSNAPBonusContract.view_bonus(account);
      const hasRedeemed = await GRVSNAPBonusContract.has_redeemed(account);
      setShowRedeemBonus(ethers.utils.formatEther(redeemBonusCount) > 0 && !hasRedeemed);
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  const checkPholoAllowance = async () => {
    try {
      const presaleAllowance = await PholoContract.balanceOf(account);
      setHoloClearApproved(ethers.utils.formatEther(presaleAllowance) <= 0);
    } catch (e) {
      console.log(e);
    }
  }

  return {
    showRedeemBonus,
    isHoloClearApproved,
    setShowRedeemBonus,
    setHoloClearApproved,
    pHoloBalance,
    fetchPHoloBalance,
    checkRedeemBonus,
    checkPholoAllowance
  };
});

usePresaleContext.Provider = PresaleContextProvider;
export { usePresaleContext };
