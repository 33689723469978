import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollTopByRoute() {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);
}

export default useScrollTopByRoute;
