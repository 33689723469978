import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useWalletContext } from "../../../context/WalletContext";

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Light!important",
    fontSize: "16px!important",
    color: theme.palette.colors.grey11,
    margin: "12px 0!important",
  },
}));
const ConnectWallet = ({ onApprove = () => {} }) => {
  const { setWalletConnected } = useWalletContext();
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid
      container
      direction={{ xs: "column-reverse", sm: "row" }}
      rowSpacing={2}
    >
      <Grid item xs={12} sm={9}>
        <Typography
          className={classes.text}
          textAlign={{ xs: "center", sm: "left" }}
          justifyContent={{ xs: "center", sm: "start" }}
        >
          {t("stakePage.connectWallet")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button
          fullWidth
          onClick={() => setWalletConnected(true)}
          variant="contained"
          color="secondary"
          size="large"
        >
          {t("buttons.connect")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConnectWallet;
