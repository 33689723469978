import { Card, Button, Divider, Grid, Typography } from "@mui/material";
import bg from "../../../assets/PrizeBg.jpg";
import bg_mobile from "../../../assets/PrizeBg_mobile.jpg";
import PrizeBg_dark from "../../../assets/PrizeBg_dark.jpg";
import PrizeBg_mobile_dark from "../../../assets/PrizeBg_mobile_dark.png";
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useWalletContext } from "../../../context/WalletContext";
import { usePoolContext } from "../Context/PoolContext";
import { useThemeContext } from "../../../context/ThemeContext";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    overflow: "hidden",
    padding: "117px 27px",
    [theme.breakpoints.down("sm")]: {
      padding: "267px 22px 44px 17px",
    },
  },
  title: {
    display: "block",
    fontFamily: "TiemposFine-LightItalic",
    fontWeight: 300,
    fontSize: 18,
    color: theme.palette.colors.grey11,
    paddingLeft: 16,
    letterSpacing: "0.07em",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  holoEdge: {
    display: "inline-block",
    fontFamily: "PowerGrotesk-HeavyItalic",
    fontWeight: 900,
    fontSize: 90,
    color: theme.palette.colors.white,
    [theme.breakpoints.down("md")]: {
      fontSize: 80,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 50,
    },
  },
  divider: {
    width: "90%",
    borderColor: theme.palette.colors.white + "!important",
    marginBottom: "40px!important",
    [theme.breakpoints.down("md")]: {
      marginBottom: "31px!important",
    },
  },
  description: {
    display: "block",
    fontFamily: "Light",
    fontSize: 16,
    color: theme.palette.colors.grey11,
    fontWeight: 300,
    marginBottom: "42px!important",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 360,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  button: {
    minWidth: "220px!important",
    borderRadius: "25px!important",
    fontSize: "16px!important",
    paddingTop: "13px!important",
    paddingBottom: "15px!important",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    [theme.breakpoints.up("sm")]: {
      minWidth: 930,
    },
  },
  hideOnMobile: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  showOnMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));

export const PrizeProgram = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isLight } = useThemeContext();
  const { isWalletConnected, setWalletConnected } = useWalletContext();
  const { handleClaimPrizeClick, isWalletOwnerWinner } = usePoolContext();

  return (
    <Card>
      <div className={classes.container}>
        {isLight && (
          <img
            alt="prize-bg"
            src={bg}
            className={`${classes.hideOnMobile} ${classes.img}`}
          />
        )}
        {isLight && (
          <img
            alt="prize-bg"
            src={bg_mobile}
            className={`${classes.showOnMobile} ${classes.img}`}
          />
        )}
        {!isLight && (
          <img
            alt="prize-bg"
            src={PrizeBg_dark}
            className={`${classes.hideOnMobile} ${classes.img}`}
          />
        )}
        {!isLight && (
          <img
            alt="prize-bg"
            src={PrizeBg_mobile_dark}
            className={`${classes.showOnMobile} ${classes.img}`}
          />
        )}
        <Typography className={classes.title} variant="string">
          {t("poolPage.Program.title")}
        </Typography>
        <Typography className={classes.holoEdge} variant="string">
          {t("poolPage.Program.holoedge")}
          <Divider className={classes.divider} />
        </Typography>
        <Typography className={classes.description} variant="string">
          {t("poolPage.Program.description")}
        </Typography>
        {!isWalletConnected && (
          <Button
            className={classes.button}
            onClick={() => setWalletConnected(true)}
            variant="contained"
          >
            {t("buttons.connect")}
          </Button>
        )}
        {isWalletConnected && isWalletOwnerWinner && (
          <Grid display={{ xs: "none", md: "block" }}>
            <Button
              className={classes.button}
              onClick={() => handleClaimPrizeClick()}
              variant="contained"
            >
              {t("poolPage.claimPrize")}
            </Button>
          </Grid>
        )}
      </div>
    </Card>
  );
};
