import React from "react";
import Grid from "@mui/material/Grid";
import { SmallHoloPinkButton } from "../../../Components/Button/ConnectWalletButtons";

const GiltButtonField = (props) => {
  const { onClickHandler, label, param } = props;
  return (
    <Grid container item xs={12} spacing={2}>
      {/* this blank Grid is required to keep button on the second level */}
      <Grid item xs={12} />
      <Grid container item xs={12}>
        <SmallHoloPinkButton param={param} onClickHandler={onClickHandler} label={label} />
      </Grid>
    </Grid>
  );
};

export default GiltButtonField;
