import { Button, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../../Components/CustomTextField";
import { useContractContext } from "../../../context/ContractContext";
import AlertComponent from "../../../Components/Alert";
import { useWalletContext } from "../../../context/WalletContext";
import { ethers } from "ethers";
import LoadingButton from "../../../Components/Button/LoadingButton";
import { useMintContext } from "../Context/MintContext";
import GiltsConfig from "../../../blockChainContexts/Testnet/Gilts/config";
import { AlertStatus } from "../../../constants/Status";
export default function MintAmountField({ isBusd, gilt }) {
  const [showApprove, setShowApprove] = useState(isBusd);
  const { BUSDContract, GiltsContract } = useContractContext();
  const { account, getBNBBalance } = useWalletContext();
  const { updateGilts } = useMintContext();
  const [inputPrice, setInputPrice] = useState(undefined);
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    status: undefined,
    message: "",
    loading: false,
  });
  const getMax = async () => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      status: undefined,
      message: "",
      loading: true,
    }));
    try {
      let max_balance;
      if (!isBusd) {
        max_balance = await getBNBBalance();
        const gas_fee = ethers.utils.formatEther(5e9);
        max_balance = max_balance - gas_fee;
      } else {
        let max_gwei = await BUSDContract.balanceOf(account);
        max_balance = ethers.utils.formatEther(max_gwei);
      }
      if (max_balance < 0)
        setStatus((prevStatus) => ({
          ...prevStatus,
          status: AlertStatus.Warning,
          message: t("errors.gasFee", { token: "BNB" }),
        }));
      setInputPrice(Math.max(max_balance, 0));
    } catch (error) {
      console.log(error);
      setStatus((prevStatus) => ({
        ...prevStatus,
        status: AlertStatus.Warning,
        message: t("errors.maxValue"),
      }));
    }
    setStatus((prevStatus) => ({ ...prevStatus, loading: false }));
  };
  const mint = async () => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      status: undefined,
      message: "",
      loading: true,
    }));
    try {
      const min_amount = ethers.utils.formatEther(gilt.min_amount);
      if (Number(min_amount) > Number(inputPrice)) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: false,
          status: AlertStatus.Warning,
          message: t("errors.minMintAmountTransaction", {
            minPurchaseAmount: min_amount,
            token: isBusd ? "BUSD" : "BNB",
          }),
        }));
        return;
      }
      let balance = 0;
      if (isBusd) {
        balance = await BUSDContract.balanceOf(account);
        balance = ethers.utils.formatEther(balance);
      } else {
        balance = await getBNBBalance();
      }
      if (Number(balance) < Number(inputPrice)) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: false,
          status: AlertStatus.Warning,
          message: t("errors.insufficientTokenBalance", {
            balance: balance,
            token: isBusd ? "BUSD" : "BNB",
          }),
        }));
        return;
      }
      setStatus((prevStatus) => ({
        ...prevStatus,
        status: AlertStatus.Info,
        message: t("info.transaction"),
      }));
      const inputPrice_gwei = ethers.utils.parseUnits(inputPrice.toString());
      let transaction;
      if (isBusd) {
        transaction = await GiltsContract.mint(
          gilt.idx,
          account,
          inputPrice_gwei
        );
      } else {
        transaction = await GiltsContract.mint(gilt.idx, account, 0, {
          value: inputPrice_gwei,
        });
      }
      await transaction.wait();
      setStatus((prevStatus) => ({
        ...prevStatus,
        message: t("success.transaction"),
        status: AlertStatus.Success,
      }));
      updateGilts();
    } catch (error) {
      console.log(error);
      setStatus((prevStatus) => ({
        ...prevStatus,
        status: AlertStatus.Warning,
        message: t("errors.transaction"),
      }));
    }
    setStatus((prevStatus) => ({ ...prevStatus, loading: false }));
  };
  const closeAlert = () => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      status: undefined,
      message: "",
    }));
  };
  const BUSDApprove = async () => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      status: AlertStatus.Info,
      message: t("info.approve"),
      loading: true,
    }));
    try {
      const min_amount = ethers.utils.formatEther(gilt.min_amount);
      if (Number(min_amount) > Number(inputPrice)) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: false,
          status: AlertStatus.Warning,
          message: t("errors.minMintAmountTransaction", {
            minPurchaseAmount: min_amount,
            token: isBusd ? "BUSD" : "BNB",
          }),
        }));
        return;
      }
      let balance = 0;
      if (isBusd) {
        balance = await BUSDContract.balanceOf(account);
        balance = ethers.utils.formatEther(balance);
      } else {
        balance = await getBNBBalance();
      }
      if (Number(balance) < Number(inputPrice)) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: false,
          status: AlertStatus.Warning,
          message: t("errors.insufficientTokenBalance", {
            balance: balance,
            token: isBusd ? "BUSD" : "BNB",
          }),
        }));
        return;
      }
      const transaction = await BUSDContract.approve(
        GiltsConfig.address,
        account
      );
      await transaction.wait();
      setStatus((prevStatus) => ({
        ...prevStatus,
        status: AlertStatus.Success,
        message: t("success.approve"),
      }));
      setShowApprove(false);
    } catch (error) {
      console.error(error);
      setStatus((prevStatus) => ({
        ...prevStatus,
        status: AlertStatus.Error,
        message: t("errors.approve"),
      }));
    }
    setStatus((prevStatus) => ({ ...prevStatus, loading: false }));
  };
  return (
    <Grid container item xs={12} rowSpacing={2} marginBottom={"22px"}>
      {status.status && (
        <AlertComponent
          severity={status.status}
          message={status.message}
          onclickHandler={closeAlert}
        />
      )}
      <Grid item xs={12}>
        <CustomTextField
          fullWidth
          // type="number"
          variant="outlined"
          placeholder={t("mintingPage.MintAmountPlaceHolder", {
            token: isBusd ? "BUSD" : "BNB",
          })}
          InputProps={{
            endAdornment: (
              <InputSubmitButton
                getMax={getMax}
                onClick={mint}
                loading={status.loading}
                showApprove={showApprove}
                onApprove={BUSDApprove}
              />
            ),
            inputProps: {
              type: "number",
              // inputMode: "numeric",
              // min: 0,
              // pattern: "[0-9]*",
            },
          }}
          value={inputPrice}
          onChange={(e) => setInputPrice(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} display={{ xs: "block", md: " none" }}>
        {showApprove ? (
          <LoadingButton
            fullWidth
            variant="contained"
            color={"primary"}
            style={{ borderRadius: 25 }}
            onClick={BUSDApprove}
            loading={status.loading}
            size="large"
          >
            {t("buttons.approve")}
          </LoadingButton>
        ) : (
          <LoadingButton
            fullWidth
            variant="contained"
            color={"primary"}
            style={{ borderRadius: 25 }}
            onClick={mint}
            loading={status.loading}
            size="large"
          >
            {t("buttons.mint")}
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
}

const InputSubmitButton = ({
  onClick = () => {},
  getMax = () => {},
  loading = false,
  showApprove = false,
  onApprove = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row">
      <LoadingButton color={"primary"} onClick={getMax} loading={loading}>
        {t("buttons.max")}
      </LoadingButton>
      <Grid display={{ xs: "none", md: "block" }}>
        {showApprove ? (
          <LoadingButton
            variant="contained"
            color={"primary"}
            onClick={onApprove}
            loading={loading}
          >
            {t("buttons.approve")}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color={"primary"}
            onClick={onClick}
            loading={loading}
          >
            {t("buttons.mint")}
          </LoadingButton>
        )}
      </Grid>
    </Stack>
  );
};
