import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import ValueCard from "../../../Components/ValueCard";
import { greySeperator } from "../../../constants/Colours";
import { usePoolContext } from "../Context/PoolContext";
import { useCountdown } from "../../../Hooks/useCountDown";

const useStyles = makeStyles((theme) => ({
  card: {
    paddingTop: "33px!important",
    paddingBottom: "33px!important",
  },
  title: {
    display: "block",
    fontSize: 18,
    fontWeight: 400,
  },
  value: {
    display: "block",
    fontFamily: "PowerGrotesk-Medium",
    fontSize: 36,
    fontWeight: 500,
    paddingBottom: "0px!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: 31,
    },
  },
}));
const NextDraw = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { nextDrawTime } = usePoolContext();
  const { days, hours, minutes, seconds } = useCountdown(nextDrawTime, () => {});

  return (
    <ValueCard className={classes.card}>
      <Grid item xs={12}>
        <ValueCard.Title className={classes.title} variantParam="string">
          {t("poolPage.NextDrawTitle")}
        </ValueCard.Title>
        <ValueCard.Value
          className={classes.value}
          variantParam="string"
          value={
            <>
              {days}D <GraySpan>•</GraySpan> {hours}H <GraySpan>•</GraySpan> {minutes}M{" "}
              <GraySpan>•</GraySpan> {seconds}S
            </>
          }
        />
      </Grid>
    </ValueCard>
  );
};

const useStylesSpan = makeStyles((theme) => ({
  span: {
    color: theme.palette.colors.greySeperator,
  },
}));
const GraySpan = ({ children }) => {
  const classes = useStylesSpan();
  return <span className={classes.span}>{children}</span>;
};

export default NextDraw;
