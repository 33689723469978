import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { holoGreen, orange } from "../../../constants/Colours";
import Icon from "../../Icon";
const Container = styled(Typography)(({ theme, isNegative, noMargin }) => ({
  color: isNegative ? orange : holoGreen,
  whiteSpace: "nowrap",
  letterSpacing: "-0.02em",
  marginTop: noMargin ? 0 : 16,
}));
const ChangeIcon = styled(Icon)({
  marginRight: 5,
});
const Change = ({ change, ...props }) => {
  const isNegative = change < 0;
  return (
    <Container {...props} isNegative={isNegative} variant="body1">
      <ChangeIcon icon={isNegative ? "down-arrow" : "up-arrow"} size="14" />
      {change}%
    </Container>
  );
};

export default Change;
