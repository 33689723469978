import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageLayout from "../../Components/Layout/PageLayout";
import PageTitle from "../../Components/Typography/PageTitle";
import HelpBubble from "../Dashboard/HelpBubble";
import ResourcesCard from "../Dashboard/Resources";
import PriceCard from "./Cards/PriceCard";
import { useSwapContext } from "./Context/SwapContext";
import TradeTokens from "./TradeTokens";
import LoadingPage from "../LoadingPage";

const SwapPage = () => {
  return (
    <useSwapContext.Provider>
      <SwapPageContent />
    </useSwapContext.Provider>
  )
}

const SwapPageContent = () => {
  const { t } = useTranslation();
  const { loading } = useSwapContext();

  if(loading) return<PageLayout><LoadingPage /></PageLayout> 

  return (
    <PageLayout>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PageTitle> {t("pages.Swap")} </PageTitle>
        </Grid>
        <Grid item xs={12}>
          <TradeTokens />
        </Grid>
        <PricesSection />
        <Grid item xs={12}>
          <ResourcesCard />
        </Grid>
        <Grid item xs={12}>
          <HelpBubble />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

const PricesSection = () => {
  const { fromToken, toToken } = useSwapContext();

  return (
    <Grid container item xs={12} spacing={4}>
      <Grid item xs={6}>
        <PriceCard
          color={fromToken.label === "HOLOCLEAR" ? "primary" : "secondary"}
          token={fromToken.value}
          price={`$ ${Number(fromToken.price).toFixed(4)}`}
        />
      </Grid>
      <Grid item xs={6}>
        <PriceCard
          color={toToken.label === "HOLOCLEAR" ? "primary" : "secondary"}
          token={toToken.value}
          price={`$ ${Number(toToken.price).toFixed(4)}`}
        />
      </Grid>
    </Grid>
  );
};

export default SwapPage;
