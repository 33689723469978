import IcomoonReact from "icomoon-react";
import iconSet from "./selection.json";
import PropTypes from "prop-types";
// Props
// color,
// size
// icon,
// className
const Icon = (props) => {
  const { color, size = "100%", icon, className = "", style = {} } = props;
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      style={style}
    />
  );
};
Icon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};
export default Icon;
