export const displayFloat = (number, fixed, displeyZeros = false) => {
  if (isNaN(number) || number === "") return "";

  const updatedNumber = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  if (displeyZeros) {
    return Number.parseFloat(number).toFixed(fixed);
  }
  return parseFloat(String(number).match(updatedNumber)[0]);
};
export const float0 = (number, displayZeros) =>
  displayFloat(number, 0, displayZeros);
export const float1 = (number, displayZeros) =>
  displayFloat(number, 1, displayZeros);
export const float2 = (number, displayZeros) =>
  displayFloat(number, 2, displayZeros);
export const float3 = (number, displayZeros) =>
  displayFloat(number, 3, displayZeros);
export const float4 = (number, displayZeros) =>
  displayFloat(number, 4, displayZeros);
