import * as React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid } from "@mui/material";
import MyGiltsHeaderValueField from "./MyGiltsHeaderValueField";
import GiltsChipField from "../fields/GiltsChipField";
import { dividerColor } from "../../../constants/Colours";
import { float2, float3 } from "../../../utils/Number";
import { useGiltContext } from "../Context/GiltContext";
import VestingTime from "./VestingTime";
import { ClaimButtonSection } from "./MyGiltsTable";
export default function MyGiltsMediumSizeTable({ rows, showClaimButtons }) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      rowGap={4}
      data-testid="mediumsize"
    >
      {rows.map((row, index) => (
        <useGiltContext.Provider key={index} gilt={row}>
          <Grid container item xs={12} spacing={2} style={{ paddingTop: 16 }}>
            <Grid
              container
              item
              xs={12}
              style={{ marginBottom: showClaimButtons ? 16 : 44 }}
            >
              <Grid container item xs={2} alignItems="center">
                <MyGiltsHeaderValueField
                  headerVisible={index === 0}
                  headerText={t("mintingPage.minted")}
                  value={t("mintingPage.holo", { value: float3(row.mint) })}
                />
              </Grid>
              <Grid container item xs={2}>
                <GiltsChipField
                  componentWidth={70}
                  headerVisible={index === 0}
                  headerText={t("mintingPage.discount")}
                  value={`${float2(row.discount)}%`}
                />
              </Grid>
              <Grid container item xs={2}>
                <GiltsChipField
                  componentWidth={110}
                  headerVisible={index === 0}
                  headerText={t("mintingPage.interesGained")}
                  value={t("mintingPage.holo", {
                    value: float3(row.interest_gained),
                  })}
                />
              </Grid>
              <Grid container item xs={3} alignItems="center">
                <MyGiltsHeaderValueField
                  headerVisible={index === 0}
                  headerText={t("mintingPage.vestingRemaning")}
                  value={<VestingTime />}
                />
              </Grid>
              <Grid container item xs={3} alignItems="center">
                <MyGiltsHeaderValueField
                  headerVisible={index === 0}
                  headerText={t("mintingPage.currentValue")}
                  value={t("mintingPage.holo", {
                    value: float3(row.current_value),
                  })}
                  fontWeight="700"
                />
              </Grid>
            </Grid>
            {showClaimButtons && (
              <Grid item xs={12} style={{ marginBottom: 32 }}>
                <ClaimButtonSection giltIndex={row._id} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider style={{ background: dividerColor }} />
            </Grid>
          </Grid>
        </useGiltContext.Provider>
      ))}
    </Grid>
  );
}
