import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import ValueCard from "../../../Components/ValueCard";
const useStyles = makeStyles((theme) => ({
  value: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px!important",
    },
  },
}));
const PriceCard = ({ token, color, price }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ValueCard size="sm">
      <Grid xs={12}>
        <ValueCard.Title variant="body2">
          {t("swapPage.currentPrice", { token })}{" "}
        </ValueCard.Title>
        <ValueCard.Value
          variant="h4"
          color={color}
          value={price}
          className={classes.value}
        />
      </Grid>
    </ValueCard>
  );
};

export default PriceCard;
