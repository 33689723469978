import React, { useState } from "react";
import DisplayedGilts from "../components/DisplayedGilts";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import MarketWrapperHeader from "./MarketWrapperHeader";
import TokenSelect from "../../../Components/Select/TokenSelect";
import { MarketTokens } from "../../Swap/Context/tokenData";
import { Stack } from "@mui/system";
import { grey10 } from "../../../constants/Colours";
import { useTranslation } from "react-i18next";
import { SmallHoloPinkButton } from "../../../Components/Button/ConnectWalletButtons";
import { useMintContext } from "../Context/MintContext";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 270,
    display: "flex",
    paddingTop: 6,
    paddingBottom: 6,
  },
  content: {
    paddingTop: "33px!important",
    paddingBottom: "45px!important",
  },
  selectboxTitle: {
    display: "block",
    color: theme.palette.colors.grey10,
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: "0.75px",
    marginBottom: "15px!important",
  },
  selectBoxContainer: {
    marginLeft: -10,
    minWidth: 150,
  },
  assetButton: {
    [theme.breakpoints.up("sm")]: {
      minWidth: 200,
    },
    marginBottom: 8,
  },
}));

const MarketWrapper = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedMarket, setSelectedMarket } = useMintContext();
  const [selectedToken, setSelectedToken] = useState(MarketTokens[0]);
  const onChange = (selected) => {
    setSelectedToken(
      MarketTokens.find((MarketToken) => MarketToken.value === selected)
    );
  };
  const confirmMarket = () => {
    setSelectedMarket(selectedToken.market);
  };
  if (selectedMarket === undefined) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container rowSpacing={5}>
            <MarketWrapperHeader />
            <Grid xs={12} item>
              <Typography className={classes.selectboxTitle} variant="string">
                {t("mintingPage.asset")}
              </Typography>
              <Stack direction="row" alignItems={"center"}>
                <div className={classes.selectBoxContainer}>
                  <TokenSelect
                    tokens={MarketTokens}
                    value={selectedToken.value}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                  />
                </div>
                <div>
                  <SmallHoloPinkButton
                    className={classes.assetButton}
                    label={t("buttons.selectAsset")}
                    onClickHandler={confirmMarket}
                  />
                </div>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
  return <DisplayedGilts market={selectedMarket} />;
};

export default MarketWrapper;
