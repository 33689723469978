import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { black, holoBlack, holoPink } from "../../constants/Colours";
import { useEffect, useState } from "react";
import { useContractContext } from "../../context/ContractContext";
import { ethers } from "ethers";
import { float0 } from "../../utils/Number";

const useStyles = makeStyles((theme) => ({
  marketCapTitle: {
    letterSpacing: 1,
    color: theme.palette.colors.black,
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px!important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "11px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px!important",
    },
  },
  marketCapValue: {
    display: "inline-block",
    paddingTop: 16,
    letterSpacing: 2,
    color: theme.palette.colors.holoPink,
    fontFamily: "PowerGrotesk-Bold!important",
    width: "calc(100% - 22px)",
    //36, 24, 18, 0-600 => 40
    [theme.breakpoints.down("lg")]: {
      fontSize: "24px!important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "34px!important", //without important, fontsize overrided by mui-root css
    },
  },
  marketCapValueIcon: {
    margin: "auto 0 auto auto",
    paddingTop: 16,
  },
  priceTitle: {
    paddingTop: 24,
    lineHeight: 18,
    letterSpacing: 1,
    color: theme.palette.colors.holoBlack,
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px!important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px!important",
    },
  },
  price: {
    paddingTop: 4,
    letterSpacing: 2,
    color: theme.palette.colors.holoPink,
    fontFamily: "PowerGrotesk-Bold!important",
    [theme.breakpoints.down("lg")]: {
      fontSize: "24px!important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px!important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px!important",
    },
  },
}));

export default function MarketCapCard(props) {
  const { holoPrice } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [marketCap, setMarketCap] = useState("-");
  const { HoloClearContract } = useContractContext();

  useEffect(() => {
    if (holoPrice > 0) {
      getMarketCap();
    }
  }, [holoPrice]);

  const getMarketCap = async () => {
    try {
      const _totalSupply_gwei = await HoloClearContract.totalSupply();
      const _totalSupply = ethers.utils.formatEther(_totalSupply_gwei);
      const mcap = float0(holoPrice * _totalSupply).toLocaleString();
      setMarketCap(mcap);
    } catch (error) {
      console.log(error);
      setMarketCap("Error");
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="body2" className={classes.marketCapTitle}>
          {t("marketCap")}
        </Typography>
        <Typography
          variant="h4"
          style={{ marginBottom: 24 }}
          className={classes.marketCapValue}
          noWrap
        >
          $ {marketCap}
        </Typography>
        <Divider />
        <Typography variant="body1" className={classes.priceTitle}>
          {t("holoPrice")}
        </Typography>
        <Typography variant="h5" className={classes.price}>
          $ {holoPrice}
        </Typography>
      </CardContent>
    </Card>
  );
}
