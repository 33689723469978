import { Grid } from "@mui/material";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../Components/Icon";
import ValueCard from "../../../Components/ValueCard";
import { useContractContext } from "../../../context/ContractContext";
import { useStakeContext } from "../Context/Stake";

const CurrentIndex = () => {
  const { t } = useTranslation();
  const [currentIndexValue, setCurrentIndexValue] = useState("-");
  const { HoloYieldContract } = useContractContext();
  const { updateCards } = useStakeContext();
  useEffect(() => {
    getCurrentIndex();
  }, [updateCards]);
  const getCurrentIndex = async () => {
    try {
      const index_gwei = await HoloYieldContract.index();
      const _index = ethers.utils.formatEther(index_gwei);
      setCurrentIndexValue(parseFloat(_index).toFixed(4));
    } catch (error) {
      console.log(error);
      setCurrentIndexValue("Error");
    }
  };
  return (
    <ValueCard size="lg">
      <Grid xs={12}>
        <ValueCard.Title>{t("currentIndex")} </ValueCard.Title>
        <ValueCard.Value value={currentIndexValue} />
        <Icon icon="current-index" size={28} />
      </Grid>
    </ValueCard>
  );
};

export default CurrentIndex;
