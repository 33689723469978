import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { FormControl, MenuItem } from "@mui/material";
import Icon from "../Icon";
import { makeStyles } from "@mui/styles";

const RangeSelectInput = styled(InputBase)(({ theme, readOnly }) => {
  return {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: 18,
      cursor: readOnly ? "default" : "pointer",
      fontFamily: "PowerGrotesk-Regular!important",
    },
  };
});

const useStyles = makeStyles((theme) => ({
  icon: {
    transform: "rotate(90deg)",
  },
  iconOpen: {
    transform: "rotate(-90deg)!important",
  },
}));
const SelectIcon = ({ className = "", ...props }) => {
  const classes = useStyles();
  let classNames = `${classes.icon} ${className}`;
  if (className.includes("MuiSelect-iconOpen"))
    classNames += ` ${classes.iconOpen}`;
  return (
    <Icon
      icon="arrow"
      size={10}
      style={{ top: "calc(50% - 8px)" }}
      className={classNames}
      {...props}
    />
  );
};
export default function TokenSelect({ tokens = [], ...props }) {
  const findToken = (selectedToken) => {
    return tokens.find((token) => token.value === selectedToken);
  };
  const renderValue = (selectedToken) => {
    const token = findToken(selectedToken);
    if (!token) return "";
    return (
      <>
        {" "}
        {token.icon} {token.label}
      </>
    );
  };

  return (
    <FormControl sx={{ m: 1 }}>
      <Select
        renderValue={renderValue}
        IconComponent={props.readOnly ? null : SelectIcon}
        {...props}
        input={<RangeSelectInput />}
      >
        {tokens.map((token) => (
          <MenuItem value={token.value}>{token.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
